@import "../../../../../assets/sass/utils/utils";

.result-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 640px) {
    width: calc(100% - 160px - 20px); }

  @media (min-width: 768px) {
    width: calc(100% - 130px - 20px - 180px);
    margin-left: 180px; }

  @media (min-width: 890px) {
    width: calc(100% - 160px - 20px - 180px);
    max-width: 537px; }

  @media (min-width: 1024px) {
    width: calc(100% - 160px - 35px - 180px);
    margin-left: 0; }

  @media (min-width: 1200px) {
    width: calc(100% - 160px - 60px - 160px - 140px); } }

.card-result {
  width: 100%;
  margin-bottom: 10px;
  width: calc((100% - 20px) / 3 );

  @media (min-width: 660px) {
    width: calc((100% - 20px) / 3); }

  @media (min-width: 1232px) {
    width: calc((100% - 30px) / 3 );
    margin-bottom: 15px; }

  @media (min-width: 1254px) {
    width: calc((100% - 39px) / 3);
    margin-bottom: 19px; }

  &__title {
    text-transform: capitalize;
    font-size: $user_parameter_title_font_size;
    font-weight: $user_parameter_title_font_weight;
    line-height: 156.49%;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.6);
    font-family: $second-font; } }

.finish-card-value {
  text-transform: uppercase;
  font-size: $user_parameter_value_font_size_base;
  font-weight: $user_parameter_value_font_weight;
  text-transform: $user_parameter_value_text_transform;
  line-height: 156.49%; }

.finish-card-text {
  font-size: 14px;
  line-height: 156.49%;
  font-family: $second-font; }

.finish-card-bmi {
  display: flex;
  align-items: center;

  @media (min-width: 460px) {
    flex-direction: column;
    align-items: flex-start; }

  &__value {
    position: relative;
    @include size(59px);
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    margin-right: 25px;

    @media (min-width: 460px) {
      @include size(68px);
      margin-bottom: 28px; }

    .circle {
      @include size(100%); }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

  .finish-card-text {
    margin-bottom: 3px; } }

.circle {
  fill: red;
  @include size(68px);

  &__meter,
  &__value {
    fill: none; }

  &__meter {
    stroke: #000000; }

  &__value {
    stroke: #7DF3B3;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%; } }

.finish-card-metabolic {
  display: flex;
  align-items: center;

  @media (min-width: 460px) {
    flex-direction: column;
    align-items: flex-start; }

  &__pic {
    @include size(51px, 76px);
    margin-right: 30px;

    @media (min-width: 460px) {
      margin-right: 0;
      margin-bottom: 22px; } }

  .finish-card-text {
    margin-bottom: 3px; } }

.finish-card-calories {
  display: flex;
  align-items: center;

  @media (min-width: 660px) {
    flex-direction: column;
    align-items: flex-start; }

  .finish-card-text {
    margin-bottom: 3px; } }

.finish-card-progress {
  width: 50%;
  height: 76px;
  margin-right: 25px;
  display: flex;
  align-items: center;

  @media (min-width: 660px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 22px; }

  &__inner {
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 35px; }

  &__under-line {
    height: 7px;
    background-color: #000000;
    width: 100%;
    position: relative;

    .mark {
      height: 4px;
      width: 1px;
      position: absolute;
      top: -3px;
      left: 0;
      background-color: #000000;

      &:nth-child(2) {
        left: 12.5%; }

      &:nth-child(3) {
        left: 25%; }

      &:nth-child(4) {
        left: 37.5%; }

      &:nth-child(5) {
        left: 50%; }

      &:nth-child(6) {
        left: 62.5%; }

      &:nth-child(7) {
        left: 75%; }

      &:nth-child(8) {
        left: 87.5%; }

      &:last-child {
        right: 0;
        left: auto; } } }

  &__on-line {
    background-color: #7DF3B3;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    div {
      position: absolute;
      bottom: 100%;
      color: #73E4A7;
      font-size: 14px;
      line-height: 156.49%;
      display: flex;
      flex-direction: column;

      &::after {
        content: "";
        width: 1px;
        height: 18px;
        background-color: #7DF3B3;
        margin-top: 5px; }

      &:first-child {
        left: 0;
        align-items: flex-start;

        span {
          margin-left: -2px; } }

      &:last-child {
        right: 0;
        align-items: flex-end;

        span {
          margin-right: -2px; } } } } }
