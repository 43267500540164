.circle-edit-btn {
  @include size(35px);
  background-color: $circle_btn_bg;
  border-radius: $circle_btn_border_radius;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: $circle_btn_svg_fill;
    @include size(13px);

    &.icon-pencil {
      @include size(13px); }

    &.icon-checkbox {
      @include size(15px, 11px); } } }
