@import "../../../assets/sass/utils/utils";

.param {
  display: flex;
  align-items: center;
  font-size: $param_font_size;
  font-family: $second-font;
  line-height: 1;

  &--capitalize {
    text-transform: capitalize; }

  svg {
    fill: #ffffff;
    @include size(16px);
    margin-right: 7px;

    &.icon-serve {
      @include size(16px, 14px); }

    &.icon-level {
      @include size(14px, 16px); } } }
