@import "../../../assets/sass/utils/utils";

.workout-plan {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: space-between; }

  .link-back {
    @media (min-width: 720px) {
      margin-bottom: 30px; }

    @media (min-width: 890px) {
      margin-bottom: 35px; }

    @media (min-width: 1024px) {
      margin-bottom: 40px; }

    @media (min-width: 1200px) {
      margin-bottom: 45px; } }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

  &__right {
    margin-top: 32px;

    @media (min-width: 720px) {
      margin-top: 0; } }

  .title-add {
    margin-bottom: 25px;

    @media (min-width: 720px) {
      margin-bottom: 0;
      order: 2; } }

  .tag-list {
    justify-content: center;

    @media (min-width: 720px) {
      justify-content: flex-start; } }

  &__title {
    margin-bottom: 16px;

    @media (min-width: 480px) {
      margin-bottom: 20px; }

    @media (min-width: 890px) {
      margin-bottom: 25px; } }

  &__play {
    width: 100%;
    height: 100%;
    height: 60px;
    background-color: #7DF3B3;
    color: #000000;

    &:hover {
      background-color: #7DF3B3;
      opacity: 0.8; }

    &.with-icon {
      padding: 0; }

    > span {
      display: flex;
      align-items: center; }

    .icon-play-small {
      order: -1;
      margin-left: 0;
      margin-right: 9px;
      margin-bottom: 2px;
      fill: #000000; }

    &-link {
      @media (max-width: 719px) {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        min-width: 100%;
        z-index: 3; }

      @media (min-width: 720px) {
        margin-top: 30px;
        order: 3; }

      @media (min-width: 1024px) {
        margin-top: 40px; }

      @media (min-width: 1200px) {
        margin-top: 50px; } } }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (min-width: 720px) {
      margin-bottom: 25px; }

    @media (min-width: 890px) {
      margin-bottom: 30px; }

    .category-tag {
      margin-right: 8px;
      margin-bottom: 8px; } } }

.workout-author-name {
  font-family: $second-font;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px;
  background-color: #fff;
  padding: 0 12px;
  color: #000000;
  margin-bottom: 8px;
  font-size: $workout_author_name_font_size;
  font-weight: $workout_author_name_font_weight;
  border-radius: $workout_author_name_border_radius;
  border: $workout_author_name_border; }

.workout-plan-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(100%, 180px);
  background-color: #fff;

  @media (min-width: 375px) {
    @include size(100%, ls(375, 1200, 180, 360)); }

  @media (min-width: 1200px) {
    height: 360px; }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ABABAB;
    font-size: 14px;
    font-family: $second-font;
    font-size: 14px;
    line-height: 156.49%;

    svg {
      fill: #ABABAB;
      @include size(50px);
      margin-bottom: 20px; } } }

.exercise-single-video {
  position: relative;
  width: 100%;
  height: 170px;
  margin-top: 30px;

  @media (min-width: 320px) {
    height: ls(320, 720, 170, 370); }

  @media (min-width: 720px) {
    height: ls(720, 1350, 200, 394); }

  @media (min-width: 1350px) {
    height: 394px; }

  > div,
  iframe,
  object,
  embed {
    @include size(100%); } }

.single-video-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
