@import "../../../../../assets/sass/utils/utils";

.last-workout {

  &__section-desc {
    color: #000000;
    padding: 18px 20px;
    background-color: #ffffff;

    @media (min-width: 480px) {
      padding: 22px 24px; }

    @media (min-width: 576px) {
      padding: 24px 26px;
      margin-left: auto;
      max-width: 374px; }

    @media (min-width: 708px) {
      padding: 27px 27px; }

    svg {
      fill: #000000; } } }

.last-workout-group {
  display: flex;
  justify-content: space-between; }

.last-workout-calories {
  width: 69px;
  background-color: $last_workout_calories_bg;
  border-radius: $last_workout_calories_border_radius;
  border: $last_workout_calories_border;
  text-align: center;
  padding: 18px 0 15px;
  color: #000000;

  @media (min-width: 480px) {
    width: 115px; }

  &__title {
    background-color: #ffffff;
    line-height: 18px;
    margin-bottom: 2px;
    font-family: $second-font;
    padding: 0 10px;
    font-size: $last_workout_calories_title_font_size_base;

    @media (min-width: 480px) {
      font-size: $last_workout_calories_title_font_size_480; }

    span {
      display: none;

      @media (min-width: 480px) {
        display: inline; } } }

  &__value {
    line-height: 156.49%;
    text-transform: uppercase;
    font-size: $last_workout_calories_value_font_size_base;
    font-weight: $last_workout_calories_value_font_weight;

    @media (min-width: 576px) {
      font-size: $last_workout_calories_value_font_size_576; }

    @media (min-width: 640px) {
      font-size: $last_workout_calories_value_font_size_640; } } }

.last-workout-preview {
  color: #000000;
  padding: 18px 10px;
  display: flex;
  align-items: center;
  font-family: $second-font;
  background-color: $last_workout_preview_bg;
  width: calc(100% - 6px - 69px);
  border-radius: $last_workout_preview_border_radius;
  border: $last_workout_preview_border;

  @media (min-width: 375px) {
    padding: 18px 20px; }

  @media (min-width: 480px) {
    padding: 22px 24px;
    width: calc(100% - 6px - 115px); }

  @media (min-width: 576px) {
    padding: 20px 26px; }

  @media (min-width: 708px) {
    padding: 27px 27px; }

  @media (min-width: 1024px) {
    width: calc(100% - 6px - 115px); }

  &__pic {
    @include size(46px);
    border-radius: 50%;
    border: 6px solid #F7F9FB;
    margin-right: 17px;
    overflow: hidden;

    @media (min-width: 420px) {
      @include size(54px);
      margin-right: 20px; }

    @media (min-width: 576px) {
      @include size(46px);
      margin-right: 17px; }

    @media (min-width: 640px) {
      @include size(54px);
      margin-right: 20px; }

    .image-creator-error {
      svg {
        @include size(30px); } } }

  &__body {
    width: calc(100% - 17px - 46px);

    @media (min-width: 420px) {
      width: calc(100% - 20px - 54px); }

    @media (min-width: 576px) {
      width: calc(100% - 17px - 46px); }

    @media (min-width: 640px) {
      width: calc(100% - 20px - 54px); } }

  &__title {
    color: #ABABAB;
    font-size: $last_workout_preview_title_font_size;
    font-weight: $last_workout_preview_title_font_weight;
    line-height: $last_workout_preview_title_line_height;
    margin-bottom: 5px; }

  &__name {
    font-size: $last_workout_preview_name_font_size_base;
    font-weight: $last_workout_preview_name_font_weight;
    line-height: 110.49%;

    @media (min-width: 360px) {
      font-size: $last_workout_preview_name_font_size_360; }

    @media (min-width: 375px) {
      font-size: $last_workout_preview_name_font_size_375; }

    @media (min-width: 480px) {
      font-size: $last_workout_preview_name_font_size_480; }

    @media (min-width: 576px) {
      font-size: $last_workout_preview_name_font_size_576; }

    @media (min-width: 640px) {
      font-size: $last_workout_preview_name_font_size_640; }

    @media (min-width: 720px) {
      font-size: $last_workout_preview_name_font_size_720; } } }
