.button {
  width: 100%;
  background-color: #86DBCC;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 13px;
  padding: 18px 28px;
  text-align: center;
  font-weight: 700;
  transition: all .2s ease-in-out;

  @media (min-width: 375px) {
    padding: 20px 30px; }

  @media (min-width: 480px) {
    padding: 22px 30px; }

  @media (min-width: 640px) {
    padding: 24px 30px; }

  &:hover {
    background-color: #54C4B0; }

  &.transparent {
    background-color: transparent;
    color: #86DBCC;

    &:hover {
      background-color: rgba(#54C4B0, .1); } }

  &:disabled {
    background-color: rgba(#000, 0.1);
    cursor: not-allowed; } }
