.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.body {
  width: 100%;
  flex: 1 0 auto; }

.footer {
  width: 100%;
  margin-top: 42px;
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  @media (max-width: 575px) {
    padding-bottom: 30px; } }

.btn {
  max-width: 184px; }
