@import "../../../assets/sass/utils/utils";

.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 395px;
  margin: 0 auto;
  width: 100%;

  form {
    width: 100%; }

  &__text {
    line-height: 156.49%;
    font-size: 18px;
    font-family: $second-font; }

  &__title {
    font-size: $login_title_font_size_base;
    line-height: 1;
    margin-bottom: 12px;
    font-weight: $login_title_font_weight;
    text-transform: $login_title_text_transform;

    @media (min-width: 576px) {
      font-size: $login_title_font_size_576;
      margin-bottom: 18px; }

    @media (min-width: 768px) {
      font-size: $login_title_font_size_768;
      margin-bottom: 20px; }

    @media (min-width: 890px) {
      font-size: $login_title_font_size_890; }

    @media (min-width: 1024px) {
      font-size: $login_title_font_size_1024;
      margin-bottom: 23px; }

    @media (min-width: 1200px) {
      font-size: $login_title_font_size_1200; } }

  &__subtitle {
    line-height: 156.49%;
    font-size: $login_subtitle_font_size_base;
    margin-bottom: 40px;
    font-family: $second-font;
    font-weight: $login_subtitle_font_weight;

    @media (min-width: 576px) {
      margin-bottom: 50px;
      font-size: $login_subtitle_font_size_576; }

    @media (min-width: 890px) {
      margin-bottom: 60px;
      font-size: $login_subtitle_font_size_890; } }

  &__link {
    margin-top: 30px;
    color: #000000;
    font-size: $login_link_font_size;
    font-weight: $login_link_font_weight;
    line-height: 156.49%;
    border-bottom: 1px solid transparent;
    transition: all .2s;
    font-family: $second-font;

    &:hover {
      border-color: #000000;
      color: #000000; }

    &.forgot {
      margin-top: 25px;
      display: inline-block; } }

  .input-auth-wrap {
    &:first-of-type {
      .help-error {
        bottom: calc(100% + 2px); } }

    &:last-of-type {
      .help-error {
        top: calc(100% + 2px); } }

    .help-error {
      position: absolute; } } }

.auth-btn {
  min-width: 150px;
  margin: 40px auto 0;
  padding-top: 2px;

  @media (min-width: 576px) {
    margin-top: 45px; }

  @media (min-width: 890px) {
    margin-top: 50px; } }
