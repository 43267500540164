@import "../../../assets/sass/utils/utils";

.image-creator-pic {
  @include size(100%);
  object-fit: cover; }

.image-creator-error {
  @include size(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DFDFDF;

  svg {
    fill: rgba(#000000, 0.2);
    @include size(60px); } }
