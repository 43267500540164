.loader-full-block {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center; }

.loader-full-block-transition-enter {
  opacity: 0; }

.loader-full-block-transition-enter-active {
  opacity: 1;
  transition: all .2s ease-in-out; }

.loader-full-block-transition-exit {
  opacity: 1; }

.loader-full-block-transition-exit-active {
  opacity: 0;
  transition: all .2s ease-in-out; }
