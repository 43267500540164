.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%; }

.head {
  width: 100%;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    width: 43%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

.body {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 55%; } }

.title {
  margin-bottom: 0;
  max-width: 100%;

  @media (min-width: 768px) {
    text-align: left; } }

.tabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.1538461538461537em;
  font-size: 20px;

  @media (min-width: 480px) {
    font-size: 21px; }

  @media (min-width: 640px) {
    font-size: 22px; }

  @media (min-width: 768px) {
    font-size: 18px; }

  @media (min-width: 890px) {
    font-size: 21px; }

  @media (min-width: 1024px) {
    font-size: 23px; }

  @media (min-width: 1200px) {
    font-size: 26px; } }

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc( (100% - 1.1538461538461537em) / 2);
  color: #000000;
  opacity: 0.3;
  line-height: 1.3461538461538463em;
  font-weight: 700;
  cursor: pointer;
  padding: 0.5192307692307693em 0;
  transition: all .3s ease-in-out;

  &:hover {
    opacity: 0.5; }

  &.activeTab {
    opacity: 1; } }

.backBtn {
  @media (max-width: 766px) {
    display: none; } }

.backBtnForm {
  @media (min-width: 768px) {
    display: none; } }

.formFooter {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.formBtn {
  width: calc((100% - 20px) / 2);

  @media (min-width: 768px) {
    width: 100%; } }

.form {
  display: flex;
  flex-direction: column;
  flex: 1 0; }

.fields {
  margin: 0 0 30px;

  @media (min-width: 576px) {
    margin-top: auto; } }

