.input {
  margin-top: 45px;
  margin-bottom: 45px; }

.inputWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .input {
    width: calc((100% - 15px) / 2); } }

.checkboxes {
  margin-top: 45px;
  margin-bottom: 45px; }

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column; }

.btn {
  margin-top: auto; }
