.wrap {
  width: 100%;
  display: block;
  cursor: pointer;

  input {
    display: none; } }

.wrap + .wrap {
  margin-top: 10px; }

.content {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 16px;
  user-select: none;
  background-color: #fff;

  @media (min-width: 480px) {
    padding: 22px; }

  @media (min-width: 640px) {
    padding: 22px 30px; } }

.icon {
  margin-right: 10px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 360px) {
    margin-right: 12px;
    font-size: 46px; }

  @media (min-width: 480px) {
    font-size: 54px;
    margin-right: 16px; }

  @media (min-width: 640px) {
    font-size: 62px;
    margin-right: 20px; } }

.body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.title {
  line-height: 156.49%;
  font-size: 16px;
  font-weight: 700; }

.check {
  width: 42px;
  border-radius: 70px;
  background-color: #E5E5E5;
  transition: all .2s ease-in-out;
  height: 24px;
  position: relative;

  span {
    border-radius: 50%;
    background-color: #fff;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    transition: all .2s ease-in-out; } }

.input:checked ~ .content {
  .check {
    background-color: #54C4B0;

    span {
      left: calc(100% - 18px - 3px); } } }
