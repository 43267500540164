@import "../../../../../assets/sass/utils/utils";

.category-tabs {
  margin: 25px -10px 10px;
  padding-bottom: 15px;
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  align-items: center;
  width: calc(100% + 20px);
  padding-left: 10px;

  @media (min-width: 320px) {
    padding-left: ls(320, 374, 10, 25);
    width: calc(100% + #{ls-c(320, 374, 10, 25)} + #{ls-c(320, 374, 10, 25)} );
    margin-left: ls(320, 374, -10, -25);
    margin-right: ls(320, 374, -10, -25); }

  @media (min-width: 375px) {
    padding-left: 25px;
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px; }

  @media (min-width: 480px) {
    margin: 35px 0 20px;

    padding-left: ls(480, 1349, 25, 50);
    width: calc(100% + #{ls-c(480, 1349, 25, 50)} + #{ls-c(480, 1349, 25, 50)} );
    margin-left: ls(480, 1349, -25, -50);
    margin-right: ls(480, 1349, -25, -50); }

  @media (min-width: 768px) {
    margin-top: 42px;
    margin-bottom: 27px; }

  @media (min-width: 1024px) {
    margin: 50px 0;
    width: 100%;
    padding: 0;
    overflow-x: hidden; }

  .category-tab {
    text-align: center;
    white-space: nowrap;
    text-transform: $category_tab_text_transform;
    font-size: $category_tab_font_size;
    font-weight: $category_tab_font_weight;
    transition: all .2s;
    color: $category_tab_color;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1024px) {
      padding-right: 50px;
      width: auto; }

    @media (min-width: 1200px) {
      padding-right: 70px; }

    &:last-child {
      padding-right: 10px;

      @media (min-width: 320px) {
        padding-right: ls(320, 374, 10, 25); }

      @media (min-width: 375px) {
        padding-right: 25px; }

      @media (min-width: 480px) {
        padding-right: ls(480, 1349, 25, 50); }

      @media (min-width: 1024px) {
        padding-right: 0; } }

    + .category-tab {
      padding-left: 28px;

      @media (min-width: 480px) {
        padding-left: 32px; }

      @media (min-width: 576px) {
        padding-left: 40px; }

      @media (min-width: 1024px) {
        padding-left: 50px; }

      @media (min-width: 1200px) {
        padding-left: 70px; }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1px;
        height: 22px;
        background-color: #E6E6E6;

        @media (max-width: 1023px) {
          display: none; } } }

    &:hover {
      color: #8a8a8a;

      svg {
        fill: #8a8a8a; } }

    &.active {
      color: #000000;

      svg {
        fill: #000000; } }

    svg {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      transition: all .2s;
      fill: #ABABAB; } } }
