@import "utils/utils";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%; }

body {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  position: relative;
  color: #000000;
  font-family: $main-font;
  overflow-y: scroll; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  transition: all .2s; }
p {
  margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
  vertical-align: middle; }

textarea {
  resize: none; }

section {
  position: relative; }

input,
select,
button {
  outline: none; }

input {
  -webkit-appearance: none;
  appearance: none; }

*, *:before, *:after {
  box-sizing: border-box; }

#root {
  height: 100%;
  position: relative;

  > .page-loader {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%); } }

.app-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  z-index: 1;
  position: relative; }

.content {
  flex: 1 0 auto;
  position: relative;
  flex-direction: column;
  align-items: center;
  display: flex;

  .index-page & {
    display: flex;
    flex-direction: column;

    .workout-plans-section {
      -webkit-box-flex: 1;
      flex: 1 0 auto; } }

  > .page-loader {
    position: absolute;
    top: calc(50% - 30px);
    transform: translateY(-50%);
    left: 0;
    right: 0; }

  &.auth-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; } }

.container {
  margin: 0 auto;
  max-width: $content-size;
  width: 100%;
  padding: 0 10px;

  @media (min-width: 320px) {
    padding: 0 ls(320, 374, 10, 25); }

  @media (min-width: 375px) {
    padding: 0 25px; }

  @media (min-width: 480px) {
    padding: 0 ls(480, 1349, 25, 50); }

  @media (min-width: 1350px) {
    padding: 0 55px; } }

.container-fluid {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  padding: 0 10px;

  @media (min-width: 320px) {
    padding: 0 ls(320, 374, 10, 25); }

  @media (min-width: 375px) {
    padding: 0 25px; }

  @media (min-width: 480px) {
    padding: 0 ls(480, 1349, 25, 50); }

  @media (min-width: 1350px) {
    padding: 0 50px; } }

@import "utils/custom";

@import "components/components";
@import "modules/modules";
@import "common";
