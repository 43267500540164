.card {
  width: 100%;
  // height: 90px
  color: #fff;
  text-align: center;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all .2s ease-in-out;
  border-radius: 20px;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto 10px;
  height: auto;

  @media (min-width: 576px) {
    width: calc( (100% - 15px) / 2);
    margin: 0 0 15px;
    height: 140px; }

  @media (min-width: 640px) {
    height: 150px; }

  @media (min-width: 768px) {
    width: calc( (100% - 15px) / 2);
    margin: 0 0 15px;
    height: 160px; }

  @media (min-width: 890px) {
    width: calc( (100% - 30px) / 4);
    height: 200px;
    margin: 0; }

  @media (min-width: 1024px) {
    width: calc( (100% - 40px) / 4);
    height: 220px; }

  @media (min-width: 1200px) {
    width: calc( (100% - 60px) / 4);
    height: 240px; }

  @media (min-width: 1420px) {
    width: calc( (100% - 90px) / 4);
    height: 280px; }


  &:hover {
    @media (min-width: 1024px) {
      border-color: #54C5B0; }

    .content {
      @media (min-width: 1024px) {
        transform: translateY(-15px); } } }

  &.active {
    border-color: #54C5B0;

    .content {
      @media (min-width: 1024px) {
        transform: translateY(-15px); } }

    .flag {
      opacity: 1; }

    .flag .flagIcon {
      transform: translateY(0) scale(1); } } }

.inner {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 16px;
  align-items: center;
  padding: 25px 0;

  @media (min-width: 360px) {
    padding: 35px 0; }

  @media (min-width: 576px) {
    padding: 0; }

  @media (min-width: 1024px) {
    align-items: flex-end; }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.085) 100%); } }

.content {
  font-size: 1em;
  padding: 0 0.75em 2em;
  position: relative;
  transition: all .2s ease-in-out;

  @media (max-width: 1023px) {
    padding-bottom: 0; } }

.name {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.75em; }

.desc {
  font-size: 0.75em;
  font-weight: 600; }

.flag {
  font-size: 3.125em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: -1px;
  right: -1px;
  border-radius: 0 0.2em 0 0;
  border-top: 0.5em solid #54C5B0;
  border-bottom: 0.5em solid transparent;
  border-right: 0.5em solid #54C5B0;
  border-left: 0.5em solid transparent;
  opacity: 0;
  transition: all .2s ease-in-out; }

.flagIcon {
  position: absolute;
  stroke: #fff;
  width: 0.333333333em;
  height: 0.244444444em;
  right: -0.377777778em;
  top: -0.288888889em;
  transition: all .3s ease-in-out;
  transform: translateY(-8px) scale(0.8); }
