@mixin size($width, $height: $width) {
	width: $width;
	height: $height; }

@mixin pseudo($content: '', $display: block, $pos: absolute) {
	content: $content;
	display: $display;
	position: $pos; }

@mixin arrow($direction: 'down', $color: #000, $width: 10px, $height: $width) {
	@if $direction == up {
		border-left: $width / 2 solid transparent;
		border-right: $width / 2 solid transparent;
		border-bottom: $height solid $color; }
	@else if $direction == down {
		border-left: $height / 2 solid transparent;
		border-right: $height / 2 solid transparent;
		border-top: $width solid $color; }
	@else if $direction == right {
		border-top: $width / 2 solid transparent;
		border-bottom: $width / 2 solid transparent;
		border-left: $height solid $color; }
	// @else if $direction == left
	//   border-top: $size solid transparent
	//   border-bottom: $size solid transparent
 }	//   border-right: $size solid $color

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content; }

	&:-moz-placeholder {
		@content; }

	&::-moz-placeholder {
		@content; }

	&:-ms-input-placeholder {
		@content; } }

@mixin dots() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; }

@mixin X($x: -50%) {
	transform: translateX($x); }

@mixin Y($y: -50%) {
	transform: translateY($y); }

@mixin T($y: -50%, $x: $y) {
	transform: translate($y, $x); }

@mixin transition($time: .2s, $func: ease-in-out) {
	transition: $time all $func; }

@mixin position($top: auto, $right: $top, $bottom: $top, $left: $top) {
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left; }

@mixin pos-all($position: absolute) {
	position: $position;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0; }

@mixin circle-pic($size) {
	@include s($size);
	border-radius: 50%;
	object-fit: cover; }

@mixin fzl($fontSize, $lineHeight) {
	font-size: $fontSize;
	line-height: $lineHeight; }

@function ls($x1, $x2, $y1, $y2) {
	$m: ($y2 - $y1) / ($x2 - $x1);
	$b: $y1 - $m * $x1;

	@return calc(#{$m} * 100vw + #{$b}px); }

@function ls-c($x1, $x2, $y1, $y2) {
	$m: ($y2 - $y1) / ($x2 - $x1);
	$b: $y1 - $m * $x1;

	@return #{$m} * 100vw + #{$b}px; }
