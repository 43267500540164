@keyframes rotate {
  0% {
    transform: rotate(0deg); }

  50% {
    transform: rotate(180deg); }

  100% {
    transform: rotate(360deg); } }

.loader-icon {
  > div {
    border-radius: 100%;
    animation-fill-mode: both; }

  > div {

    border: 3px solid #7DF3B3;
    border-bottom-color: transparent !important;
    height: 24px;
    width: 24px;
    background: transparent !important;
    display: inline-block;
    animation: rotate 0.75s 0s linear infinite; } }
