.categories-list {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 640px) {
    margin-bottom: -20px; }

  @media (min-width: 768px) {
    margin-bottom: -30px; }

  @media (min-width: 890px) {
    margin-bottom: -40px; }

  @media (min-width: 1024px) {
    margin-bottom: -50px; }

  .category-preview {
    width: 100%;

    @media (min-width: 640px) {
      width: calc( (100% - 20px) / 2 );
      margin-bottom: 20px; }

    @media (min-width: 768px) {
      width: calc( (100% - 30px) / 2 );
      margin-bottom: 30px; }

    @media (min-width: 890px) {
      width: calc( (100% - 40px) / 2 );
      margin-bottom: 40px; }

    @media (min-width: 1024px) {
      width: calc( (100% - 47px) / 2 );
      margin-bottom: 50px; }

    + .category-preview {
      margin-top: 20px;

      @media (min-width: 640px) {
        margin-top: 0; } }

    &:not(:nth-child(2n)) {
      @media (min-width: 640px) {
        margin-right: 20px; }

      @media (min-width: 768px) {
        margin-right: 30px; }

      @media (min-width: 890px) {
        margin-right: 40px; }

      @media (min-width: 1024px) {
        margin-right: 47px; } } } }
