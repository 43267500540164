.wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#2C2C2C, 0.8);
  z-index: 25;

  @media (min-width: 576px) {
    overflow-x: hidden;
    overflow-y: auto; } }

.inner {
  position: relative;
  height: 100%;

  @media (min-width: 576px) {
    padding: 8vh 0;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    overflow-y: visible; } }

.modal {
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  transition: all .2s;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (min-width: 576px) {
    width: 1240px;
    min-height: 0;
    max-width: calc(100% - 30px);
    border-radius: 10px;
    height: auto;
    overflow: hidden; } }

.modalWrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px 25px;

  @media (min-width: 480px) {
    padding: 55px 35px; }

  @media (min-width: 576px) {
    min-height: 72vh; }

  @media (min-width: 890px) {
    padding: 45px 45px; }

  @media (min-width: 1024px) {
    padding: 60px 60px; }

  @media (min-width: 1200px) {
    padding: 80px 80px; } }

.close {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.75em;
  right: 0.75em;
  font-size: 17px;

  @media (min-width: 576px) {
    font-size: 20px; } }

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  background-color: #54C4B0;
  height: 5px;
  transition: all .2s;

  @media (min-width: 480px) {
    height: 6px; }

  @media (min-width: 640px) {
    height: 7px; }

  @media (min-width: 1024px) {
    height: 8px; }

  @media (min-width: 1240px) {
    height: 10px; } }
