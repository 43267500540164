@import "../../../../../assets/sass/utils/utils";

.category-list {
  overflow: hidden;

  + .category-list {
    margin-top: 70px; } }

.category-list-carousel.small {
  .workout-card {
    &__btn.btn-circle {
      display: none; }

    &__info {
      width: 100%;
      text-align: center; }

    &__params {
      justify-content: center; } } }
