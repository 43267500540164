@import "../../../assets/sass/utils/utils";

.category-tag {
  display: flex;
  align-items: center;
  font-family: $second-font;
  text-transform: capitalize;
  font-size: $category_tag_font_size;
  line-height: 1;
  color: $category_tag_color;
  font-weight: $category_tag_font_weight;
  background-color: #ffffff;
  padding: $category_tag_padding;
  border-radius: $category_tag_border_radius;

  &.beginner {
    background-color: $color_level_beginner; }

  &.intermediate {
    background-color: $color_level_medium; }

  &.advanced {
    background-color: $color_level_advanced; }

  &__icon {
    @include size(15px);
    margin-right: 6px; } }
