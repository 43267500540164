@import "../../../assets/sass/utils/utils";

.full-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.full-modal-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, .3); }

.full-modal {
  background-color: #000000;
  z-index: 3;
  color: #000;
  position: relative;
  padding: 40px 0 0;
  height: 82.1vh;

  @media (min-width: 669px) {
    padding: 50px 0 0; }

  @media (min-width: 1024px) {
    padding: 54px 0 0; }

  .container {
    height: 100%; }

  &__close {
    position: absolute;
    top: 36px;
    right: 10px;
    @include size(28px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    @media (min-width: 320px) {
      right: ls(320, 374, 10, 25); }

    @media (min-width: 375px) {
      right: 25px; }

    @media (min-width: 400px) {
      @include size(21px, 32px);
      top: 40px; }

    @media (min-width: 480px) {
      @include size(18px, 30px);
      right: ls(480, 1349, 25, 50); }

    @media (min-width: 576px) {
      @include size(32px);
      right: 30px;
      top: 36px; }

    @media (min-width: 669px) {
      top: 46px; }

    @media (min-width: 768px) {
      top: 47px;
      right: 30px; }

    @media (min-width: 1024px) {
      @include size(38px);
      top: 15px;
      right: 40px; }

    @media (min-width: 1200px) {
      @include size(42px);
      top: 20px;
      right: 40px; }

    @media (min-width: 1440px) {
      top: 50px;
      right: 40px;
      @include size(47px); }

    .icon-close {
      @include size(18px);
      fill: #ffffff;

      @media (min-width: 480px) {
        @include size(21px); }

      @media (min-width: 768px) {
        @include size(24px); }

      @media (min-width: 1024px) {
        @include size(27px); } } } }

.full-modal-inner {
  height: 100%;
  position: relative;

  .page-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }
