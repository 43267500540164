@import "../../../assets/sass/utils/utils";

button {
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent; }

.btn-primary {
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  background-color: #000000;
  padding: 0 26px;
  height: 54px;
  justify-content: center;
  transition: all .2s;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  padding-top: $btn_padding_top;
  min-width: 130px;
  font-size: $btn_font_size_base;
  font-weight: $btn_font_weight;
  border-radius: $btn_border_radius;

  @media (min-width: 480px) {
    height: 58px;
    min-width: 140px;
    font-size: $btn_font_size_480; }

  @media (min-width: 768px) {
    height: 62px;
    min-width: 150px;
    font-size: $btn_font_size_768; }

  > * {
    z-index: 1; }

  &.with-icon {
    padding: 0 18px 0 26px; }

  svg {
    fill: #fff;
    width: 19px;
    height: 19px;
    margin-left: 11px; }

  &:hover {
    background-color: rgba(#000000, 0.8); }

  &:active {
    background-color: #000000; }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    cursor: auto; } }

.btn-secondary {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(157, 157, 157, 0.3);
  color: #000000;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  padding: 0 50px;
  transition: all .2s;
  min-width: 130px;
  text-transform: uppercase;
  font-size: $btn_font_size_base;
  font-weight: $btn_font_weight;
  border-radius: $btn_border_radius;

  @media (min-width: 480px) {
    font-size: $btn_font_size_480;
    height: 58px;
    min-width: 140px; }

  @media (min-width: 768px) {
    height: 62px;
    font-size: $btn_font_size_768;
    min-width: 150px; }

  &:hover {
    background-color: rgba(#000000, 0.04); }

  &:active {
    background-color: rgba(#000000, 0.14); } }
