@import "../../../assets/sass/utils/utils";

.select-input-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #E5E5E5;
  cursor: pointer;
  position: relative; }

.select-input {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  color: #000000;
  padding: 18px 22px;
  font-size: 16px;
  line-height: 156.49%;
  font-weight: 600;
  cursor: pointer;

  &:active {
    ~ .select-input-icon {
      transform: translateY(-50%) rotate(-180deg); } } }

.select-input-icon {
  transition: all .2s;
  @include size(12px, 7px);
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); }
