.title-add {
	color: #000000;
	font-size: 12px;
	line-height: 156.49%;
	font-family: $second-font;

	@media (min-width: 480px) {
		font-size: 13px; }

	@media (min-width: 576px) {
		font-size: 14px; }

	@media (min-width: 1024px) {
		font-size: 15px; }

	@media (min-width: 1200px) {
		font-size: 16px; }

	p + p {
		margin-top: 20px;

		@media (min-width: 890px) {
			margin-top: 30px; } } }
