.loader-wrap {
  position: relative; }

.loader-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.inner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;

  &.spin {
    opacity: 0; } }
