@import "../../../../../assets/sass/utils/utils";

.workout-play-finish {
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10.75vh 0 16.75vh;

  &__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 12px;
    text-transform: uppercase; }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 36px;

    @media (min-width: 480px) {
      font-size: 36px;
      line-height: 44px; }

    @media (min-width: 576px) {
      font-size: 42px;
      line-height: 51px; }

    @media (min-width: 768px) {
      font-size: 46px;
      line-height: 58px; }

    @media (min-width: 890px) {
      font-size: 52px;
      line-height: 60px; }

    @media (min-width: 1024px) {
      font-size: 56px;
      line-height: 64px; }

    @media (min-width: 1200px) {
      font-size: 60px;
      line-height: 72px; } }

  &__subtitle {
    font-family: $second-font;
    font-size: 14px;

    @media (min-width: 480px) {
      font-size: 15px; }

    @media (min-width: 768px) {
      font-size: 16px; } }

  .icon-finish {
    margin-top: 11.5vh;
    @include size(156px);

    @media (min-width: 375px) {
      @include size(ls(375, 1200, 156, 300)); }

    @media (min-width: 1200px) {
      @include size(300px); } } }
