.app-wrap {

  > div,
  > footer {
    z-index: 2; }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: #F7F7F7;
    height: 550px;
    max-height: 100%;
    z-index: -1; }

  &.index-page {
    &::before {
      height: 680px;

      @media (min-width: 1350px) {
        height: 1220px;
        height: 940px; } } }

  &.workouts-page-wrapper {
    &::before {
      height: 700px; } }

  &.categories-page {
    &::before {
      height: 385px;

      @media (min-width: 375px) {
        height: ls(375, 1349, 385, 458); }

      @media (min-width: 1350px) {
        height: 458px; } } }

  &.meals-page {
    background-color: #F7F7F7;

    &::before {
      display: none; }

    .content {
      z-index: 3; } }

  &.history-page,
  &.author-page {
    background-color: #F7F7F7;

    &::before {
      display: none; } }

  &.profile-page,
  &.workouts-plan-page,
  &.error-page {
    background-color: #F7F7F7;

    &::before {
      display: none; } }

  &.auth-page,
  &.workout-play-page {
    background-color: #F7F7F7;
    background-image: url('../../../images/general/top-figure-bg.svg'), url('../../../images/general/bottom-figure-bg.svg'), url('../../../images/general/top-right-figure-bg.svg');
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 589px 527px, 400px 395px, 229px 296px;
    background-position: -435px 75px, calc(100% + 120px) calc(100% + 250px), calc(100% + 80px) -70px;

    @media (min-width: 375px) {
      background-size: ls(375, 1599, 589, 749) ls(375, 1599, 527, 719), ls(375, 1599, 400, 847) ls(375, 1599, 395, 843), ls(375, 1599, 229, 529) ls(375, 1599, 296, 496);
      background-position: ls(375, 1599, -435, -259) ls(375, 1599, 75, 100), calc(100% + #{ls-c(375, 1599, 120, 220)}) calc(100% + #{ls-c(375, 1599, 250, 460)}), calc(100% + #{ls-c(375, 1599, 80, -50)}) ls(375, 1599, -70, -240); }

    @media (min-width: 1600px) {
      background-position: -259px 100px, calc(100% + 220px) calc(100% + 460px), calc(100% - 50px) -240px;
      background-size: 749px 719px, 847px 843px, 529px 496px; }

    &::before {
      display: none; } }

  &.workout-play-page {
    @media (max-width: 767px) {
      background-image: none; } } }
