@import "../../../assets/sass/utils/utils";

.tag {
  background-color: #5D54A0;
  font-weight: $tag_font_weight;
  font-size: $tag_font_size;
  line-height: 1;
  color: $tag_font_color;
  padding: $tag_font_padding;
  text-transform: $tag_font_transform;
  border-radius: $tag_border_radius;
  font-family: $second-font; }
