@import "../../../../../assets/sass/utils/utils";

.wrap {
  width: 100%;
  position: relative; }

.input {
  width: 100%;
  display: block;
  padding: 0.8542em 1em;
  font-size: 1em;
  line-height: 1.5649em;
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  text-align: center;

  @include placeholder() {
    color: rgba(#000, .3); }

  &.error {
    border: 1px solid rgba(#ff0000, 0.9);
    color: #ff0000;

    + .helper {
      color: rgba(#ff0000, 0.9); } } }

.helper {
  font-size: 1em;
  font-weight: 400;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.66666667em;
  color: rgba(#000, 0.5); }

.errorHelper {
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 0.75em;
  line-height: 1.4em;
  color: rgba(#ff0000, 0.9); }
