@import "../../../assets/sass/utils/utils";

.author.content-page-inner {
  max-width: 972px;
  margin: 0 auto;

  @media (min-width: 720px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

  @media (min-width: 890px) {
    align-items: center; }

  &::after {
    display: none; } }

.author-left {
  @media (min-width: 720px) {
    width: calc(55% - 20px);
    max-width: 500px; } }

.author-right {
  @media (min-width: 720px) {
    width: calc(45% - 15px);
    max-width: 352px; } }

.author-name {
  margin-bottom: 25px;
  order: -2; }

.socials-group {
  display: flex;
  align-items: center;
  margin-top: 30px;

  @media (min-width: 480px) {
    margin-top: 40px; }

  @media (min-width: 576px) {
    margin-top: 50px; }

  @media (min-width: 720px) {
    margin-top: 30px; }

  @media (min-width: 890px) {
    margin-top: 45px; }

  @media (min-width: 1024px) {
    margin-top: 60px; } }

.social-btn {
  @include size(56px);
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  border-radius: $author_border_radius;

  @media (min-width: 890px) {
    @include size(59px); }

  @media (min-width: 1024px) {
    @include size(62px); }

  + .social-btn {
    margin-left: 5px; }

  &:hover {
    background-color: rgba(#000000, 0.9); } }

.social-icon {
  @include size(22px);
  fill: #ffffff; }

.author-pic {
  width: 100%;
  display: block;
  order: -1;
  margin-bottom: 25px;
  border-radius: $author_border_radius;
  overflow: hidden;

  @media (min-width: 720px) {
    margin-bottom: 0; }

  .image-creator-error {
    height: 380px; } }
