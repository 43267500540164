button {
	cursor: pointer;
	padding: 0;
	border: 0;
	background-color: transparent; }

.btn-circle {
	@include size(53px);
	border-radius: 50%;
	border: 1px solid rgba(#fff, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		fill: #fff;
		@include size(18px, 15px); } }

.btn-wrap {
	display: flex;
	align-items: center;
	justify-content: center; }
