.icon {
  font-size: 60px;
  margin-bottom: 0.333333333337em;
  font-size: 40px;

  @media (min-width: 480px) {
    font-size: 45px; }

  @media (min-width: 890px) {
    font-size: 55px; }

  @media (min-width: 1024px) {
    font-size: 55px; }

  @media (min-width: 1240px) {
    font-size: 60px; }

  img {
    width: 1em;
    height: 1em; } }
