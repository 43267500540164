@import "../../../assets/sass/utils/utils";

.history {
  &__head {
    margin-bottom: 35px;

    @media (min-width: 720px) {
      margin-bottom: 0; } }

  &__title {
    margin-bottom: $history_title_margin_bottom; }

  &__title-add {
    font-size: 16px;
    max-width: 320px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 35px; }

    @media (min-width: 1024px) {
      margin-bottom: 40px; } } }

.history-counters {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.history-counter {
  width: calc( (100% - 10px) / 3);
  text-align: center;
  padding: 11px 0 16px;
  border-radius: $history_counter_border_radius;
  border: $history_counter_border;
  background-color: $history_counter_bg;

  @media (min-width: 1024px) {
    padding: 12px 0 18px; }

  @media (min-width: 1200px) {
    width: calc( (100% - 20px) / 3);
    padding: 13px 0 20px; }

  &__title {
    font-size: $history_counter_title_font_size;
    line-height: $history_counter_title_line_height;
    font-weight: $history_counter_title_font_weight;
    margin-bottom: 3px;
    font-family: $second-font;
    padding: 0 5px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1024px) {
      margin-bottom: 4px; }

    @media (min-width: 1200px) {
      margin-bottom: 5px; } }

  &__value {
    text-transform: $history_counter_value_text_transform;
    font-size: $history_counter_value_font_size_base;
    line-height: 146.49%;
    letter-spacing: $history_counter_value_letter_spacing_base;
    font-weight: $history_counter_value_font_weight;

    @media (min-width: 1024px) {
      font-size: $history_counter_value_font_size_1024; }

    @media (min-width: 1200px) {
      font-size: $history_counter_value_font_size_1200;
      letter-spacing: $history_counter_value_letter_spacing_1200; } } }

.history-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin-bottom: 40px; }

  @media (min-width: 1024px) {
    margin-bottom: 45px; }

  &__title {
    width: 100%;
    margin-bottom: 15px;

    @media (min-width: 480px) {
      width: auto;
      margin-bottom: 0;
      margin-right: 20px; }

    @media (min-width: 720px) {
      width: 100%;
      margin-bottom: 15px; }

    @media (min-width: 890px) {
      width: auto;
      margin-bottom: 0;
      margin-right: 20px; }

    &.auto {
      width: auto; } }

  &__filter {
    width: 110px;
    margin-left: auto;
    transition: all .25s;

    &.edit-hide {
      opacity: 0;
      pointer-events: none; } } }

.history-item-wrap + .history-item-wrap {
  margin-top: 10px; }

.history-item-wrap {
  position: relative;

  &.history-item-wrap-delete {
    .history-item__level span,
    .history-item .history-item__icon {
      background-color: #c3c3c3; }

    .history-item {
      opacity: 0.5; } }

  &.history-item-wrap-edit {
    .history-item {
      width: calc(100% - 24px);
      padding: $history_item_padding_base_edit; // -6

      @media (min-width: 1350px) {
        padding: $history_item_padding_1350_edit; } } // -10

    .history-item__level {
      @media (min-width: 1200px) {
        margin-left: 10px; } }

    .history-item__date {
      @media (min-width: 1350px) {
        margin-right: 24px; } }

    .history-item__name {
      @media (min-width: 1200px) {
        width: calc(100% - 10px - 76px - 110px - 160px - 15px + 10px); }

      @media (min-width: 1350px) {
        width: calc(100% - 38px - 76px - 110px - 160px - 25px + 10px + 14px); } }

    .history-item-delete {
      right: 0;
      opacity: 1;
      pointer-events: auto; } } }

.history-item {
  $t: &;

  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  padding: $history_item_padding_base;
  flex-wrap: wrap;
  color: $history_item_color;
  background-color: $history_item_bg;
  border-radius: $history_item_border_radius;
  border: $history_item_border;
  width: 100%;
  transition: all .25s;

  @media (min-width: 1350px) {
    padding: $history_item_padding_1350; }

  &--intermediate {
    #{$t}__icon {
      background-color: $color_level_medium; }

    #{$t}__level {
      span {
        background-color: $color_level_medium; } } }

  &--beginner {
    #{$t}__icon {
      background-color: $color_level_beginner; }

    #{$t}__level {
      span {
        background-color: $color_level_beginner; } } }

  &--advanced {
    #{$t}__icon {
      background-color: $color_level_advanced; }

    #{$t}__level {
      span {
        background-color: $color_level_advanced; } } }

  &__date {
    width: 50%;
    font-family: $second-font;
    font-size: $history_item_date_font_size;
    font-weight: $history_item_date_font_weight;
    line-height: 176%;
    order: -2;
    transition: all .25s;

    @media (min-width: 1200px) {
      order: 0;
      width: 76px;
      margin-right: 10px; }

    @media (min-width: 1350px) {
      margin-right: 38px; } }

  &__name {
    width: 100%;
    position: relative;
    text-transform: $history_item_name_text_transform;
    font-size: $history_item_name_font_size_base;
    font-weight: $history_item_name_font_weight;
    line-height: $history_item_name_line_height;
    margin: 8px 0 11px;
    padding-right: 25px;
    transition: all .25s;

    @media (min-width: 1200px) {
      margin: 0;
      width: calc(100% - 10px - 76px - 110px - 160px - 15px);
      padding-right: 0; }

    @media (min-width: 1350px) {
      font-size: $history_item_name_font_size_1350;
      width: calc(100% - 38px - 76px - 110px - 160px - 25px); } }

  &__name-inner {
    position: relative;
    padding-left: 42px;

    span {
      padding-top: 3px;
      display: block;

      @media (min-width: 768px) {
        padding-top: 0; } } }

  &__icon {
    border-radius: 50%;
    @include size(26px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all .25s;

    svg {
      fill: #000000;
      fill: #000000;
      @include size(16px); } }

  &__level {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
    order: -1;
    transition: all .25s;

    @media (min-width: 1200px) {
      order: 0;
      width: 90px;
      margin-left: 20px; }

    span {
      padding: 8px 10px;
      font-family: $second-font;
      font-size: $history_item_level_font_size;
      line-height: $history_item_level_line_height;
      font-weight: $history_item_level_font_weight;
      border-radius: $history_item_level_border_radius;
      transition: all .25s; } }

  &__params {
    width: 100%;

    @media (min-width: 1200px) {
      width: 160px;
      margin-left: 15px; }

    @media (min-width: 1350px) {
      margin-left: 25px; }

    .param {
      svg {
        fill: #000000; } } } }

.history-footer {
  margin-top: 30px;
  transition: all .25s;

  @media (min-width: 576px) {
    margin-top: 35px; }

  @media (min-width: 768px) {
    margin-top: 40px; }

  @media (min-width: 1024px) {
    margin-top: 45px; }

  @media (min-width: 1200px) {
    margin-top: 55px; }

  &.edit-hide {
    opacity: 0;
    pointer-events: none;
    transform: translateY(25px); } }

.history-head-left {
  display: flex;
  align-items: center; }

.history-nav {
  display: flex;
  align-items: center; }

.history-nav-edit {
  display: flex;
  align-items: center;
  background-color: $p_history_nav_edit_bgc;
  padding: 11px 13px;
  font-size: 16px;
  text-transform: uppercase;
  transition: all .25s ease-in-out;
  line-height: 16px;
  border-radius: $p_history_nav_edit_brs;

  .icon-pencil {
    @include size(13px);
    margin-right: 14px; }

  &:hover {
    background-color: $p_history_nav_edit_bgc_hover; } }

.history-nav-discard {
  padding: 11px 13px;
  font-size: 16px;
  line-height: 1;
  transition: all .25s ease-in-out;
  font-family: $second-font;
  font-size: 12px;
  line-height: 16px;
  margin-left: 5px;
  transition: all .25s ease-in-out;
  border-radius: $p_history_nav_edit_brs;

  &:hover {
    background-color: rgba(#000, 0.03); } }

.history-item-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 100%;
  font-size: 24px;
  transition: all .25s;
  border-radius: $p_history_nav_delete_brs;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  opacity: 0;
  pointer-events: none;

  &:hover {
    background-color: rgba(#000, 0.025); }

  .history-item-delete-icon {
    width: 0.416666667em;
    height: 0.416666667em; } }
