@import "../../../assets/sass/utils/utils";

.view-all-link {
  $t: &;

  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  color: #000000;
  font-family: $second-font;

  @media (min-width: 576px) {
    font-size: 15px; }

  @media (min-width: 768px) {
    font-size: 16px; }

  &:hover {

    #{$t}__name {
      border-color: #000000; }

    #{$t}__arrow {
      transform: translateX(3px); } }

  &__arrow {
    padding-bottom: 6px;
    margin-left: 4px;
    transition: all .2s; }

  &__name {
    transition: all .2s;
    border-bottom: 1px solid transparent;
    padding-bottom: 1px; }

  .arrow-right {
    fill: #000000;
    @include size(18px, 15px);
    margin-left: 12px; } }
