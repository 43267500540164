@import "../../../../../assets/sass/utils/utils";

.meal-preview {
  background-color: #000000;
  height: 100%;

  @media (min-width: 1024px) {
    padding-top: 20px; }

  @media (min-width: 1440px) {
    padding-top: 0; }

  &.fixed {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2; }

  .btn-primary {
    background-color: #fff;
    color: #000000; }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1px;

    @media (min-width: 576px) {
      margin-bottom: 40px;
      padding-right: 50px;
      margin-top: -15px;
      padding-top: 0; }

    @media (min-width: 720px) {
      margin-bottom: 40px; }

    @media (min-width: 768px) {
      margin-top: -14px; }

    @media (min-width: 1024px) {
      margin-top: 0;
      padding-right: 0; }

    @media (min-width: 1440px) {
      margin-top: -10px; }

    .fixed-list & {
      margin-top: 0; } }

  &__back {
    display: flex;
    align-items: center;
    color: #ffffff;
    text-transform: $meal_preview_back_text_transform;
    font-size: $meal_preview_back_font_size_base;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    height: 22px;
    z-index: 1;
    font-weight: $meal_preview_back_font_weight;

    @media (min-width: 400px) {
      height: 30px; }

    @media (min-width: 480px) {
      height: 32px; }

    @media (min-width: 576px) {
      position: static;
      font-size: $meal_preview_back_font_size_576; }

    @media (min-width: 768px) {
      font-size: $meal_preview_back_font_size_768; }

    @media (min-width: 890px) {
      font-size: $meal_preview_back_font_size_890; }

    @media (min-width: 1024px) {
      font-size: $meal_preview_back_font_size_1024; }

    svg {
      fill: #ffffff;
      @include size(20px, 16px);

      @media (min-width: 576px) {
        margin-right: 15px; } }

    span {
      @media (max-width: 575px) {
        display: none; } } }

  &__body {
    overflow-y: auto;
    padding-bottom: 40px;
    max-height: calc(100% - 30px);
    padding-right: 15px;
    width: calc(100% + 15px);
    margin-right: -15px;

    @media (min-width: 576px) {
      max-height: calc(100% - 24px - 62px); }

    @media (min-width: 1024px) {
      max-height: calc(100% - 36px - 62px); } }

  .loader-wrap {
    position: absolute;
    bottom: 0;
    left: -10px;
    right: -10px;

    @media (min-width: 320px) {
      left: ls(320, 374, -10, -25);
      right: ls(320, 374, -10, -25); }

    @media (min-width: 375px) {
      left: -25px;
      right: -25px; }

    @media (min-width: 480px) {
      right: ls(480, 1349, -25, -60);
      left: ls(480, 1349, -25, -60); }

    @media (min-width: 576px) {
      position: relative;
      left: 0;
      right: 0; }

    .btn-primary {
      @media (max-width: 575px) {
        width: 100%;
        border-radius: 0; } } }

  .meal {
    .title {
      text-align: center;
      background-color: #000000;
      padding: 0 34px 20px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      @media (max-width: 399px) {
        font-size: 22px; }

      @media (max-width: 575px) {
        margin-bottom: 40px; }

      @media (min-width: 576px) {
        text-align: left;
        padding: 0; }

      @media (min-width: 576px) {
        background-color: transparent;
        position: static; } }

    .param-list {
      margin-top: 66px;

      @media (min-width: 400px) {
        margin-top: 86px; }

      @media (min-width: 480px) {
        margin-top: 90px; }

      @media (min-width: 576px) {
        margin-top: 0; } } } }
