@import "../../../../../assets/sass/utils/utils";

.meals-list-section {
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    background-color: $content_under_carouseles;
    height: 198px;

    @media (min-width: 503px) {
      height: 153px; }

    @media (min-width: 1021px) {
      height: 125px; } }

  .swiper-container {
    width: 100%;
    overflow: visible; }

  .meal-plan-preview-pagination {
    @media (min-width: 1024px) {
      display: none; } } }

.meal-plan-preview-list {
  position: relative;

  .swiper-slide {
    max-width: 100%;
    width: 325px;

    @media (min-width: 503px) {
      width: 220px; } }

  .meal-card {
    height: 250px;

    @media (min-width: 503px) {
      height: 214px; }

    &__inner {
      padding: 16px 16px 20px; }

    &__name {
      line-height: 26px; }

    .tag {
      padding: $home_meal_card_tag_padding; }

    .param-list {
      .param {
        margin: 0 4px 7.5px;

        &:first-child {
          margin-left: 0; }

        &:last-child {
          margin-right: 0; } } } } }

.meals-list-section-head {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 890px) {
    margin-bottom: 35px; }

  @media (min-width: 1200px) {
    margin-bottom: 40px; }

  .title-medium {
    width: auto; } }
