@import "../../../../../../../assets/sass/utils/utils";

.plan-cart-product {
  $t: &;

  cursor: pointer;
  transition: all .2s;
  border-radius: 10px;
  display: block;
  padding: 10px;
  margin: 0 -10px;
  width: calc(100% + 20px);

  &:hover {
    background-color: rgba(#F1F1F1, 0.3); }

  > input {
    display: none;

    &:checked {
      ~ #{$t}__body {
        #{$t}__icon {
          background-color: #54C4B0;

          svg {
            opacity: 1; } } } } }

  &__body {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; }

  &__icon {
    background-color: #F1F1F1;
    border-radius: 5px;
    transition: all .2s;
    @include size(24px);
    margin-right: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;

    @media (min-width: 480px) {
      margin-top: 0; }

    svg {
      fill: #fff;
      @include size(13px, 10px);
      transition: all .2s;
      opacity: 0; } }

  &__pic {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    min-height: 190px;
    order: -1;
    margin-bottom: 30px;

    @media (min-width: 480px) {
      min-height: 120px;
      width: 160px;
      margin-bottom: 0;
      order: 0; } }

  &__info {
    width: calc(100% - 24px - 19px);

    @media (min-width: 370px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; }

    @media (min-width: 480px) {
      width: calc(100% - 24px - 19px - 160px);
      padding-left: 21px;
      display: block; } }

  &__desc {

    @media (min-width: 370px) {
      width: calc(100% - 90px); }

    @media (min-width: 480px) {
      width: 100%; } }

  &__title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 8px;

    @media (min-width: 480px) {
      font-size: 20px;
      line-height: 27px; } }

  &__text {
    font-size: 12px;
    line-height: 16px;
    padding-right: 10px;

    @media (min-width: 480px) {
      min-height: 64px; } }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000;

    @media (min-width: 370px) {
      width: 82px;
      flex-direction: column;
      align-items: flex-end; }

    @media (min-width: 480px) {
      width: 100%; }

    span {
      color: #F33658;
      font-size: 16px;
      line-height: 22px;
      text-decoration: line-through;
      margin-right: 15px;

      @media (min-width: 370px) {
        margin-bottom: 1px;
        margin-right: 0; } } } }
