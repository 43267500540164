@import "../../../assets/sass/utils/utils";

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%; }

.title {
  font-size: 22px;
  line-height: 156.49%;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1em;

  @media (min-width: 375px) {
    font-size: 23px; }

  @media (min-width: 480px) {
    font-size: 24px; }

  @media (min-width: 890px) {
    font-size: 26px; } }

.body {
  width: 100%;
  margin: auto 0; }

.desc {
  width: 100%;
  font-size: 16px;

  @media (min-width: 480px) {
    font-size: 17px; }

  @media (min-width: 890px) {
    font-size: 18px; }

  &.descCenter {
    text-align: center;

    br {
      @media screen and ( max-width: 374px ) {
        display: none; } } } }

.inputWrap {
  width: 100%;
  margin: 30px 0;
  position: relative; }

.input {
  width: 100%;
  font-size: 14px;
  color: #000;
  border-radius: 10px;
  padding: 18px 23px;
  border: 1px solid #E5E5E5;

  @include placeholder() {
    color: rgba(#000, 0.6); } }

.footer {
  width: 100%;
  margin-top: auto;
  padding-bottom: 30px;
  padding-top: 30px;

  @media (min-width: 576px) {
    padding-bottom: 0; } }

.errorHelper {
  position: absolute;
  left: 0;
  top: 100%;
  color: #ff0064;
  font-size: 12px;
  line-height: 1.6em;

  @media ( min-width: 890px ) {
    .errorHelper {
      font-size: 13px; } } }

.button {
  width: 100%;
  background-color: #86DBCC;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  line-height: 18px;
  font-size: 13px;
  padding: 18px 28px;
  text-align: center;
  font-weight: 700;
  transition: all .3s ease-in-out;

  @media (min-width: 375px) {
    padding: 20px 30px; }

  @media (min-width: 480px) {
    padding: 22px 30px; }

  @media (min-width: 640px) {
    padding: 24px 30px; }

  &:hover {
    background-color: #54C4B0; }

  &.transparent {
    background-color: transparent;
    color: #86DBCC;
    padding: 11px 30px;
    margin-top: 10px;

    &:hover {
      background-color: rgba(#54C4B0, .1); } }

  &:disabled {
    background-color: rgba(#000, 0.1);
    cursor: not-allowed; } }
