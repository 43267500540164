.filter-select-list {
  display: flex;
  align-items: center;

  .select-wrap + .select-wrap {
    margin-left: 25px;

    @media (min-width: 576px) {
      margin-left: 30px; }

    @media (min-width: 768px) {
      margin-left: 35px; } } }
