@import "../../../../../assets/sass/utils/utils";

.recipes-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5vh 0;

  &__text {
    margin-top: 50px;

    @media (min-width: 375px) {
      margin-top: ls(375, 889, 50, 65); }

    @media (min-width: 890px) {
      margin-top: 65px; }

    &-title {
      text-transform: uppercase;
      font-size: 30px;
      line-height: 1;
      margin-bottom: 6px;
      font-weight: 700;

      @media (min-width: 576px) {
        margin-bottom: 14px; } }

    &-desc {
      font-family: $second-font;
      font-size: 14px;
      line-height: 1;

      @media (min-width: 576px) {
        font-size: 15px; }

      @media (min-width: 890px) {
        font-size: 16px; } } }

  &__icon {
    @include size(120px);

    @media (min-width: 375px) {
      @include size(ls(375, 1199, 120, 195)); }

    @media (min-width: 1200px) {
      @include size(195px); } } }
