@import "../../../../../assets/sass/utils/utils";

.workout-play-slide-rest {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  opacity: 0;

  @media (max-width: 1023px) {
    height: 70vh; }

  @media (min-width: 1024px) {
    padding: 30px 0; }

  .swiper-slide-active & {
    opacity: 1; }

  .timer {
    &__icon {
      @include size(29vh); } } }

.workout-play-rest-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 36px;
  text-transform: $wt_rest_title_text_transform;

  @media (min-width: 480px) {
    font-size: 36px;
    line-height: 44px; }

  @media (min-width: 576px) {
    font-size: 42px;
    line-height: 51px;
    margin-bottom: 50px; }

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 58px; }

  @media (min-width: 890px) {
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 60px; }

  @media (min-width: 1024px) {
    font-size: 56px;
    line-height: 64px;
    margin-bottom: 70px; }

  @media (min-width: 1200px) {
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 80px; }

  .icon-rest-icon {
    fill: #000000;
    margin-right: 6px;
    @include size(21px);

    @media (min-width: 375px) {
      @include size(ls(375, 1200, 21, 42)); }

    @media (min-width: 576px) {
      margin-right: 10px; }

    @media (min-width: 1200px) {
      @include size(42px); } } }
