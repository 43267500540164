@import "../../../../../assets/sass/utils/utils";

.plan-cart-icon {
  fill: #000;
  @include size(52px, 48px);

  @media (min-width: 480px) {
    @include size(58px, 52px); }

  @media (min-width: 1200px) {
    @include size(65px, 60px); } }

.plan-cart-head-transition-enter {
  opacity: 0; }

.plan-cart-head-transition-enter-active {
  opacity: 1;
  transition: all .2s ease-in-out; }

.plan-cart-head-transition-exit {
  opacity: 1; }

.plan-cart-head-transition-exit-active {
  opacity: 0;
  transition: all .2s ease-in-out; }
