// Sizes
$base-font-size: 16px;

$content-size: 1360px;

// Fonts

$main-font: "DIN Condensed";
$second-font: "HelveticaNeue";
$quote-font: "Kalam";

// Components

// Logo
$logo_img_width: 132px;
$logo_img_height: 34px;

$logo_company_font_size: 13px;
$logo_company_position_top: calc(100% + 1px);
$logo_company_position_right: 3px;
$logo_company_color: #000000;

// MainNav Link
$main_nav_link_color: #000000;
$main_nav_link_font_size: 15px;
$main_nav_link_font_weight: 700;
$main_nav_link_text_transform: uppercase;

// Header
$header_main_nav_link_margin_left_base: 22px;
$header_main_nav_link_margin_left_desktop_1024: 24px;
$header_main_nav_link_margin_left_desktop_1200: 40px;
$header_main_nav_link_margin_left_desktop_1350: 55px;
$header_main_nav_link_margin_left_desktop_1440: 62px;
$header_main_nav_link_margin_left_desktop_1540: 70px;

// Footer
$footer_main_bg: #060606;
$footer_inner_border_top: none;

$footer_copyright_font_size: 12px;
$footer_copyright_color: #ffffff;

$footer_nav_terms_margin_left: 14px;
$footer_nav_terms_color: #6B6B6B;
$footer_nav_terms_font_size: 12px;
$footer_nav_terms_color_hover: #fff;

// Home User
$home_user_greeting_font_size_base: 22px;
$home_user_greeting_font_size_576: 26px;
$home_user_greeting_font_size_720: 31px;
$home_user_greeting_font_size_890: 42px;
$home_user_greeting_font_size_1024: 54px;
$home_user_greeting_font_size_1200: 64px;
$home_user_greeting_font_size_1350: 80px;
$home_user_greeting_font_weight: 700;
$home_user_greeting_text_transform: uppercase;

$home_user_motivation_font_size_base: 19px;
$home_user_motivation_font_size_720: 20px;
$home_user_motivation_font_size_1200: 21px;
$home_user_motivation_font_size_1350: 22px;

// Workouts finished
$workouts_finished_bg: #ffffff;
$workouts_finished_color: #000000;
$workouts_finished_border_radius: 0;
$workouts_finished_border: 0;

$workouts_finished_desc_color: #ABABAB;
$workouts_finished_desc_font_size: 12px;
$workouts_finished_desc_line_height: 14px;
$workouts_finished_desc_margin_bottom: 2px;

$workouts_finished_title_font_size_base: 18px;
$workouts_finished_title_font_size_720: 20px;
$workouts_finished_title_margin_bottom: 4px;
$workouts_finished_title_font_weight: 500;
$workouts_finished_subtitle_font_size_base: 13px;

// Home Recomended
$home_workouts_recomended_title_color: #ffffff;

// Circle Progress
$circle_progress_nums_font_size: 20px;
$circle_progress_nums_font_weight: 700;

// Titles
$title_text_transform: uppercase;
$title_font_weight: 700;
$title_color: #000000;
$title_font_size_base: 30px;
$title_font_size_480: 32px;
$title_font_size_700: 35px;
$title_font_size_768: 41px;
$title_font_size_890: 47px;
$title_font_size_1024: 56px;
$title_font_size_1200: 62px;
$title_font_size_1340: 70px;
$title_font_size_1440: 80px;

$title_medium_color: #000000;
$title_medium_font_weight: 500;
$title_medium_text_transform: uppercase;
$title_medium_font_size_base: 22px;
$title_medium_font_size_480: 25px;
$title_medium_font_size_576: 28px;
$title_medium_font_size_768: 30px;
$title_medium_font_size_1024: 32px;

// Meal Card
$meal_card_border_radius: 0;
$meal_card_name_font_size: 22px;
$meal_card_name_font_weight: 500;
$meal_card_tag_bg: #7DF3B3;
$meal_card_name_text_transform: uppercase;
$meal_card_name_bg: #fff;
$meal_card_btn_ico_border_radius: 0;

// Home Meal Card
$home_meal_card_tag_padding: 6px 8px;

// Tags
$tag_font_weight: 500;
$tag_font_size: 12px;
$tag_font_color: #000000;
$tag_font_padding: 10px 12px;
$tag_font_transform: capitalize;
$tag_border_radius: 0;

// Carousel Swiper Nav
$carousel_swiper_nav_next_btn_border_radius: 0;
$carousel_swiper_nav_prev_btn_border_radius: 0;

// Workout Card
$workout_card_title_color: #ffffff;
$workout_card_font_size_base: 28px;
$workout_card_font_size_576_639: 22px;
$workout_card_text_transform: uppercase;
$workout_card_font_weight: 700;
$workout_card_border_raius: 0;
$workout_card_btn_circle_display: flex;
$workout_card_info_width: calc(100% - 53px - 12px);

$workout_card_small_title_font_size_890: 18px;
$workout_card_small_title_font_size_1024: 20px;
$workout_card_small_title_font_size_1200: 22px;

// Category Tag
$category_tag_font_size: 12px;
$category_tag_color: #000000;
$category_tag_font_weight: 500;
$category_tag_padding: 8.5px 10px;
$category_tag_border_radius: 0;

// Params
$param_font_size: 12px;

// Category Tab
$category_tab_color: #ABABAB;
$category_tab_font_size: 18px;
$category_tab_font_weight: 700;
$category_tab_text_transform: uppercase;

// Buttons
$btn_font_size_base: 14px;
$btn_font_size_480: 15px;
$btn_font_size_768: 16px;
$btn_font_weight: 700;
$btn_border_radius: 0;
$btn_padding_top: 2px;

// One meal content
$meal_one_preparation_font_weight: 700;
$meal_one_preparation_font_size: 24px;
$meal_one_preparation_border_radius: 0;

// Meal Plan
$meal_plan_calendar_nav_btn_border_radius: 0;
$meal_plan_calendar_nav_btn_bg: #fff;
$meal_plan_calendar_nav_btn_border: 0;
$meal_plan_calendar_nav_btn_border_hover: transparent;

$meal_plan_calendar_day_bg: #fff;
$meal_plan_calendar_day_border: 1px solid #ffffff;
$meal_plan_calendar_day_active_bg: #000000;
$meal_plan_calendar_day_active_color: #ffffff;
$meal_plan_calendar_day_active_border_color: #000000;
$meal_plan_calendar_day_hover_border_color: #000000;

$meal_plan_calendar_day_border_radius: 0;
$meal_plan_calendar_day_name_font_size: 12px;
$meal_plan_calendar_day_name_line_height: 14px;

$meal_plan_calendar_day_num_font_size_base: 22px;
$meal_plan_calendar_day_num_font_size_420: 25px;
$meal_plan_calendar_day_num_font_size_500: 28px;
$meal_plan_calendar_day_num_font_size_530: 22px;
$meal_plan_calendar_day_num_font_size_640: 25px;
$meal_plan_calendar_day_num_font_size_768: 28px;
$meal_plan_calendar_day_num_font_wieght: 700;

// Confirm Modal
$small_modal_bg: #fff;
$small_modal_border_radius: 0;
$small_modal_title_color: #000000;
$small_modal_title_font_size_base: 24px;
$small_modal_title_font_size_480: 28px;
$small_modal_title_font_size_890: 32px;
$small_modal_title_font_weight: 700;
$small_modal_title_text_transform: uppercase;

$small_modal_desc_font_size_base: 12px;
$small_modal_desc_font_size_480: 13px;
$small_modal_desc_font_size_890: 14px;
$small_modal_desc_font_weight: 400;

// Select
$select_value_text_transform: uppercase;
$select_value_font_weight: 500;
$select_value_font_size_base: 14px;
$select_value_font_size_480: 15px;
$select_value_font_size_768: 16px;
$select_value_letter_spacing: 0;

// Meal Preview
$meal_preview_back_text_transform: uppercase;
$meal_preview_back_font_size_base: 32px;
$meal_preview_back_font_size_576: 24px;
$meal_preview_back_font_size_768: 27px;
$meal_preview_back_font_size_890: 30px;
$meal_preview_back_font_size_1024: 32px;
$meal_preview_back_font_weight: 700;

// Input Auth
$input_auth_font_size: 16px;
$input_auth_font_weight: 400;
$input_auth_border: 0;
$input_auth_color: #000000;
$input_auth_border_radius: 0;
$input_auth_bg: #ffffff;
$input_auth_error_border_radius: 0;
$input_auth_error_font_size_base: 14px;
$input_auth_error_font_size_576: 15px;
$input_auth_error_font_size_890: 16px;
$input_auth_error_font_weight: 400;
$input_auth_margin_top: 1px;

// Auth
$login_title_font_size_base: 30px;
$login_title_font_size_576: 32px;
$login_title_font_size_768: 40px;
$login_title_font_size_890: 48px;
$login_title_font_size_1024: 54px;
$login_title_font_size_1200: 60px;
$login_title_font_weight: 700;
$login_title_text_transform: uppercase;

$login_subtitle_font_weight: 400;
$login_subtitle_font_size_base: 14px;
$login_subtitle_font_size_576: 15px;
$login_subtitle_font_size_890: 16px;

$login_link_font_size: 14px;
$login_link_font_weight: 400;

// Profile
$user_parameter_title_font_size: 12px;
$user_parameter_title_font_weight: 400;

$user_parameter_value_font_size_base: 18px;
$user_parameter_value_font_size_768: 17px;
$user_parameter_value_font_size_890: 18px;
$user_parameter_value_font_weight: 700;
$user_parameter_value_text_transform: uppercase;

$weight_goal_link_bg: #000000;
$weight_goal_link_color: #fff;
$weight_goal_link_text_transform: uppercase;
$weight_goal_link_font_size: 14px;
$weight_goal_link_font_weight: 700;
$weight_goal_link_border_radius: 0;

$weight_goal_bg: #7DF3B3;
$weight_goal_border_radius: 0;
$weight_goal_border: 0;
$weight_goal_border_color_active: transparent;

$weight_goal_title_font_size_base: 16px;
$weight_goal_title_font_size_480: 32px;
$weight_goal_title_font_size_768: 13px;
$weight_goal_title_font_weight: 400;
$weight_goal_title_line_height_base: 21px;
$weight_goal_title_line_height_max: 1;
$weight_goal_title_color: #000000;

$weight_goal_input_font_size: 60px;
$weight_goal_input_font_weight: 500;
$weight_goal_input_color: #000000;

$weight_goal_svg_fill_active: #000000;
$weight_goal_btn_border_radius: 0;

$circle_btn_border_radius: 0;
$circle_btn_bg: #fff;
$circle_btn_svg_fill: #000000;

$weight_goal_help_error_font_size: 12px;
$weight_goal_help_error_border_radius: 0;
$weight_goal_help_error_font_weight: 400;

$cancel_sub_link_font_size: 13px;
$cancel_sub_link_font_weight: 400;
$edit_button_border_rudius: 0;

// Inputs
$input_wrap_bg: #ffffff;
$input_wrap_border_radius: 0;
$input_wrap_border: 0;
$input_wrap_padding: 14px 23px 11px;

$input_label_margin_bottom: 7px;
$input_label_font_size: 12px;
$input_label_font_weight: 400;
$input_label_color: rgba(#000000, 0.6);

$input_text_transform: uppercase;
$input_font_size: 16px;
$input_font_weight: 700;

// Radio
$radio_label_font_size: 16px;
$radio_label_font_weight: 700;
$radio_label_text_transform: uppercase;
$radio_label_padding_top: 2px;

// Category preview
$category_preview_bg: #7DF3B3;
$category_preview_color: #000000;
$category_preview_border_radius: 0;

$category_preview_svg_fill: #000000;

$category_preview_name_font_size_base: 16px;
$category_preview_name_font_size_400: 18px;
$category_preview_name_font_size_480: 20px;
$category_preview_name_font_size_576: 22px;
$category_preview_name_font_size_640: 16px;
$category_preview_name_font_size_700: 18px;
$category_preview_name_font_size_768: 20px;
$category_preview_name_font_size_890: 21px;
$category_preview_name_font_size_1024: 22px;
$category_preview_name_font_weight: 700;
$category_preview_text_transform: uppercase;

// History
$history_title_margin_bottom: 15px;

$history_counter_bg: #fff;
$history_counter_border_radius: 0;
$history_counter_border: 0;

$history_counter_title_font_size: 12px;
$history_counter_title_line_height: 14px;
$history_counter_title_font_weight: 400;

$history_counter_value_text_transform: uppercase;
$history_counter_value_font_size_base: 22px;
$history_counter_value_font_size_1024: 25px;
$history_counter_value_font_size_1200: 28px;
$history_counter_value_font_weight: 700;
$history_counter_value_letter_spacing_base: -1px;
$history_counter_value_letter_spacing_1200: -2px;

$history_item_bg: #ffffff;
$history_item_border_radius: 0;
$history_item_border: 0;
$history_item_color: #000000;
$history_item_padding_base: 15px 0 15px 25px;
$history_item_padding_base_edit: 15px 0 15px 19px;
$history_item_padding_1350: 20px 0 20px 30px;
$history_item_padding_1350_edit: 20px 0 20px 20px;

$history_item_date_font_size: 12px;
$history_item_date_font_weight: 400;

$history_item_name_font_size_base: 18px;
$history_item_name_font_size_1350: 21px;
$history_item_name_line_height: 29px;
$history_item_name_font_weight: 700;
$history_item_name_text_transform: uppercase;

$history_item_level_border_radius: 0;
$history_item_level_font_size: 12px;
$history_item_level_font_weight: 500;
$history_item_level_line_height: 15px;

// Log Counter
$log_counter_btn_font_size: 16px;
$log_counter_btn_font_weight: 700;
$log_counter_btn_border_radius: 0;

$log_counter_error_font_size: 12px;
$log_counter_error_border_radius: 0;

$log_counter_field_font_size: 30px;
$log_counter_font_weight: 700;
$log_counter_field_font_size_640: 29px;
$log_counter_field_font_size_768: ls(768, 926, 29, 40);
$log_counter_field_font_size_926: 40px;

$log_counter_input_border: 0;
$log_counter_input_border_radius: 0;
$log_counter_input_bg: #fff;

// Log Weight
$log_weight_chart_btn_bg: #262626;
$log_weight_chart_btn_border_radius: 0;
$log_weight_chart_btn_color: #fff;

$log_weight_chart_border_radius: 0;

$log_weight_item_bg: #fff;
$log_weight_item_border: 0;
$log_weight_item_border_radius: 0;
$log_weight_item_date_font_size: 12px;
$log_weight_item_date_font_weight: 400;
$log_weight_item_value_font_size: 24px;
$log_weight_item_value_font_weight: 700;
$log_weight_item_value_text_transform: uppercase;

// Workouts and Lifestyle
$last_workout_preview_bg: #ffffff;
$last_workout_preview_border_radius: 0;
$last_workout_preview_border: 0;

$last_workout_preview_title_font_size: 12px;
$last_workout_preview_title_line_height: 14px;
$last_workout_preview_title_font_weight: 400;

$last_workout_preview_name_font_weight: 500;
$last_workout_preview_name_font_size_base: 15px;
$last_workout_preview_name_font_size_360: 17px;
$last_workout_preview_name_font_size_375: 18px;
$last_workout_preview_name_font_size_480: 20px;
$last_workout_preview_name_font_size_576: 17px;
$last_workout_preview_name_font_size_640: 18px;
$last_workout_preview_name_font_size_720: 20px;

$last_workout_calories_bg: #ffffff;
$last_workout_calories_border_radius: 0;
$last_workout_calories_border: 0;

$last_workout_calories_title_font_size_base: 11px;
$last_workout_calories_title_font_size_480: 12px;

$last_workout_calories_value_font_size_base: 18px;
$last_workout_calories_value_font_size_576: 22px;
$last_workout_calories_value_font_size_640: 28px;
$last_workout_calories_value_font_weight: 700;

// One category
$category_detail_title_text_transform: uppercase;

// Workout Plan
$workout_author_name_font_size: 12px;
$workout_author_name_font_weight: 500;
$workout_author_name_border_radius: 0;
$workout_author_name_border: 0;

$exercise_card_border_radius: 0;
$exercise_card_border: 0;

$exercise_card_name_font_weight: 700;
$exercise_card_name_text_transform: uppercase;
$exercise_card_name_font_size_base: 22px;
$exercise_card_name_font_size_480: 23px;
$exercise_card_name_font_size_576: 24px;
$exercise_card_name_font_size_768: 25px;
$exercise_card_name_font_size_890: 26px;
$exercise_card_name_font_size_1024: 26px;

$exercise_card_desc_font_weight: 400;
$exercise_card_desc_font_size_base: 12px;
$exercise_card_desc_font_size_480: 13px;
$exercise_card_desc_font_size_768: 14px;

$exercise_video_btn_text_transform: uppercase;
$exercise_video_btn_font_size_base: 16px;
$exercise_video_btn_font_size_480: 18px;
$exercise_video_btn_font_size_576: 20px;
$exercise_video_btn_font_size_640: 22px;
$exercise_video_btn_font_size_720: 16px;
$exercise_video_btn_font_size_768: 18px;
$exercise_video_btn_font_size_890: 20px;
$exercise_video_btn_font_size_1024: 22px;
$exercise_video_btn_line_height_base: 19px;
$exercise_video_btn_line_height_480: 22px;
$exercise_video_btn_line_height_576: 24px;
$exercise_video_btn_line_height_640: 26px;
$exercise_video_btn_line_height_720: 19px;
$exercise_video_btn_line_height_768: 21px;
$exercise_video_btn_line_height_890: 24px;
$exercise_video_btn_line_height_1024: 26px;
$exercise_video_btn_font_weight: 700;

$exercise_video_body_bg: #FCFCFC;
$exercise_video_body_border_radius: 0;

// Single Video
$similar_video_title_text_transform: uppercase;
$similar_video_title_font_weight: 700;
$similar_video_title_font_size_base: 22px;
$similar_video_title_font_size_480: 24px;
$similar_video_title_font_size_576: 26px;
$similar_video_title_font_size_768: 28px;
$similar_video_title_font_size_890: 30px;
$similar_video_title_font_size_1024: 32px;

$similar_video_border_radius: 0;
$similar_video_name_font_size: 22px;
$similar_video_name_font_weight: 700;
$similar_video_name_text_transform: uppercase;

$single_video_border_radius: 0;

$single_video_title_font_weight: 700;
$single_video_title_text_transform: uppercase;
$single_video_title_font_size_base: 26px;
$single_video_title_font_size_640: 36px;
$single_video_title_line_height_base: 36px;
$single_video_title_line_height_640: 46px;

$single_video_desc_body_font_weight: 400;
$single_video_desc_body_font_size_base: 15px;
$single_video_desc_body_font_size_890: 16px;

// Filter Tabs
$filter_tabs_text_transform: uppercase;
$filter_tabs_font_weight: 700;
$filter_tabs_text_font_size_base: 30px;
$filter_tabs_text_font_size_480: 34px;
$filter_tabs_text_font_size_576: 38px;
$filter_tabs_text_font_size_768: 42px;
$filter_tabs_text_font_size_890: 48px;
$filter_tabs_text_font_size_1200: 60px;

// Workout Timer
$wt_start_screen_title_text_transform: uppercase;
$wt_start_screen_font_weight: 700;

$wt_time_value_stroke: #000000;
$wt_time_value_meter: #ffffff;

$wt_timer_nums_font_size_base: 120px;
$wt_timer_nums_font_size_576: 130px;
$wt_timer_nums_font_size_890: 140px;
$wt_timer_nums_font_size_1200: 150px;
$wt_timer_nums_font_weight: 700;
$wt_timer_nums_is_finish_font_size_base: 170px;
$wt_timer_nums_is_finish_font_size_576: 175px;
$wt_timer_nums_is_finish_font_size_890: 190px;

$wt_paus_btn_border_radius: 0;
$wt_pause_border_radius: 0;

$wt_pause_title_text_transform: uppercase;
$wt_pause_title_font_weight: 700;

$wt_paus_btn_text_text_transform: uppercase;
$wt_paus_btn_text_color: #000000;
$wt_paus_btn_text_font_size: 16px;
$wt_paus_btn_text_line_height: 19px;
$wt_paus_btn_text_font_weight: 700;

$wt_header_title_font_size_base: 30px;
$wt_header_title_font_size_1200: 24px;
$wt_header_title_font_size_1276: 30px;
$wt_header_title_font_weight: 700;
$wt_header_title_text_transform: uppercase;

$wt_workout_play_info_title_text_transform: uppercase;
$wt_workout_play_info_title_font_size: 18px;
$wt_workout_play_info_title_font_weight: 700;

$wt_workout_play_nav_btn_font_size: 18px;
$wt_workout_play_nav_btn_font_weight: 700;
$wt_workout_play_nav_btn_text_transform: uppercase;
$wt_workout_play_nav_btn_padding_top: 2px;

$wt_get_ready_fz: 23; //px
$wt_get_ready_fw: 700;
$wt_rest_title_text_transform: uppercase;

// author
$author_border_radius: 0;

// error
$error_page_bg: #f7f7f7;

$p_history_nav_edit_bgc: #fff;
$p_history_nav_edit_bgc_hover: rgba(#fff, 0.7);
$p_history_nav_edit_brs: 0;
$p_history_nav_delete_brs: 0;

$t_sign_up_title_text_transform: uppercase;
