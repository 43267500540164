.backdrop-transition-enter {
  opacity: 0; }

.backdrop-transition-enter-active {
  opacity: 1;
  transition: all .15s ease-in-out; }

.backdrop-transition-exit {
  opacity: 1; }

.backdrop-transition-exit-active {
  opacity: 0;
  transition: all .15s ease-in-out; }
