@import "../../../assets/sass/utils/utils";

.meals {
  padding-top: 50px;
  padding-bottom: 80px;

  .title-medium {
    margin-bottom: 60px; }

  .btn-wrap {
    padding-top: 25px; } }

.meals-list {
  margin-bottom: -25px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    margin-bottom: -25px; }

  @media (min-width: 1200px) {
    margin-bottom: -30px; }

  .meal-card {
    width: 100%;
    margin-bottom: 25px;

    @media (min-width: 576px) {
      width: calc((100% - 20px) / 2); }

    @media (min-width: 890px) {
      width: calc((100% - 40px) / 3); }

    @media (min-width: 1200px) {
      width: calc((100% - 60px) / 3);
      margin-bottom: 30px; }

    @media (min-width: 1350px) {
      width: calc((100% - 100px) / 3);
      margin-bottom: 50px; }

    &:not(:nth-child(2n)) {
      @media (min-width: 576px) and (max-width: 889px) {
        margin-right: 20px; } }

    &:not(:nth-child(3n)) {
      @media (min-width: 890px) {
        margin-right: 20px; }

      @media (min-width: 1200px) {
        margin-right: 30px; }

      @media (min-width: 1350px) {
        margin-right: 50px; } } }

  &--small {
    .meal-card {

      @media (min-width: 1200px) {
        width: calc((100% - 120px) / 4);
        margin-bottom: 40px; }

      &:not(:nth-child(3n)) {
        @media (min-width: 1200px) {
          margin-right: 0; } }

      &:not(:nth-child(4n)) {
        @media (min-width: 1200px) {
          margin-right: 40px; } }

      &:last-child {
        margin-right: auto; } } } }

.meal-card {
  $t: &;

  position: relative;
  @include size(100%, 330px);
  background-color: $meal_card_name_bg;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: #ffffff;
  cursor: pointer;
  border-radius: $meal_card_border_radius;

  &:hover {
    color: #ffffff;

    &::before {
      @media (min-width: 1024px) {
        background-color: rgba(#3A3846, 0.4); } } }

  @media (min-width: 576px) {
    height: 280px; }

  @media (min-width: 720px) {
    height: 310px; }

  @media (min-width: 768px) {
    height: 330px; }

  @media (min-width: 890px) {
    height: 280px; }

  @media (min-width: 1024px) {
    height: 330px; }

  @media (min-width: 1200px) {
    height: 348px; }

  @media (min-width: 1340px) {
    height: 363px; }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    transition: all .6s; }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;

    .icon-lock-outlined {
      fill: none;
      stroke: #fff;
      @include size(80px); } }

  &__pic {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    svg {
      @include size(30px); } }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 25px 25px 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @media (min-width: 1024px) {
      padding: 30px 30px 40px; } }

  &__body {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    transition: all .5s;

    &:hover {
      color: #fff; } }

  &__name {
    margin-bottom: 13px;
    font-size: $meal_card_name_font_size;
    line-height: 1;
    font-weight: $meal_card_name_font_weight;
    text-align: center;
    color: #fff;
    text-transform: $meal_card_name_text_transform;

    &:hover {
      color: #ffffff; } }

  &__like {
    position: absolute;
    top: 5px;
    right: 5px;
    @include size(72px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @media (min-width: 1200px) {
      top: 0;
      right: 0; }

    &-inner {
      @include size(32px);
      overflow: hidden;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s;
      z-index: 5;
      border-radius: $meal_card_btn_ico_border_radius;

      &.is-favorite {
        background-color: #7DF3B3;

        svg.icon-like-full {
          opacity: 1; }

        svg.icon-like {
          opacity: 0; } } }

    svg {
      fill: #000000;
      z-index: 2;
      @include size(17px, 15px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .2s;

      &.icon-like-full {
        opacity: 0; } } }

  &__replace {
    position: absolute;
    top: 25px;
    right: 25px;
    @include size(32px);
    overflow: hidden;
    z-index: 2;
    border-radius: $meal_card_btn_ico_border_radius;

    @media (min-width: 1024px) {
      top: 30px;
      right: 30px; }

    svg {
      fill: #F09A7F;
      z-index: 2;
      @include size(100%);
      position: relative; }

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
      background-color: #fff;
      z-index: 1;
      display: block; } }

  &__btn {
    width: 184px;
    margin-top: 48px;
    transition: all .5s;
    transition-delay: .5s;
    opacity: 0; }

  .param-list {
    justify-content: center;
    margin-bottom: -7.5px;

    .param {
      margin: 0 7.5px 7.5px; } }

  .tag {
    background-color: $meal_card_tag_bg; }

  &--small {
    height: 270px;
    max-width: 325px;
    margin: 0 auto;

    @media (min-width: 320px) {
      height: ls(320, 374, 270, 315); }

    @media (min-width: 375px) {
      height: 315px; }

    @media (min-width: 576px) {
      height: ls(576, 735, 240, 315);
      margin-right: 0;
      margin-left: 0;
      max-width: 100%; }

    @media (min-width: 735px) {
      height: 315px; }

    @media (min-width: 890px) {
      height: ls(890, 1023, 240, 260); }

    @media (min-width: 1024px) {
      height: ls(1024, 1199, 260, 310); }

    @media (min-width: 1200px) {
      height: ls(1200, 1349, 230, 270); }

    @media (min-width: 1350px) {
      height: 270px; }

    #{$t}__inner {
      padding: 20px 15px 30px; }

    .param-list {
      margin-bottom: -5px;

      .param {
        margin: 0 5px 5px; } }

    .tag-list {
      margin-left: 10px;
      margin-top: 5px;

      @media (min-width: 1200px) {
        margin-top: 0;
        margin-left: 5px; } }

    #{$t}__name {
      font-size: $meal_card_name_font_size; } } }
