@import "../../../../../assets/sass/utils/utils";

.plan-cart-title,
.plan-cart-error-title, {
  font-size: 24px;
  line-height: 137.5%;
  font-weight: 700;
  margin-bottom: 40px;

  @media (min-width: 480px) {
    font-size: 26px; }

  @media (min-width: 576px) {
    font-size: 30px; }

  @media (min-width: 768px) {
    font-size: 34px; }

  @media (min-width: 1200px) {
    font-size: 38px; } }

.plan-cart-error-title {
  text-align: center;
  margin-bottom: 20px; }

.plan-cart-body {
  height: 100%; }

.plan-cart-body-item + .plan-cart-body-item {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #F1F1F1;

  @media (min-width: 480px) {
    margin-top: 25px;
    padding-top: 25px; }

  @media (min-width: 768px) {
    margin-top: 30px;
    padding-top: 30px; } }

.plan-cart-body-success {
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column; }

  &__title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 24px;
    font-weight: 700;
    text-align: center;

    @media (min-width: 480px) {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 18px; }

    @media (min-width: 1200px) {
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 6px; } }

  &__desc {
    font-size: 16px;
    line-height: 22px;
    text-align: center; }

  &__select {
    width: 100%;
    max-width: 325px;
    margin-top: 50px;

    @media (min-width: 480px) {
      max-width: 280px; } } }

.plan-cart-body-icon {
  margin-bottom: -2px;

  @media (min-width: 480px) {
    margin-bottom: 3px; }

  @media (min-width: 576px) {
    margin-bottom: 13px; }

  @media (min-width: 1200px) {
    margin-bottom: 23px; } }

.plan-cart-desc {
  font-size: 16px;
  line-height: 22px;
  text-align: center; }

.plan-cart-body-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.plan-cart-error-ico {
  @include size(80px);
  border-radius: 50%;
  border: 4px solid #ff0000;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;

  span {
    width: 54px;
    height: 4px;
    border-radius: 2px;
    background-color: #ff0000;
    display: block;
    transform-origin: 50% 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:first-child {
      transform: translate(-50%, -50%) rotate(45deg); }

    &:last-child {
      transform: translate(-50%, -50%) rotate(-45deg); } } }
