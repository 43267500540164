.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.item + .item {
  margin-top: 20px;

  @media (min-width: 576px) {
    margin-top: 0; } }

.item {
  width: 100%;

  @media (min-width: 576px) {
    width: calc((100% - 20px) / 2); }

  @media (min-width: 768px) {
    width: calc((100% - 25px) / 2); }

  @media (min-width: 1024px) {
    width: calc((100% - 32px) / 2); }

  @media (min-width: 1200px) {
    width: calc((100% - 40px) / 2); } }
