@import "../../../../../assets/sass/utils/utils";

.workout-play-carousel-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column; }

.workout-play-carousel {
  margin: 0 auto;
  width: 100%;
  max-width: 1156px;

  @media (min-width: 768px) {
    padding-top: calc(10vh + 40px); }

  @media (min-width: 1200px) {
    padding-top: 10vh; }

  .swiper-container {
    overflow: visible;

    .swiper-slide {
      transform: scale(0.85);
      opacity: 0.5;
      transition: all .2s;

      &.swiper-slide-active {
        opacity: 1;
        transform: scale(1); } } } }

.workout-play-carousel-inner {
  width: 100%;

  @media (min-width: 768px) {
    position: relative;
    padding-bottom: 70px; } }

.workout-play-stop {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 312;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.workout-play-stop-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#fff, 0.85);
  z-index: 1; }

.workout-play-title {
  text-transform: $wt_pause_title_text_transform;
  font-weight: $wt_pause_title_font_weight;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;
  z-index: 2;

  @media (min-width: 480px) {
    font-size: 36px;
    line-height: 44px; }

  @media (min-width: 576px) {
    font-size: 42px;
    line-height: 51px; }

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 58px; }

  @media (min-width: 890px) {
    font-size: 52px;
    line-height: 60px; }

  @media (min-width: 1024px) {
    font-size: 56px;
    line-height: 64px; }

  @media (min-width: 1200px) {
    font-size: 60px;
    line-height: 72px; } }

.workout-play-subtitle {
  font-family: $second-font;
  font-size: 14px;
  z-index: 2;

  @media (min-width: 480px) {
    font-size: 15px; }

  @media (min-width: 768px) {
    font-size: 16px; } }

.workout-info-mobile-wrap {
  @media (min-width: 768px) {
    display: none; } }

.workout-info-mobile {
  $t: &;

  padding-top: 25px;

  &__title {
    font-size: 12px;
    line-height: 156.49%;
    color: rgba(#000000, 0.6);
    font-family: $second-font; }

  &__head {
    #{$t}__info + #{$t}__info {
      margin-top: 30px; } }

  &__value {
    font-size: 16px;
    line-height: 156.49%;
    text-transform: uppercase;
    margin-top: 5px;

    &.big {
      font-size: 30px;
      line-height: 110.49%; } }

  &__add {
    background-color: #fff;
    position: fixed;
    bottom: 60px;
    right: 0;
    left: 0;
    padding: 15px 0;

    .container {
      display: flex;
      align-items: flex-start; }

    #{$t}__info {
      margin-right: auto;

      &.right {
        margin-right: 0;
        text-align: right;
        margin-left: 14px; } } } }
