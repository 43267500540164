@import "../../../assets/sass/utils/utils";

.user-avatar {
  position: relative;
  @include size(160px);

  @media (min-width: 768px) {
    @include size(130px); }

  @media (min-width: 890px) {
    @include size(160px); }

  &__pic {
    border-radius: 50%;
    object-fit: cover;
    @include size(100%); }

  &__empty {
    @include size(100%);
    border-radius: 50%;
    overflow: hidden;
    background-color: #7DF3B3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 80px;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 60px; }

    @media (min-width: 890px) {
      font-size: 80px; } }

  &__edit {
    position: absolute;
    bottom: 0;
    right: 14px;
    cursor: pointer;
    display: block;
    border-radius: $edit_button_border_rudius; } }
