@import "../../../assets/sass/utils/utils";

.select-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &--white {
    .select {
      select {
        color: #ffffff;

        option {
          background-color: #ffffff;
          color: #000000; } } }

    .select-label {
      color: #fff; }

    .angle-bottom {
      fill: #ffffff; } } }

.select {
  position: relative;

  .angle-bottom {
    @include size(10px, 5px);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

  select {
    -webkit-appearance: none;
    height: 28px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-weight: $select_value_font_weight;
    font-size: $select_value_font_size_base;
    color: #111111;
    width: 100%;
    text-transform: $select_value_text_transform;
    letter-spacing: $select_value_letter_spacing;

    @media (min-width: 480px) {
      font-size: $select_value_font_size_480; }

    @media (min-width: 768px) {
      font-size: $select_value_font_size_768; }

    option {
      text-transform: capitalize; } } }

.select-label {
  margin-bottom: 2px;
  line-height: 1;
  color: #111111;
  font-family: $second-font;
  font-size: 11px;

  @media (min-width: 576px) {
    font-size: 12px; } }
