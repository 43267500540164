.wrap {
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  font-size: 15px;
  height: 100%;

  @media (min-width: 1024px) {
    font-size: 16px; }

  &:hover {
    .content {
      border-color: #54c5b0; } }

  &.big {
    font-size: 20px;

    @media (min-width: 480px) {
      font-size: 22px; }

    @media (min-width: 768px) {
      font-size: 23px; }

    @media (min-width: 1024px) {
      font-size: 25px; }

    @media (min-width: 1200px) {
      font-size: 26px; } } }

.input {
  display: none;

  &:checked {
    ~ .content {
        border-color: #54c5b0; }

    ~ .flag {
      opacity: 1;

      .flagIcon {
        transform: translateY(0) scale(1); } } } }

.content {
  border-radius: 0.5em;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  width: 100%;
  transition: all .2s ease-in-out;
  padding: 1px;
  height: 100%; }

.inner {
  width: 100%;
  height: 100%;
  padding: 0.75em 1em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; }

.flag {
  font-size: 3em;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0 0.2em 0 0;
  border-top: 0.5em solid #54C5B0;
  border-bottom: 0.5em solid transparent;
  border-right: 0.5em solid #54C5B0;
  border-left: 0.5em solid transparent;
  opacity: 0;
  transition: all .2s ease-in-out; }

.flagIcon {
  position: absolute;
  stroke: #fff;
  width: 0.333333333em;
  height: 0.244444444em;
  right: -0.377777778em;
  top: -0.288888889em;
  transition: all .3s ease-in-out;
  transform: translateY(-8px) scale(0.8); }
