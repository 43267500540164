@import "../../../assets/sass/utils/utils";

.workouts-page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 0 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding-bottom: 40px; }

  @media (min-width: 890px) {
    padding-bottom: 50px; }

  @media (min-width: 1350px) {
    padding: 0 0 60px; }

  &__title + &__title {
    margin-left: 15px; }

  &__title {
    color: #ABABAB;
    transition: all .2s ease-in-out;
    cursor: pointer;
    font-size: 36px;
    position: relative;

    @media (min-width: 890px) {
      font-size: 52px; }

    @media (min-width: 1024px) {
      font-size: 62px; }

    &.active {
      color: #000; }

    span {
      position: absolute;
      font-size: 14px;
      left: calc(100% + 6px);
      top: 0;

      @media (min-width: 890px) {
        font-size: 17px; } }

    .content-page &.title {
      margin-bottom: 0;

      @media (max-width: 575px) {
        text-align: left;
        padding-left: 0; } } }

  &__last-workout {
    width: 100%;

    @media (min-width: 768px) {
      width: 59%;
      max-width: 374px;
      max-width: 495px; }

    @media (min-width: 890px) {
      width: 48%; } } }

.workout-plans-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  @media (min-width: 576px) {
    margin-top: 30px; }

  @media (min-width: 768px) {
    margin-bottom: -20px;
    margin-top: 40px; }

  @media (min-width: 1024px) {
    margin-bottom: -30px; }

  @media (min-width: 1200px) {
    margin-bottom: -40px; }

  @media (min-width: 1350px) {
    margin-bottom: -55px; }

  .workout-card {
    width: 100%;

    @media (min-width: 576px) {
      width: calc((100% - 20px) / 2); }

    @media (min-width: 890px) {
      width: calc((100% - 40px) / 3); }

    @media (min-width: 1024px) {
      width: calc((100% - 60px) / 3); }

    @media (min-width: 1200px) {
      width: calc((100% - 80px) / 3); }

    @media (min-width: 1350px) {
      width: calc((100% - 128px) / 3); }

    &:not(:nth-child(2n)) {
      @media (min-width: 576px) and (max-width: 889px) {
        margin-right: 20px; } }

    &:not(:nth-child(3n)) {
      @media (min-width: 890px) {
        margin-right: 20px; }

      @media (min-width: 1024px) {
        margin-right: 30px; }

      @media (min-width: 1200px) {
        margin-right: 40px; }

      @media (min-width: 1350px) {
        margin-right: 64px; } } } }

.workouts-page-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-medium {
    width: auto; } }

.workouts-page-history {
  position: relative;
  padding-bottom: 80px;
  padding-bottom: 40px;

  @media (min-width: 1024px) {
    padding-bottom: 50px; }

  @media (min-width: 1200px) {
    padding-bottom: 60px; }

  @media (min-width: 1350px) {
    padding-bottom: 80px; }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $content_under_carouseles;
    height: 286px;

    @media (min-width: 1024px) {
      height: 300px; }

    @media (min-width: 1200px) {
      height: 315px; }

    @media (min-width: 1350px) {
      height: 330px; } }

  + .workouts-page-categories {
    margin-top: 70px; } }

.workouts-page-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    width: 40%; } }
