.wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#2C2C2C, 0.8);
  z-index: 15; }

.inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (min-width: 576px) {
    padding: 7vh 0;
    overflow-y: auto; } }

.modal {
  background-color: #fff;
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
  padding: 45px 20px 30px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 0;
  transition: all .3s ease-in-out;

  @media (min-width: 576px) {
    max-width: calc(100% - 30px);
    border-radius: 10px;
    overflow-y: visible;
    height: auto;
    padding: 55px 40px 30px; }

  @media (min-width: 640px) {
    padding: 65px 50px 30px; } }

.close {
  position: absolute;
  font-size: 30px;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0.466666667em;
  top: 0.466666667em;

  @media (min-width: 576px) {
    font-size: 36px; }

  svg {
    width: 0.666666667em;
    height: 0.666666667em; } }

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 575px) {
    padding-top: 9vh; } }

.content {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  .list {
    padding: 30px 0 0 20px; } }

.title {
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-bottom: 2px;

  @media (min-width: 640px) {
    font-size: 32px; } }

.subtitle {
  text-align: center;
  margin-bottom: 30px;
  font-size: 17px;
  font-weight: 500;

  @media (min-width: 640px) {
    font-size: 17px;
    margin-bottom: 35px; } }

.desc,
.item {
  font-size: 14px;
  line-height: 156%;
  font-weight: 300; }

.desc + .list,
.desc + .desc,
.list + .desc,
.item + .item {
  margin-top: 10px;

  @media (min-width: 640px) {
    margin-top: 14px; } }

.list {
  padding-left: 30px;
  list-style: disc; }

.item {
  list-style: disc; }

.footer {
  margin-top: 40px;
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 70px auto 30px;
  flex-wrap: wrap;

  @media (min-width: 576px) {
    padding-bottom: 0; } }

.btn {
  background-color: #86DBCC;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  height: 60px;
  width: 100%;
  font-size: 11px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: 375px) {
    font-size: 12px;
    width: calc((100% - 10px) / 2); }

  @media (min-width: 480px) {
    font-size: 13px;
    width: calc((100% - 15px) / 2); }

  @media (min-width: 576px) {
    font-size: 14px;
    width: calc((100% - 20px) / 2); }

  @media (min-width: 640px) {
    height: 72px;
    font-size: 15px;
    width: calc((100% - 25px) / 2); }

  &:hover {
    opacity: 0.9; }

  + .btn {
    @media (max-width: 374px) {
      margin-top: 10px; } } }
