@import "../../../../../assets/sass/utils/utils";

.subs-modal-footer {

  &__desc {
    font-family: $second-font;
    line-height: 150.3%;
    color: #ABABAB;
    font-size: 11px;
    margin-top: 28px;
    max-width: 340px;

    @media (min-width: 480px) {
      font-size: 12px;
      margin-top: 35px; }

    @media (min-width: 576px) {
      margin-top: 42px; }

    @media (min-width: 1200px) {
      margin-top: 60px; } }

  &__add-info {
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1;
    text-align: center;

    @media (min-width: 480px) {
      font-size: 15px; }

    @media (min-width: 576px) {
      font-size: 16px;
      margin-top: 22px; } }

  &__btn {
    text-align: center;
    height: 54px;
    background-color: #000;
    height: 54px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    width: 100%;
    transition: all .2s;

    @media (min-width: 480px) {
      height: 58px;
      font-size: 15px; }

    @media (min-width: 576px) {
      height: 62px;
      font-size: 16px; }

    &:hover {
      opacity: .8; }

    &.purchase {
      background-color: #7DF3B3;
      color: #000; } } }
