.item + .item {
  margin-top: 30px; }

.item {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; } }

.question,
.answers {
  width: 100%; }

.question {
  margin-bottom: 15px;

  @media (min-width: 640px) {
    width: 33%;
    margin-bottom: 0; }

  @media (min-width: 1200px) {
    width: 28%; } }

.answers {
  @media (min-width: 640px) {
    width: 65%; }

  @media (min-width: 1200px) {
    width: 70%; } }

.question {
  font-weight: 600;
  font-size: 20px; }

.answers {
  display: flex;
  justify-content: space-between; }

.answer {
  width: calc( (100% - 12px) / 2 );

  @media (min-width: 1200px) {
    width: calc( (100% - 20px) / 2 ); } }
