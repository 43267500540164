@font-face {
  font-family: 'DIN Condensed';
  src: url('../../../fonts/DINCondensed.otf');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../fonts/helvetica/HelveticaNeue.ttf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../fonts/helvetica/HelveticaNeue-Bold.eot');
  src: local('HelveticaNeue-Bold'), local('HelveticaNeue-Bold'), url('../../../fonts/helvetica/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('../../../fonts/helvetica/HelveticaNeue-Bold.woff') format('woff'), url('../../../fonts/helvetica/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../../fonts/helvetica/HelveticaNeue-Medium.eot');
  src: local('HelveticaNeue-Medium'), local('HelveticaNeue-Medium'), url('../../../fonts/helvetica/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), url('../../../fonts/helvetica/HelveticaNeue-Medium.woff') format('woff'), url('../../../fonts/helvetica/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal; }
