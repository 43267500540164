@import "../../../assets/sass/utils/utils";

.content-page.single-video-page {
  display: flex;
  flex-wrap: wrap;
  padding: 0; }

.single-video-widget-wrap {
  padding: 35px 0 0;

  @media (min-width: 890px) {
    padding: 60px 0 10px; }

  @media (min-width: 1024px) {
    padding: 70px 0 10px; }

  @media (min-width: 1200px) {
    padding: 80px 0 10px; } }

.similar-videos {
  width: 100%;

  @media (max-width: 889px) {
    padding-bottom: 50px; }

  @media (min-width: 890px) {
    width: ls(890, 1600, 264, 415);
    margin-top: 0;
    padding-right: ls(890, 1600, 10, 55);
    padding-left: ls(890, 1600, 10, 55);
    background-color: #EBEBEB;
    min-height: calc(100vh - 90px - 80px); }

  @media (min-width: 1024px) {
    min-height: calc(100vh - 98px - 90px); }

  @media (min-width: 1600px) {
    width: 415px;
    padding-right: 55px;
    padding-left: 55px; }

  .container {
    height: 100%;

    @media (min-width: 890px) {
      padding: 0;
      max-width: 100%;
      margin: 0; } } }

.similar-video-head {
  margin-bottom: 40px; }

.similar-video-title {
  line-height: 1;
  text-transform: $similar_video_title_text_transform;
  font-size: $similar_video_title_font_size_base;
  font-weight: $similar_video_title_font_weight;

  @media (min-width: 480px) {
    font-size: $similar_video_title_font_size_480; }

  @media (min-width: 576px) {
    font-size: $similar_video_title_font_size_576; }

  @media (min-width: 768px) {
    font-size: $similar_video_title_font_size_768; }

  @media (min-width: 890px) {
    font-size: $similar_video_title_font_size_890;
    line-height: 31px; }

  @media (min-width: 1024px) {
    font-size: $similar_video_title_font_size_1024;
    line-height: 32px; } }

.single-video-list {

  @media (min-width: 460px) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }

  @media (min-width: 890px) {
    display: block;
    overflow-y: auto;
    width: 100%;
    padding-right: ls(890, 1600, 10, 38);
    max-height: 556px;
    min-height: 259px;
    height: calc(100% - 31px - 40px); }

  @media (min-width: 1024px) {
    min-height: 300px; }

  @media (min-width: 1200px) {
    min-height: 360px; }

  @media (min-width: 1600px) {
    padding-right: 38px; }

  &::-webkit-scrollbar {
    width: 9px;
    background-color: #fff; }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #ffffff;
    cursor: pointer; } }

.similar-video-loader {
  position: relative;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 890px) {
    height: 170px; } }

.similar-video {
  width: 100%;
  height: 180px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 15px;
  display: block;
  border-radius: $similar_video_border_radius;
  overflow: hidden;

  @media (min-width: 460px) {
    width: calc((100% - 15px) / 2); }

  @media (min-width: 768px) {
    width: calc((100% - 30px) / 3); }

  @media (min-width: 890px) {
    width: 100%;
    height: 170px; }

  @media (min-width: 1200px) {
    margin-bottom: 23px; }

  &:not(:nth-child(2n)) {
    @media (min-width: 460px) and (max-width: 767px) {
      margin-right: 15px; } }

  &:not(:nth-child(3n)) {
    @media (min-width: 768px) and (max-width: 889px) {
      margin-right: 15px; } }

  &:last-child {
    margin-bottom: 0; }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.07) 100%); }

  &__pic {
    position: absolute;
    @include size(100%);
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }

  &__inner {
    z-index: 3;
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__name {
    text-transform: $similar_video_name_text_transform;
    font-size: $similar_video_name_font_size;
    font-weight: $similar_video_name_font_weight;
    line-height: 26px;
    margin-bottom: 4px; } }

.single-video-wrap {
  width: 100%;

  @media (min-width: 890px) {
    width: calc(100% - (#{ls-c(890, 1600, 264, 415)}));
    padding-right: ls(890, 1600, 30, 110);
    padding-left: ls(890, 1600, 30, 170); }

  @media (min-width: 1600px) {
    padding-right: 110px;
    padding-left: 170px;
    width: calc(100% - 415px); } }

.single-video {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: $single_video_border_radius; }

.single-video-file {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.loading {
    opacity: 0; }

  iframe,
  object,
  embed {
    @include size(100%);
    position: absolute;
    top: 0;
    left: 0; } }

.single-video-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; }

.single-video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  @include size(73px);
  cursor: pointer;
  z-index: 3;

  @media (min-width: 375px) {
    @include size(ls(375, 890, 73, 105)); }

  @media (min-width: 890px) {
    @include size(ls(890, 1300, 82, 105)); }

  @media (min-width: 1300px) {
    @include size(105px); }

  .play {
    @include size(33px, 35px);
    fill: $color_main_focus;
    margin-left: -4px;

    @media (min-width: 375px) {
      @include size(ls(375, 890, 33, 40), ls(375, 890, 35, 42)); }

    @media (min-width: 890px) {
      @include size(ls(890, 1300, 35, 40), ls(890, 1300, 37, 42)); }

    @media (min-width: 1300px) {
      @include size(40px, 42px); } } }

.single-video-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  @include size(100%);
  object-fit: cover; }

.single-video-body {
  margin-top: 25px;

  @media (min-width: 890px) {
    margin-top: 35px;
    padding-bottom: 50px; }

  .container {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 890px) {
      padding: 0;
      max-width: 100%; } }

  &__main {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;

    @media (min-width: 640px) {
      flex-direction: row; } } }

.single-video-title {
  width: 100%;
  margin-bottom: 10px;
  font-weight: $similar_video_title_font_weight;
  font-size: $single_video_title_font_size_base;
  line-height: $single_video_title_font_size_640;
  text-transform: $single_video_title_text_transform;

  @media (min-width: 640px) {
    font-size: $single_video_title_line_height_base;
    line-height: $single_video_title_line_height_640;
    margin-bottom: 0; } }

.single-video-plan-info {
  display: flex;
  align-items: center;

  @media (min-width: 640px) {
    margin-left: auto;
    width: 100%;
    justify-content: flex-end;
    height: 46px; }

  .workout-author-name {
    margin: 0 10px 0 0; } }

.single-video-duration {
  margin-top: 15px;

  .param {
    @media (min-width: 640px) {
      font-size: 16px; }

    svg {
      fill: #000000;

      @media (min-width: 640px) {
        @include size(20px);
        margin-right: 9px; } } } }

.single-video-description {
  margin-top: 15px;
  width: 100%;

  @media (min-width: 640px) {
    margin-top: 20px; }

  @media (min-width: 890px) {
    margin-top: 25px; }

  @media (min-width: 1024px) {
    margin-top: 30px; }

  &__body {
    font-family: $second-font;
    font-size: $single_video_desc_body_font_size_base;
    line-height: 156.49%;
    font-weight: $single_video_desc_body_font_weight;

    @media (min-width: 890px) {
      font-size: $single_video_desc_body_font_size_890; } }

  &__btn {
    font-size: 16px;
    line-height: 156.49%;
    text-transform: uppercase;
    margin-top: 25px; } }
