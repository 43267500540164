@import "../../../../../assets/sass/utils/utils";

.workouts-list-carousel {
  position: relative;
  overflow: hidden;

  .workout-card {
    display: block;
    opacity: 0.4;
    transition: all .2s;
    background-color: transparent;
    margin-bottom: 0;
    width: 100%;

    @media (min-width: 576px) {
      height: 340px; }

    @media (min-width: 820px) {
      height: 348px; }

    @media (min-width: 1024px) {
      height: 355px; }

    @media (min-width: 1200px) {
      height: 363px; }

    &__btn.btn-circle {
      display: $workout_card_btn_circle_display; }

    &__info {
      text-align: left;
      width: $workout_card_info_width; }

    &__params {
      justify-content: flex-start; }

    &__tags {
      @media (min-width: 890px) {
        top: 25px;
        left: 25px; } }

    &__category {
      @media (min-width: 890px) {
        top: 20px;
        right: 25px; } } }

  .swiper-container {
    overflow: visible;
    width: 100%;

    .swiper-slide-visible {
      .workout-card {
        opacity: 1; } }

    .swiper-slide {
      max-width: 100%;
      width: 325px;

      @media (min-width: 450px) {
        width: 345px; }

      @media (min-width: 820px) {
        width: 355px; }

      @media (min-width: 1024px) {
        width: 365px; }

      @media (min-width: 1200px) {
        width: 374px; } } }

  &.small {
    .workout-card {
      height: 269px; }

    .swiper-container {
      .swiper-slide-visible {
        .workout-card {
          opacity: 1; } }

      .swiper-slide {
        max-width: 100%;
        width: 325px;

        @media (min-width: 576px) {
          width: 300px; }

        @media (min-width: 768px) {
          width: 290px; }

        @media (min-width: 890px) {
          width: 277px; }

        @media (min-width: 1360px) {
          width: calc((100% - 141px) / 4); } } } } }
