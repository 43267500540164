@import "../../../assets/sass/utils/utils";

.title {
  color: $title_color;
  font-weight: $title_font_weight;
  font-size: $title_font_size_base;
  line-height: 1;
  text-transform: $title_text_transform;

  @media (min-width: 480px) {
    font-size: $title_font_size_480; }

  @media (min-width: 700px) {
    font-size: $title_font_size_700; }

  @media (min-width: 768px) {
    font-size: $title_font_size_768; }

  @media (min-width: 890px) {
    font-size: $title_font_size_890; }

  @media (min-width: 1024px) {
    font-size: $title_font_size_1024; }

  @media (min-width: 1200px) {
    font-size: $title_font_size_1200; }

  @media (min-width: 1340px) {
    font-size: $title_font_size_1340; }

  @media (min-width: 1440px) {
    font-size: $title_font_size_1440; } }

.title-medium {
  color: $title_medium_color;
  font-size: $title_medium_font_size_base;
  line-height: 1;
  font-weight: $title_medium_font_weight;
  width: 100%;
  text-transform: $title_medium_text_transform;

  @media (min-width: 480px) {
    font-size: $title_medium_font_size_480; }

  @media (min-width: 576px) {
    font-size: $title_medium_font_size_576; }

  @media (min-width: 768px) {
    font-size: $title_medium_font_size_768; }

  @media (min-width: 1024px) {
    font-size: $title_medium_font_size_1024; } }
