@import "../../../../../assets/sass/utils/utils";

.workout-play-slide {
  width: 100%;
  background-color: #fff;
  position: relative;
  height: 60vw;

  @media (min-width: 1025px) {
    height: ls(480, 1600, 320, 652); }

  @media (min-width: 1600px) {
    height: 652px; }

  .workout-play-main-timer {
    transition: all .2s;
    opacity: 1; }

  &.is-not-ready {
    .workout-play-main-timer {
      opacity: 0; } }

  .workout-play-video {
    @include size(100%);
    opacity: 0;
    transition: all .2s;

    .swiper-slide-active & {
      opacity: 1; }

    > div,
    iframe,
    object,
    embed {
      @include size(100%); } }

  .timer {
    position: absolute;
    left: 23px;
    top: 23px;
    z-index: 5;
    transition: all .2s;

    @media (min-width: 375px) {
      left: ls(375, 1200, 23, 45);
      top: ls(375, 1200, 23, 45); }

    @media (min-width: 1200px) {
      left: 45px;
      top: 45px; }

    &__icon {
      @include size(56px);

      @media (min-width: 375px) {
        @include size(ls(375, 1200, 56, 100)); }

      @media (min-width: 1200px) {
        @include size(100px);
        min-width: 0;
        min-height: 0; } }

    &__nums {
      font-size: 22px;
      transition: all .2s;

      @media (min-width: 375px) {
        font-size: ls(375, 1200, 22, 32); }

      @media (min-width: 1200px) {
        font-size: 32px; }

      &.is-finish {
        font-size: 27px;

        @media (min-width: 375px) {
          font-size: ls(375, 1200, 27, 52); }

        @media (min-width: 1200px) {
          font-size: 52px; } } }

    &__meter {
      stroke: #EFF1F5; } }

  &__pre-play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#ffffff, 0.1);
    z-index: 12; }

  &__pre-phrase {
    white-space: nowrap;
    width: 100%;
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    transition: all .4s;
    left: 0;
    font-weight: $wt_get_ready_fw;
    font-size: 13px;

    @media (min-width: 375px) {
      font-size: ls(375, 1350, 13, $wt_get_ready_fz); }

    @media (min-width: 1350px) {
      font-size: #{$wt_get_ready_fz}px; }

    &.starting {
      left: 90%; } }

  &__pre-timer {
    position: absolute;
    left: 11.5px;
    top: 11.5px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7DF3B3;
    border-radius: 50%;
    @include size(75px);

    @media (min-width: 375px) {
      @include size(ls(375, 1200, 75, 118));
      left: ls(375, 1200, 23, 45);
      top: ls(375, 1200, 23, 45);

      left: calc(#{ls-c(375, 1200, 23, 45)} - ((#{ls-c(375, 1200, 75, 118)} - #{ls(375, 1200, 56, 100)}) / 2));
      top: calc(#{ls-c(375, 1200, 23, 45)} - ((#{ls-c(375, 1200, 75, 118)} - #{ls(375, 1200, 56, 100)}) / 2)); }

    @media (min-width: 1200px) {
      @include size(118px);
      left: 36px;
      top: 36px; }

    .timer {
      position: relative;
      top: 0;
      left: 0;

      &__icon {
        @include size(56px);

        @media (min-width: 375px) {
          @include size(ls(375, 1200, 56, 100)); }

        @media (min-width: 1200px) {
          @include size(100px);
          min-width: 0;
          min-height: 0; } }

      &__meter {
        stroke: #ffffff; }

      &__nums {
        font-size: 22px;

        @media (min-width: 375px) {
          font-size: ls(375, 1350, 22, 32); }

        @media (min-width: 1350px) {
          font-size: 32px; }

        &.is-start {
          white-space: nowrap;
          font-size: 13px;

          @media (min-width: 375px) {
            font-size: ls(375, 1350, 13, 23); }

          @media (min-width: 1350px) {
            font-size: 23px; } } } } } }

.workout-play-video {
  &__item {
    position: relative;
    z-index: 1; }

  &__preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: all .2s;

    img {
      max-width: 100%;
      max-height: 100%; } } }

.workout-play-pause-block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2; }

.workout-play-slide-name {
  font-size: 14px;
  line-height: 156.49%;
  text-transform: uppercase;
  position: absolute;
  top: 18px;
  right: 25px;
  color: rgba(#000000, 0.6);
  z-index: 2;
  opacity: 0;
  transition: all .2s;

  @media (min-width: 375px) {
    top: ls(375, 890, 18, 35);
    right: ls(375, 890, 25, 35);

    @media (min-width: 480px) {
      font-size: 15px; }

    @media (min-width: 576px) {
      font-size: 16px; }

    @media (min-width: 768px) {
      font-size: 17px; }

    @media (min-width: 890px) {
      font-size: 18px;
      top: 35px;
      right: 35px; } }

  .swiper-slide-active & {
    opacity: 1; } }

.workout-play-slide-nav {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 13;
  top: 18px;
  right: 15px;
  opacity: 0;

  @media (min-width: 375px) {
    top: ls(375, 1200, 18, 33);
    right: ls(375, 1200, 15, 22); }

  @media (min-width: 1200px) {
    top: 33px;
    right: 22px; }

  .swiper-slide-active & {
    opacity: 1; } }

.workout-play-slide-full {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  border-radius: 2px;
  @include size(40px);

  @media (min-width: 768px) {
    @include size(50px); }

  @media (min-width: 1025px) {
    @include size(60px);
    display: none; }

  .is-ios & {
    display: none; }

  &:hover {
    @media (min-width: 1025px) {
      background-color: rgba(#000000, 0.01); } }

  &:active {
    background-color: rgba(#000000, 0.01); }

  svg {
    @include size(24px);

    @media (min-width: 1025px) {
      @include size(ls(375, 1200, 24, 32)); }

    @media (min-width: 1200px) {
      @include size(32px); } } }

.workout-play-slide-muting {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .2s;
  border-radius: 2px;
  @include size(40px);

  @media (min-width: 768px) {
    @include size(50px); }

  @media (min-width: 1025px) {
    @include size(60px); }

  &:hover {
    @media (min-width: 1025px) {
      background-color: rgba(#000000, 0.01); } }

  &:active {
    background-color: rgba(#000000, 0.01); }

  svg {
    @include size(24px);

    @media (min-width: 1025px) {
      @include size(ls(375, 1200, 24, 32)); }

    @media (min-width: 1200px) {
      @include size(32px); } } }

.workout-play-main-name {
  padding-top: 2px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  margin-right: 12px;
  display: none; }
