@import "../../../assets/sass/utils/utils";

.workout-plans-list {
  $t: &;

  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;

  @media (min-width: 576px) {
    margin-top: 40px; }

  @media (min-width: 768px) {
    margin-bottom: -20px; }

  @media (min-width: 1024px) {
    margin-bottom: -30px; }

  @media (min-width: 1200px) {
    margin-bottom: -40px; }

  @media (min-width: 1350px) {
    margin-bottom: -64px; }

  .workout-card {
    width: 100%;
    max-width: 400px;

    @media (max-width: 575px) {
      margin-right: auto;
      margin-left: auto; }

    @media (min-width: 576px) {
      width: calc((100% - 20px) / 2);
      max-width: 100%; }

    @media (min-width: 890px) {
      width: calc((100% - 40px) / 3); }

    @media (min-width: 1024px) {
      width: calc((100% - 60px) / 3); }

    @media (min-width: 1200px) {
      width: calc((100% - 80px) / 3); }

    @media (min-width: 1350px) {
      width: calc((100% - 128px) / 3); }

    &:not(:nth-child(2n)) {
      @media (min-width: 576px) and (max-width: 889px) {
        margin-right: 20px; } }

    &:not(:nth-child(3n)) {
      @media (min-width: 890px) {
        margin-right: 20px; }

      @media (min-width: 1024px) {
        margin-right: 30px; }

      @media (min-width: 1200px) {
        margin-right: 40px; }

      @media (min-width: 1350px) {
        margin-right: 64px; } }

    .workout-card__nav {
      @media (min-width: 890px) {
        top: 15px; }

      @media (min-width: 1200px) {
        top: 20px; } } }

  .workout-card.workout-card--small {
    @media (min-width: 890px) {
      width: calc((100% - 30px) / 4); }

    @media (min-width: 1024px) {
      width: calc((100% - 60px) / 4); }

    @media (min-width: 1200px) {
      width: calc((100% - 90px) / 4); }

    @media (min-width: 1350px) {
      width: calc((100% - 141px) / 4); }

    &:not(:nth-child(3n)) {
      @media (min-width: 890px) {
        margin-right: 0; } }

    &:not(:nth-child(4n)) {
      @media (min-width: 890px) {
        margin-right: 10px; }

      @media (min-width: 1024px) {
        margin-right: 20px; }

      @media (min-width: 1200px) {
        margin-right: 30px; }

      @media (min-width: 1350px) {
        margin-right: 47px; } } } }

.workout-plans-more {
  margin-top: 30px;

  @media (min-width: 480px) {
    margin-top: 35px; }

  @media (min-width: 576px) {
    margin-top: 40px; }

  @media (min-width: 768px) {
    margin-top: 50px; }

  @media (min-width: 890px) {
    margin-top: 60px; }

  @media (min-width: 1024px) {
    margin-top: 70px; } }

.workout-card {
  $t: &;

  cursor: pointer;
  margin-bottom: 20px;
  transition: none;
  overflow: hidden;
  height: 300px;
  position: relative;
  border-radius: $workout_card_border_raius;

  @media (min-width: 360px) {
    height: 315px; }

  @media (min-width: 375px) {
    height: ls(375, 450, 315px, 340); }

  @media (min-width: 450px) {
    height: 340px; }

  @media (min-width: 576px) {
    height: ls(576, 889, 260, 370); }

  @media (min-width: 890px) {
    margin-bottom: 20px;
    height: ls(890, 1349, 270, 360); }

  @media (min-width: 1024px) {
    margin-bottom: 30px; }

  @media (min-width: 1200px) {
    margin-bottom: 40px; }

  @media (min-width: 1350px) {
    margin-bottom: 64px;
    height: 360px; }

  &::after {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.07) 100%); }

  &__close {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    padding-bottom: 45px;

    .icon-lock-outlined {
      fill: none;
      stroke: #fff;
      @include size(80px); } }

  &__pic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

  &__inner {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column; }

  &__tags {
    position: absolute;
    top: 25px;
    left: 25px;

    @media (min-width: 720px) {
      top: 30px;
      left: 30px; }

    @media (min-width: 890px) {
      top: 20px;
      left: 20px; }

    @media (min-width: 1024px) {
      top: 25px;
      left: 25px; }

    @media (min-width: 1200px) {
      top: 30px;
      left: 30px; } }

  &__tag {
    &.beginner {
      background-color: $color_level_beginner; }

    &.intermediate {
      background-color: $color_level_medium; }

    &.advanced {
      background-color: $color_level_advanced; } }

  &__nav {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    align-items: center;

    @media (min-width: 720px) {
      top: 30px;
      right: 30px; }

    @media (min-width: 890px) {
      top: 25px;
      right: 25px; }

    .category-tag {
      z-index: 1; }

    &.b-category {
      top: 14px;
      right: 25px;

      @media (min-width: 720px) {
        top: 19px; }

      @media (min-width: 890px) {
        top: 14px;
        right: 15px; }

      @media (min-width: 1024px) {
        right: 25px; } } }

  &__category {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    text-transform: capitalize;
    z-index: 1;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      @include size(53px);
      border-radius: 50%;

      &.beginner {
        background-color: $color_level_beginner; }

      &.intermediate {
        background-color: $color_level_medium; }

      &.advanced {
        background-color: $color_level_advanced; }

      svg {
        color: #000000;
        @include size(30px); } } }

  &__body {
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    justify-content: space-between;
    padding: 0 25px 27px; }

  &__title {
    color: $workout_card_title_color;
    font-size: $workout_card_font_size_base;
    line-height: 120%;
    font-weight: $workout_card_font_weight;
    margin-bottom: 8px;
    text-transform: $workout_card_text_transform;

    @media (min-width: 576px) and (max-width: 639px) {
      font-size: $workout_card_font_size_576_639; } }

  &__info {
    width: 100%;
    text-align: center;

    @media (min-width: 360px) {
      text-align: left;
      width: $workout_card_info_width; }

    @media (min-width: 576px) {
      text-align: center;
      width: 100%; }

    @media (min-width: 720px) {
      text-align: left;
      width: $workout_card_info_width; }

    @media (min-width: 890px) {
      text-align: center;
      width: 100%; }

    @media (min-width: 1120px) {
      text-align: left;
      width: $workout_card_info_width; } }

  &__btn.btn-circle {
    display: none;

    @media (min-width: 360px) {
      display: $workout_card_btn_circle_display; }

    @media (min-width: 576px) {
      display: none; }

    @media (min-width: 720px) {
      display: $workout_card_btn_circle_display; }

    @media (min-width: 890px) {
      display: none; }

    @media (min-width: 1120px) {
      display: $workout_card_btn_circle_display; } }

  &__params {
    justify-content: center;

    @media (min-width: 360px) {
      justify-content: flex-start; }

    @media (min-width: 576px) {
      justify-content: center; }

    @media (min-width: 720px) {
      justify-content: flex-start; }

    @media (min-width: 890px) {
      justify-content: center; }

    @media (min-width: 1120px) {
      justify-content: flex-start; }

    .param {
      color: #fff;

      svg {
        fill: #fff; } } }

  &--small {
    @media (min-width: 890px) {
      height: ls(890, 1359, 220, 270); }

    @media (min-width: 1360px) {
      height: 270px; }

    #{$t}__info {
      @media (min-width: 890px) {
        width: 100%; } }

    #{$t}__title {
      @media (min-width: 890px) {
        width: 100%;
        text-align: center;
        font-size: $workout_card_small_title_font_size_890;
        margin-bottom: 10px; }

      @media (min-width: 1024px) {
        font-size: $workout_card_small_title_font_size_1024; }

      @media (min-width: 1200px) {
        font-size: $workout_card_small_title_font_size_1200;
        margin-bottom: 12px; } }

    #{$t}__params {
      @media (min-width: 890px) {
        width: 100%;
        justify-content: center; } }

    #{$t}__tags {
      @media (min-width: 890px) {
        top: 15px;
        left: 15px; }

      @media (min-width: 1200px) {
        top: 20px;
        left: 20px; }

      @media (min-width: 1200px) {
        left: 25px; } }

    #{$t}__category {
      @media (min-width: 890px) {
        top: 15px;
        right: 15px;
        width: 75px;
        text-align: center; }

      @media (min-width: 1200px) {
        top: 20px;
        right: 20px; }

      @media (min-width: 1200px) {
        right: 25px; } }

    #{$t}__body {
      @media (min-width: 890px) {
        padding: 0 10px 25px; }

      @media (min-width: 1200px) {
        padding: 0 27px 30px; } }

    #{$t}__btn {
      @media (min-width: 890px) {
        display: none; } } } }

.workout-card-like {
  @include size(32px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-left: 10px;
  position: relative;

  &__inner {
    @include size(32px);
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    z-index: 5;
    border-radius: $meal_card_btn_ico_border_radius;

    &.is-favorite {
      background-color: #7DF3B3;

      svg.icon-like-full {
        opacity: 1; }

      svg.icon-like {
        opacity: 0; } } }

  svg {
    fill: #000000;
    z-index: 2;
    @include size(17px, 15px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s;

    &.icon-like-full {
      opacity: 0; } } }

.workout-card-link {
  width: 100%;
  height: 100%;
  display: block; }
