@import "../../../assets/sass/utils/utils";

.input-auth-wrap {
  width: 100%;
  position: relative;

  ~ .input-auth-wrap {
    margin-top: $input_auth_margin_top; }

  &.with-icon {
    .input-auth {
      padding-left: 58px; } }

  svg {
    position: absolute;
    top: calc(50%);
    transform: translateY(-50%);
    left: 28px;
    @include size(20px);
    fill: #000000; } }

.input-auth {
  width: 100%;
  background-color: $input_auth_bg !important;
  box-shadow: none;
  border: $input_auth_border;
  font-size: $input_auth_font_size;
  color: $input_auth_color !important;
  padding: 27px 28px;
  font-weight: $input_auth_font_weight;
  font-family: $second-font;
  border-radius: $input_auth_border_radius;

  @include placeholder {
    font-size: $input_auth_font_size;
    color: rgba(#ABABAB, 0.6);
    font-weight: $input_auth_font_weight;
    font-family: $second-font; }

  &.error {
    color: #F64848 !important;

    + svg {
      fill: #F64848; } }

  &:-webkit-autofill {
    + svg {
      fill: #000000; } } }

.help-error {
  color: red;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  font-weight: 400;
  display: block;
  margin-top: 10px;
  background-color: #F64848;
  color: #ffffff;
  font-size: $input_auth_error_font_size_base;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-family: $second-font;
  border-radius: $input_auth_error_border_radius;
  font-weight: $input_auth_error_font_weight;

  @media (min-width: 576px) {
    padding: 12px 18px;
    font-size: $input_auth_error_font_size_576; }

  @media (min-width: 890px) {
    padding: 14px 20px;
    font-size: $input_auth_error_font_size_890; }

  ~ .help-error {
    margin-top: 4px; }

  ~ .auth-btn {
    margin-top: 40px; } }

.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  .help-error {
    order: 4; }

  .auth-btn {
    order: 5; } }
