.modal-right-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#2C2C2C, 0.8);
  z-index: 15; }

.modal-right {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 550px;
  max-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0 15px;

  @media (min-width: 480px) {
    padding: 45px 0 20px; }

  @media (min-width: 576px) {
    padding: 50px 0 25px; }

  @media (min-width: 1200px) {
    padding: 60px 0 35px; } }

.modal-right-container {
  padding: 0 25px;
  height: 100%;
  @media (min-width: 480px) {
    padding: 0 35px; }

  @media (min-width: 576px) {
    padding: 0 45px; }

  @media (min-width: 1200px) {
    padding: 0 55px; } }

.modal-right-head {
  background-color: #fff;
  padding-bottom: 30px;
  width: 100%;

  @media (min-width: 480px) {
    padding-bottom: 35px; }

  @media (min-width: 1200px) {
    padding-bottom: 40px; } }

.modal-right-body {
  margin-bottom: auto;
  height: 100%;
  overflow-y: auto;
  width: 100%; }

.modal-right-footer {
  background-color: #fff;
  padding-top: 15px;
  width: 100%;

  @media (min-width: 480px) {
    padding-top: 20px; } }

.modal-right-transition-enter {
  opacity: 0;

  .modal-right {
    transform: translateX(100%);
    opacity: 0; } }

.modal-right-transition-enter-active {
  opacity: 1;
  transition: all .3s ease-in-out;

  .modal-right {
    transition-delay: 1s;
    transform: translateX(0);
    opacity: 1;
    transition: all .4s ease-in-out; } }

.modal-right-transition-exit {
  opacity: 1;

  .modal-right {
    transform: translateX(0);
    opacity: 1; } }

.modal-right-transition-exit-active {
  opacity: 0;
  transition: all .2s ease-in-out;

  .modal-right {
    transform: translateX(100%);
    opacity: 0;
    transition: all .4s ease-in-out; } }

.modal-right-close {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 576px) {
    top: 22px;
    right: 22px; } }

.modal-right-close-icon {
  width: 20px;
  height: 20px; }
