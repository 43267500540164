@import "../../../assets/sass/utils/utils";

.wrap {
  width: 100%;
  display: block;
  position: relative;
  font-size: 15px;

  @media (min-width: 576px) {
    font-size: 16px; }

  &.white {
    .input {
      border: 1px solid #E1E1E1; } }

  &.error {
    .input {
      border-color: #F64848;
      color: #F64848;

      @include placeholder() {
        color: rgba(#F64848, 0.4); } }

    .label {
      color: #F64848; } } }

.input {
  width: 100%;
  display: block;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: none;
  color: #000000;
  line-height: 156.49%;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.438em 1.5625em;
  transition: all .25s ease-in-out;

  &.withLabel {
    padding-top: 2.25em;
    padding-bottom: 0.625em; }

  &.white {
    border: 1px solid #E1E1E1; }

  &:focus {
    border: 1px solid #000; }

  @include placeholder() {
    color: rgba(#000000, 0.4); } }

.label {
  font-size: 0.75em;
  position: absolute;
  line-height: 156.49%;
  top: 1.25em;
  left: 1.91666667em;
  left: 2.08333333em;
  font-family: $second-font;
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  transition: all .25s ease-in-out; }
