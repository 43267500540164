.cancel {
  width: 100%; }

.wrap {
  padding: 25px 25px;
  display: flex;
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 10px;
  width: 100%;
  flex-direction: column;

  @media (min-width: 480px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; }

  @media (min-width: 576px) {
    padding: 35px 0 35px 35px; }

  @media (min-width: 768px) {
    align-items: center; }

  @media (min-width: 1200px) {
    padding: 35px 0 35px 50px; } }

.logo {
  margin-bottom: 30px;

  @media (min-width: 480px) {
    margin-bottom: 0;
    margin-top: 8px; }

  @media (min-width: 768px) {
    margin-top: 0; }

  img {
    width: 65px; } }

.body {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 480px) {
    width: calc(100% - 65px - 25px); }

  @media (min-width: 576px) {
    width: calc(100% - 65px - 50px); }

  @media (min-width: 768px) {
    width: calc(100% - 65px - 35px); }

  @media (min-width: 1200px) {
    width: calc(100% - 65px - 50px); }

  &.delifitplan {
    @media (min-width: 480px) {
      width: calc(100% - 100px - 25px); }

    @media (min-width: 576px) {
      width: calc(100% - 120px - 25px); }

    @media (min-width: 890px) {
      width: calc(100% - 140px - 25px); }

    @media (min-width: 1200px) {
      width: calc(100% - 180px - 25px); } }

  &.funappetit {
    @media (min-width: 480px) {
      width: calc(100% - 90px - 25px); }

    @media (min-width: 576px) {
      width: calc(100% - 100px - 25px); }

    @media (min-width: 890px) {
      width: calc(100% - 120px - 25px); }

    @media (min-width: 1200px) {
      width: calc(100% - 160px - 25px); } } }

.item {
  width: calc(100% / 2);
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: 480px) {
    text-align: left; }

  @media (min-width: 768px) {
    width: calc(100% / 4);
    margin-bottom: 0; } }

.value {
  font-size: 30px;
  line-height: 120%;
  font-weight: 700;
  margin-bottom: 5px; }

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 156.49%;
  opacity: 0.6; }

.btn {
  line-height: 36px;
  font-size: 12px;
  color: rgba(#000, 0.6);
  border-radius: 10px;
  background-color: transparent;
  padding: 0 10px;
  margin-left: -10px;
  transition: all .3s ease-in-out;
  margin-top: 45px;

  &:hover {
    background-color: rgba(#000, 0.015);
    color: rgba(#000, 0.8); } }

.helper {
  font-size: 12px;
  color: rgba(#000, 0.6);
  margin-top: 45px; }
