@import "../../../assets/sass/utils/utils";

.input-wrap {
  padding: $input_wrap_padding;
  width: 100%;
  background-color: $input_wrap_bg;
  border-radius: $input_wrap_border_radius;
  border: $input_wrap_border; }

.input-label {
  width: 100%;
  margin-bottom: $input_label_margin_bottom;
  line-height: 156.49%;
  font-family: $second-font;
  font-size: 12px;
  font-weight: 400;
  color: rgba(#000000, 0.6); }

.input {
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
  color: #000000;
  line-height: 156.49%;
  text-transform: $input_text_transform;
  font-size: $input_font_size;
  font-weight: $input_font_weight; }
