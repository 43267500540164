.loader-over {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(#2C2C2C, 0.8); }

.loader-over-transition-enter {
  opacity: 0; }

.loader-over-transition-enter-active {
  opacity: 1;
  transition: all .2s ease-in-out; }

.loader-over-transition-exit {
  opacity: 1; }

.loader-over-transition-exit-active {
  opacity: 0;
  transition: all .2s ease-in-out; }
