@import "../../../../../assets/sass/utils/utils";

.not-purchased-icon {
  border-radius: 50%;
  background-color: $color_main_focus;
  display: flex;
  justify-content: center;
  align-items: center;
  @include size(43px);

  .icon {
    @include size(50.5%, 47.1%);
    fill: #fff; } }
