@import "../../../assets/sass/utils/utils";

.profile-wrap {
  padding: 15px 0 30px;

  @media (min-width: 480px) {
    padding: 30px 0; }

  @media (min-width: 768px) {
    padding: 40px 0; }

  @media (min-width: 890px) {
    padding: 50px 0; }

  @media (min-width: 1200px) {
    padding: 60px 0; } }

.profile {
  display: flex;
  flex-direction: column;
  margin: 0 auto; }

.profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  width: 100%;
  flex-direction: column;

  @media (min-width: 480px) {
    margin-bottom: 40px; }

  @media (min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap; }

  @media (min-width: 890px) {
    margin-bottom: 50px; }

  @media (min-width: 1200px) {
    flex-direction: row; }

  &__body {
    width: 100%;

    @media (min-width: 640px) {
      width: calc(100% - 160px - 20px); }

    @media (min-width: 768px) {
      width: calc(100% - 130px - 20px - 180px); }

    @media (min-width: 890px) {
      width: calc(100% - 160px - 20px - 180px);
      max-width: 537px; }

    @media (min-width: 1024px) {
      width: calc(100% - 160px - 35px - 180px); }

    @media (min-width: 1200px) {
      width: calc(100% - 160px - 60px - 160px - 140px); } }

  &__params {
    display: flex;
    width: 100%;
    align-items: flex-start;

    @media (min-width: 480px) {
      align-items: center; } }

  .user-parameter {
    width: 33.3%; }

  .user-avatar {
    margin-bottom: 22px;

    @media (min-width: 640px) {
      margin-bottom: 0;
      margin-right: 20px; }

    @media (min-width: 1024px) {
      margin-right: 35px; }

    @media (min-width: 1200px) {
      margin-right: 60px; } }

  .weight-goal {
    margin-left: auto; }

  .user-name {
    margin-bottom: 28px;
    text-align: center;

    @media (min-width: 640px) {
      text-align: left;
      margin-bottom: 12px; }

    @media (max-width: 575px) {
      font-size: 30px; }

    @media (min-width: 1200px) {
      text-align: left;
      margin-bottom: 20px; } } }

.user-parameter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 480px) {
    flex-direction: row;
    align-items: center; }

  &__icon {
    @include size(40px);
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #000000;
    margin-right: 10px;

    @media (min-width: 480px) {
      @include size(30px); }

    @media (min-width: 1024px) {
      margin-right: 14px;
      @include size(45px); }

    .height-icon {
      @include size(28px);

      @media (min-width: 1024px) {
        @include size(39px); } }

    .weight-icon {
      @include size(28px);

      @media (min-width: 1024px) {
        @include size(37px); } }

    .eat-icon {
      @include size(30px);

      @media (min-width: 1024px) {
        @include size(43px); } } }

  &__body {
    width: 100%;
    margin-top: 7px;

    @media (min-width: 480px) {
      width: calc(100% - 40px); }

    @media (min-width: 1024px) {
      width: calc(100% - 59px); } }

  &__title {
    color: rgba(#000000, 0.6);
    font-size: $user_parameter_title_font_size;
    font-weight: $user_parameter_title_font_weight;
    line-height: 156.49%;
    margin-bottom: 2px;
    font-family: $second-font;

    span {
      display: none;

      @media (min-width: 889px) {
        display: inline; } } }

  &__value {
    line-height: 156.49%;
    font-size: $user_parameter_value_font_size_base;
    font-weight: $user_parameter_value_font_weight;
    text-transform: $user_parameter_value_text_transform;
    color: #000000;

    @media (min-width: 768px) {
      font-size: $user_parameter_value_font_size_768; }

    @media (min-width: 890px) {
      font-size: $user_parameter_value_font_size_890; }

    span {
      position: relative;
      width: auto; } } }

.weight-goal-wrap {
  width: 100%;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 160px; } }

.weight-log-link {
  width: 100%;
  display: flex;
  background-color: $weight_goal_link_bg;
  align-items: center;
  height: 40px;
  color: $weight_goal_link_color;
  text-transform: $weight_goal_link_text_transform;
  font-size: $weight_goal_link_font_size;
  font-weight: $weight_goal_link_font_weight;
  border-radius: $weight_goal_link_border_radius;
  line-height: 1;
  justify-content: center;
  margin-top: 11px;
  transition: all .2s;

  svg {
    fill: #ffffff;
    @include size(16px, 14px);
    margin-right: 8px; }

  &:hover {
    opacity: 0.8; }

  &.refer-friend {
    background-color: #86DBCC;
    text-transform: none; } }

.weight-goal {
  $t: &;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 34px;
  margin-top: 30px;
  position: relative;
  transition: all .2s;
  background-color: $weight_goal_bg;
  border-radius: $weight_goal_border_radius;
  border: $weight_goal_border;

  @media (min-width: 768px) {
    flex-direction: column;
    padding: 32px 0;
    margin-top: 0; }

  &.is-focus {
    background-color: #fff;
    border-color: $weight_goal_border_color_active;

    #{$t}__btn {
      background-color: $weight_goal_bg;

      svg {
        fill: $weight_goal_svg_fill_active; } } }

  &.error {
    @media (max-width: 767px) {
      margin-bottom: 47px; }

    #{$t}__input {
      color: #F64848; } }

  &__title {
    color: $weight_goal_title_color;
    font-size: $weight_goal_title_font_size_base;
    line-height: $weight_goal_title_line_height_base;
    font-family: $second-font;
    width: 78px;
    transition: all .2s;
    font-weight: $weight_goal_title_font_weight;

    @media (min-width: 480px) {
      width: auto;
      font-size: $weight_goal_title_font_size_480;
      line-height: $weight_goal_title_line_height_max; }

    @media (min-width: 768px) {
      margin-bottom: 11px;
      font-size: $weight_goal_title_font_size_768;
      text-align: center;
      line-height: $weight_goal_title_line_height_max; } }

  &__input {
    color: $weight_goal_input_color;
    max-width: 100%;
    width: 100px;
    background-color: transparent;
    font-weight: $weight_goal_input_font_weight;
    font-size: $weight_goal_input_font_size;
    line-height: 1;
    border: 0;
    text-align: center;
    padding: 0; }

  &__btn {
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all .2s;
    border-radius: $weight_goal_btn_border_radius; }

  &__help-error {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #F64848;
    padding: 12px 20px;
    text-align: center;
    color: #ffffff;
    font-family: $second-font;
    font-size: $weight_goal_help_error_font_size;
    font-weight: $weight_goal_help_error_font_weight;
    top: calc(100% + 10px);
    border-radius: $weight_goal_help_error_border_radius; } }

.profile-results {
  width: 100%;
  margin-left: auto;
  margin-bottom: 35px;

  @media (min-width: 480px) {
    margin-bottom: 40px; }

  @media (min-width: 890px) {
    margin-bottom: 50px; }

  @media (min-width: 1024px) {
    width: calc(100% - 160px - 35px); }

  @media (min-width: 1200px) {
    width: calc(100% - 160px - 60px); } }

.profile-settings {
  width: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 800px) {
    flex-direction: row;
    align-items: flex-end; }

  @media (min-width: 1024px) {
    width: calc(100% - 160px - 35px);
    align-items: flex-end; }

  @media (min-width: 1200px) {
    width: calc(100% - 160px - 60px); }

  &__title {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;

    @media (min-width: 480px) {
      margin-bottom: 35px; }

    @media (min-width: 576px) {
      margin-bottom: 40px; }

    @media (min-width: 768px) {
      margin-bottom: 45px; }

    @media (min-width: 890px) {
      margin-bottom: 50px; } }

  &__btn {
    width: 160px;

    @media (min-width: 800px) {
      margin-left: auto; }

    &:disabled {
      background-color: rgba(#000000, 0.6);
      color: #ffffff;
      cursor: auto; } }

  &__fields {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 9px;

    @media (min-width: 576px) {
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 20px; }

    @media (min-width: 800px) {
      width: 537px;
      margin-bottom: -21px; }

    .input-wrap {
      width: 100%;
      margin-bottom: 21px;
      max-width: 400px;

      @media (min-width: 576px) {
        width: calc( (100% - 12px) / 2 );
        margin-bottom: 10px; }

      @media (min-width: 800px) {
        width: calc( (100% - 23px) / 2 );
        margin-bottom: 21px; }

      &:last-child {
        margin-right: auto; }

      &:nth-child(3) {
        order: 2;

        @media (min-width: 576px) {
          order: 0; } }

      &:nth-child(2n) {
        margin-right: 0; } } } }

.profile-footer {
  width: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (min-width: 375px) {
    margin-top: ls(375, 1200, 40, 80); }

  @media (min-width: 1024px) {
    width: calc(100% - 160px - 35px); }

  @media (min-width: 1200px) {
    width: calc(100% - 160px - 60px);
    margin-top: 80px; } }

.cancel-sub-btn {
  font-size: $cancel_sub_link_font_size;
  font-weight: $cancel_sub_link_font_weight;
  display: block;
  margin-right: 0;
  font-family: $second-font;
  opacity: 0.6;

  &:hover {
    text-decoration: underline; }

  &.send {
    &:hover {
      text-decoration: none; } } }

.cancel-sub-radios {
  justify-content: space-around;
  margin-bottom: 30px;

  @media (min-width: 576px) {
    margin-bottom: 35px;
    padding: 0 25px; }

  @media (min-width: 768px) {
    margin-bottom: 40px; } }

.cancel-sub-modal {
  .small-modal-inner {
    @media (max-width: 374px) {
      padding-left: 10px;
      padding-right: 10px; } } }
