@import "../../../assets/sass/utils/utils";

.week-calendar-wrap {
  width: 100%;

  @media (min-width: 530px) {
    max-width: 325px; }

  @media (min-width: 576px) {
    max-width: 350px; }

  @media (min-width: 640px) {
    max-width: 375px; }

  @media (min-width: 768px) {
    max-width: 496px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .swiper-container {
    @media (min-width: 768px) {
      width: calc(100% - 100px); } } }

.calendar-arrow-nav {
  align-items: center;
  justify-content: center;
  background-color: $meal_plan_calendar_nav_btn_bg;
  height: 80px;
  width: 46px;
  display: none;
  cursor: pointer;
  border-radius: $meal_plan_calendar_nav_btn_border_radius;
  border: $meal_plan_calendar_nav_btn_border;
  transition: all .2s;

  @media (min-width: 768px) {
    display: flex; }

  &--right {
    order: 2; }

  svg {
    @include size(14px, 11px); }

  &:hover {
    border-color: $meal_plan_calendar_nav_btn_border_hover; } }

.week-calendar-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.week-calendar-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $meal_plan_calendar_day_bg;
  justify-content: center;
  cursor: pointer;
  border: $meal_plan_calendar_day_border;
  transition: all .2s;
  width: calc( (100% - 16px) / 5 );
  height: 70px;
  height: 60px;
  border-radius: $meal_plan_calendar_day_border_radius;

  @media (min-width: 320px) {
    height: ls(320, 374, 60, 70); }

  @media (min-width: 375px) {
    height: 70px; }

  @media (min-width: 420px) {
    height: ls(420, 529, 70, 90); }

  @media (min-width: 530px) {
    height: 70px; }

  @media (min-width: 640px) {
    height: 80px; }

  &.active {
    background-color: $meal_plan_calendar_day_active_bg;
    color: $meal_plan_calendar_day_active_color;
    border-color: $meal_plan_calendar_day_active_border_color; }

  &:hover {
    border-color: $meal_plan_calendar_day_hover_border_color; } }

.week-calendar-day-name {
  font-family: $second-font;
  font-size: $meal_plan_calendar_day_name_font_size;
  line-height: $meal_plan_calendar_day_name_line_height;
  margin-bottom: 5px;

  @media (min-width: 420px) {
    margin-bottom: 7px; }

  @media (min-width: 500px) {
    margin-bottom: 10px; }

  @media (min-width: 530px) {
    margin-bottom: 5px; }

  @media (min-width: 640px) {
    margin-bottom: 7px; }

  @media (min-width: 768px) {
    margin-bottom: 9px; } }

.week-calendar-day-num {
  font-size: $meal_plan_calendar_day_num_font_size_base;
  font-weight: $meal_plan_calendar_day_num_font_wieght;
  line-height: 1;

  @media (min-width: 420px) {
    font-size: $meal_plan_calendar_day_num_font_size_420; }

  @media (min-width: 500px) {
    font-size: $meal_plan_calendar_day_num_font_size_500; }

  @media (min-width: 530px) {
    font-size: $meal_plan_calendar_day_num_font_size_530; }

  @media (min-width: 640px) {
    font-size: $meal_plan_calendar_day_num_font_size_640; }

  @media (min-width: 768px) {
    font-size: $meal_plan_calendar_day_num_font_size_768; } }
