@import "../../../assets/sass/utils/utils";

.header {
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  position: relative;

  @media (min-width: 480px) {
    height: 80px; }

  @media (min-width: 890px) {
    height: 90px; }

  @media (min-width: 1024px) {
    height: 98px; } }

.header-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .main-nav {
    margin-left: auto; }

  .nav-burger {
    margin-left: auto; } }

.main-nav {
  display: none;
  align-items: center;

  @media (min-width: 1024px) {
    display: flex; }

  .main-nav-link + .main-nav-link {
    margin-left: $header_main_nav_link_margin_left_base;

    @media (min-width: 1024px) {
      margin-left: $header_main_nav_link_margin_left_desktop_1024; }

    @media (min-width: 1200px) {
      margin-left: $header_main_nav_link_margin_left_desktop_1200; }

    @media (min-width: 1350px) {
      margin-left: $header_main_nav_link_margin_left_desktop_1350; }

    @media (min-width: 1440px) {
      margin-left: $header_main_nav_link_margin_left_desktop_1440; }

    @media (min-width: 1540px) {
      margin-left: $header_main_nav_link_margin_left_desktop_1540; } } }

.header.purple-header {
  .main-nav-link {
    @media (min-width: 1024px) {
      color: #fff;
      opacity: 1;

      svg {
        fill: #fff; }

      &:hover {
        opacity: 0.9; } } }

  .nav-burger:not(.open) {
    span {
      background-color: #fff; } }

  .mob-nav-head .nav-burger {
    span {
      background-color: #000; } } }
