@import "../../../../../assets/sass/utils/utils";

.subs-modal-body {
  &__title {
    font-size: 30px;
    line-height: 125%;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 25px;

    @media (min-width: 480px) {
      font-size: 33px; }

    @media (min-width: 576px) {
      font-size: 36px; }

    @media (min-width: 1200px) {
      font-size: 40px; } }

  &__desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 156.49%;
    font-family: $second-font;
    margin-bottom: 30px;
    max-width: 320px;

    @media (min-width: 480px) {
      font-size: 15px; }

    @media (min-width: 576px) {
      font-size: 16px; } }

  &__item + &__item {
    margin-top: 20px;

    @media (min-width: 480px) {
      margin-top: 22px; }

    @media (min-width: 576px) {
      margin-top: 25px; } }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: $second-font;
    line-height: 145%;
    max-width: 370px;

    @media (min-width: 480px) {
      font-size: 15px; }

    @media (min-width: 576px) {
      font-size: 16px; } }

  &__icon {
    border-radius: 50%;
    background-color: #A7F693;
    @include size(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    @media (min-width: 480px) {
      @include size(32px);
      margin-right: 12px; }

    @media (min-width: 576px) {
      @include size(34px);
      margin-right: 15px; }

    .icon-check {
      @include size(55%, 50%);
      stroke: #000; } }

  &__text {
    max-width: calc(100% - 40px);

    @media (min-width: 480px) {
      max-width: calc(100% - 44px); }

    @media (min-width: 576px) {
      max-width: calc(100% - 49px); } } }

.subs-modal-body-icon {
  margin-bottom: -2px;

  @media (min-width: 480px) {
    margin-bottom: 3px; }

  @media (min-width: 576px) {
    margin-bottom: 13px; }

  @media (min-width: 1200px) {
    margin-bottom: 23px; } }

.subs-modal-body-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center; }

.subs-modal-body-ico {
  @include size(80px);
  border-radius: 50%;
  border: 4px solid #ff0000;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;

  span {
    width: 54px;
    height: 4px;
    border-radius: 2px;
    background-color: #ff0000;
    display: block;
    transform-origin: 50% 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:first-child {
      transform: translate(-50%, -50%) rotate(45deg); }

    &:last-child {
      transform: translate(-50%, -50%) rotate(-45deg); } } }
