@import "../../../../../assets/sass/utils/utils";

@mixin overContainer($width, $margin: -$width, $padding: $width) {
  width: calc(100% + #{$width} + #{$width});
  margin-left: #{$margin};
  margin-right: #{$margin}; }

.meal-card-swipeable {
  @include overContainer(10px);

  @media (min-width: 320px) {
    @include overContainer(#{ls-c(320, 374, 10, 25)}, #{ls(320, 374, -10, -25)}, #{ls(320, 374, 10, 25)}); }

  @media (min-width: 375px) {
    @include overContainer(25px); }

  @media (min-width: 480px) {
    @include overContainer(#{ls-c(480, 1349, 25, 50)}, #{ls(480, 1349, -25, -50)}, #{ls(480, 1349, 25, 50)}); }

  .swiper-container {
    overflow: visible; }

  .slide-empty {
    width: 0;
    display: none; }

  .swiper-slide {
    &:first-child {
      width: 100%; }

    &:last-child {
      width: 72px; }

    .meal-card {
      margin: 0 auto; } }

  + .meal-card-swipeable {
    margin-top: 20px; }

  .meal-card {
    &__inner {
      position: relative;
      overflow: hidden;

      @media (max-width: 359px) {
        padding-left: 16px;
        padding-right: 16px; } }

    &__name {
      min-height: 22px; }

    .tag-list {
      .tag {
        margin: 0; } } }

  &.with-transition {
    .meal-card-done-btn {
      transition: all .300s;
      transition-delay: .600s;

      &__inner {
        transition: all .300s;
        transition-delay: .600s; } }

    .meal-card {
      transition: all .300s;
      transition-delay: .600s;

      .tag-list {
        z-index: 3; }

      .tag-list,
      &__replace,
      &__body,
      .param-list {
        transition: all .200s;
        transition-delay: .400s; }

      .tag-list {
        position: absolute;
        left: 25px;
        top: 25px; }

      &__name {
        max-height: 400px;
        transition: all .2s;
        overflow: hidden;
        height: auto; } } }

  &.is-done {
    .meal-card-done-btn {
      height: 90px;

      &__inner {
        &:last-child {
          visibility: visible; }

        &:first-child {
          visibility: hidden; } } }

    .meal-card {
      height: 90px;

      .tag-list {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 0; }

      &__replace {
        opacity: 0;
        pointer-events: none; }

      &__body {
        transform: translateY(27px); }

      .param-list {
        opacity: 0;
        margin-top: 0; }

      &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        max-height: 22px; } } }

  &.is-undo {
    .meal-card {
      transition-delay: 0.4s;

      .tag-list,
      &__replace {
        transition-delay: 0.5s; }

      &__name {
        transition: all .5s;
        transition-delay: .5s; }

      &__body {
        transition: all .3s;
        transition-delay: 0.4s; } } } }

.meal-card-swipeable-element {
  padding-right: 10px;
  padding-left: 10px;

  @media (min-width: 320px) {
    padding-right: #{ls(320, 374, 10, 25)};
    padding-left: #{ls(320, 374, 10, 25)}; }

  @media (min-width: 375px) {
    padding-right: 25px;
    padding-left: 25px; }

  @media (min-width: 480px) {
    padding-right: #{ls(480, 1349, 25, 50)};
    padding-left: #{ls(480, 1349, 25, 50)}; } }

.meal-card-done-btn {
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #7DF3B3;
  font-family: $second-font;
  font-size: 12px;
  height: 330px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 100vh;
    background-color: #7DF3B3; }

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;

    &:last-child {
      visibility: hidden; } }

  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 7px;

    &.undo-arrow-icon {
      width: 19px;
      height: 23px; } } }
