@import "../../../assets/sass/utils/utils";

.nav-burger {
  width: 26px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  height: 23px;
  align-items: center;
  justify-content: center;

  span {
    width: 100%;
    background-color: #000000;
    border-radius: 5px;
    height: 3px;
    transition: transform .3s, opacity .3s;

    + span {
      margin-top: 7px; } }

  &.open {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px); }

      &:nth-child(2) {
        transform: translateX(30px) scale(0.7);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -4px);
        margin-top: 0; } } }

  @media (min-width: 1024px) {
    display: none; } }

.mob-nav-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: #ffffff;

  @media (min-width: 1024px) {
    display: none; }

  .container {
    height: 100%; } }

.mob-nav {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mob-nav-menu {
    flex: 1 0 auto; }

  .mob-nav-footer {
    flex: 0 0 auto; } }

.mob-nav-menu {
  height: calc(100% - 80px - 41px - 5vh - 2.5vh);
  overflow-y: auto;
  padding-top: 5vh;

  @media (min-width: 890px) {
    height: calc(100% - 90px - 41px - 5vh - 2.5vh); }

  .main-nav-link + .main-nav-link {
    margin-top: 15px;

    @media (min-width: 768px) {
      margin-top: 20px; } }

  .main-nav-link {
    @media (min-width: 768px) and (min-height: 768px) {
      font-size: 18px; }

    svg {
      @media (min-width: 768px) and (min-height: 768px) {
        margin-right: 20px; }

      &.icon-home-icon {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(34px, 30px); } }

      &.icon-workouts {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(43px, 32px); } }

      &.icon-meal-plan,
      &.icon-recipes {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(30px, 32px); } }

      &.icon-lifestyle {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(32px); } }

      &.icon-profile {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(32px); } }

      &.icon-logout {
        @media (min-width: 768px) and (min-height: 768px) {
          @include size(30px); } } } } }

.mob-nav-footer {
  padding-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 5vh;
  padding-top: 2.5vh;

  .nav-terms {
    width: 100%;
    padding: 0 22px;
    margin-bottom: 15px; }

  .copyright {
    width: 100%;
    padding: 0 22px;
    color: #000000;

    .index-page & {
      color: #000000; } } }

.mob-nav-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  @media (min-width: 480px) {
    height: 80px; }

  @media (min-width: 890px) {
    height: 90px; }

  .logo {
    display: block; } }

// effects

.alert-enter {
  opacity: 0;

  .nav-burger {
    span {
      &:nth-child(1) {
        transform: rotate(0) translate(0, 0); }

      &:nth-child(2) {
        transform: translateX(0) scale(1);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(0) translate(0, 0);
        margin-top: 7px; } } } }

.alert-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;

  .nav-burger {
    span {
      transition: opacity 300ms, transform 300ms;

      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px); }

      &:nth-child(2) {
        transform: translateX(30px) scale(0.7);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -4px);
        margin-top: 0; } } } }

.alert-exit {
  opacity: 1;

  .nav-burger {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px); }

      &:nth-child(2) {
        transform: translateX(30px) scale(0.7);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -4px);
        margin-top: 0; } } } }

.alert-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;

  .nav-burger {
    span {
      transition: opacity 300ms, transform 300ms;

      &:nth-child(1) {
        transform: rotate(0) translate(0, 0); }

      &:nth-child(2) {
        transform: translateX(0) scale(1);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(0) translate(0, 0);
        margin-top: 7px; } } } }

.alert-enter-done {
  opacity: 1;

  .nav-burger {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px); }

      &:nth-child(2) {
        transform: translateX(30px) scale(0.7);
        opacity: 0; }

      &:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -4px);
        margin-top: 0; } } } }
