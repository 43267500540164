@import "../../../assets/sass/utils/utils";

.search-fail {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; }

  &__icon {
    margin-bottom: 25px;

    .search-fail-icon {
      @include size(60px); } }

  &__message {
    font-size: 14px;
    line-height: 156.49%;
    font-family: $second-font;
    color: #ABABAB;
    max-width: 242px; }

  &__clean {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 156.49%;
    margin-top: 25px; } }
