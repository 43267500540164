@import "../../../../../assets/sass/utils/utils";

.plans-dropdown {
  $t: &;

  position: relative;
  z-index: 3;

  &:hover {
    #{$t}__with-cart {
      animation-iteration-count: 1; } }

  &.open {
    #{$t}__active {
      border-radius: 10px 10px 0 0; }

    #{$t}__angle {
      transform: translateY(-50%) rotate(-180deg); }

    #{$t}__with-cart {
      animation-iteration-count: 1; } }

  &__active {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 23px;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;
    overflow: hidden;
    user-select: none;

    &:hover {
      background-color: rgba(#000, .03); } }

  &__inner {
    width: 100%; }

  &__name {
    font-weight: 600;
    font-size: 15px;
    line-height: 156.49%;
    margin-bottom: 2px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 1200px) {
      font-size: 14px; } }

  &__status {
    font-family: $second-font;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    &.strong {
      font-weight: 700;
      color: #54C4B0; } }

  &__angle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 23px;
    @include size(12px, 7px);
    transition: all .2s ease-in-out; }

  &__list {
    border: 1px solid #E5E5E5;
    border-top: 0;
    border-radius: 0 0 10px 10px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: #fff;
    z-index: 1;

    @media (max-width: 1199px) {
      top: 80px; } }

  &__item + &__item {
    border-top: 1px solid #E5E5E5; }

  &__item {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 23px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    user-select: none;

    &:hover {
      background-color: rgba(#000, .03); } }

  &__item-not-purchased {
    align-items: center;

    #{$t}__inner {
      width: calc(100% - 43px - 15px);
      margin-left: auto; } }

  &__item-not-purchased-icon {
    margin-top: 2px; }

  &__with-cart {
    @include size(32px);
    position: absolute;
    top: -6px;
    right: -7px;
    z-index: 1;
    box-shadow: 0 0 0 rgba(#54C4B0, 0.4);
    animation: pulse 2s infinite; } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#54C4B0, 0.4); }

  70% {
    box-shadow: 0 0 0 20px rgba(204,169,44, 0); }

  100% {
    box-shadow: 0 0 0 0 rgba(204,169,44, 0); } }

.plans-dropdown-transition-enter {
  opacity: 0;
  transform: scaleY(0.4);
  transform-origin: 0 0; }

.plans-dropdown-transition-enter-active {
  opacity: 1;
  transition: all .2s ease-in-out;
  transform: scaleY(1); }

.plans-dropdown-transition-exit {
  opacity: 1;
  transform: scaleY(1);
  transform-origin: 0 0; }

.plans-dropdown-transition-exit-active {
  opacity: 0;
  transition: all .2s ease-in-out;
  transform: scaleY(0.4); }

.plans-dropdown-btn {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  background-color: #000;
  height: 38px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;
  margin-top: 6px;
  transition: all .2s;

  @media (min-width: 1200px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0; }

  &:hover {
    opacity: 0.9; }

  &.no-purchased {
    background-color: #54C4B0; } }

.plans-dropdown-dot {
  font-size: 16px;
  width: 1em;
  height: 1em;
  background-color: #54C4B0;
  border-radius: 50%;
  position: absolute;
  right: -0.5em;
  top: -0.5em;
  z-index: 1;
  box-shadow: 0 0 0 rgba(#54C4B0, 0.4);
  animation: pulse 2s infinite;
  transition: all .2s ease-in-out;

  @media (min-width: 360px) {
    font-size: 24px; } }
