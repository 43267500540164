@import "../../../assets/sass/utils/utils";

.workout-play-page {
  .header {
    display: none;

    .back-button {
      display: none; } }

  .footer {
    @media screen and ( max-width:  767px) {
      display: none; } } }

.workout-play-page-inner {
  width: 100%;
  flex: 1 0 auto; }

.workout-play-carousel-wrapper.is-finish {
  .workout-info-mobile-wrap {
    display: none; }

  .workout-play-header {
    display: block; } }

.workout-play-horizontal {
  background-color: #F7F7F7;

  // @media (orientation: landscape) and (min-resolution:.001dpcm)
  //   overflow: hidden

  @media (orientation: landscape) and (max-width: 1024px) {
    .start-screen {
      width: calc(100% + 62px);
      margin-right: -62px;

      &__inner {
        padding: 4vh 0 70px; }

      &__head {
        display: none; }

      &__btn {
        position: fixed;
        margin-top: 0;
        height: 62px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2; }

      .timer {
        &__icon {
          @include size(75vh); } } }

    .workout-play-finish {
      padding-top: 5vh;
      padding-bottom: 0;

      &__name,
      &__subtitle {
        display: none; }

      &__title {
        font-size: 11vh;
        margin-bottom: 0; }

      .icon-finish {
        margin-top: 5vh;
        @include size(51vh);
        max-width: 300px;
        max-height: 300px; } }

    .workout-play-slide-rest {
      height: 100%;

      .timer {
        &__icon {
          @include size(75vh);
          max-width: 320px;
          max-height: 320px; }

        &__meter {
          stroke: #EFF1F5; } }

      .workout-play-rest-title {
        margin-bottom: 6vh; } }

    .content {
      width: calc(100vw - 62px); }

    .footer,
    .header,
    .workout-play-header {
      display: none; }

    .workout-play-header {
      &.is-finish {
        display: block;

        ~ .workout-info-mobile-wrap {
          display: none; } } }

    .workout-play-carousel-wrapper.is-finish {
      width: calc(100% + 62px);
      margin-right: -62px;
      background-color: #fff;

      .workout-info-mobile-wrap {
        display: none; } }

    .workout-play-carousel-inner,
    .workout-play-carousel {
      padding: 0; }
    .workout-play-carousel-inner {
      z-index: 4; }

    .workout-play-carousel-wrapper {
      background-color: #fff; }

    .workout-play-main-name,
    .workout-info-mobile-wrap {
      display: block; }

    .workout-play-slide {
      height: calc(100vh - 60px); }

    .workout-play-slide-rest {
      height: 100vh; }

    .workout-info-mobile-wrap {
      background-color: #fff; }

    .workout-info-mobile {
      padding-top: 0;

      &__head {
        display: flex;
        padding-bottom: 4px;

        .workout-info-mobile__info {
          width: 30%;

          &:first-child {
            width: 70%;
            padding-right: 15px; }

          &:last-child {
            .workout-info-mobile__value {
              margin-top: 9px; } } } }

      .workout-info-mobile__info + .workout-info-mobile__info {
        margin-top: 0; }

      &__info {
        width: 100%; }

      &__value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }

      &__fixed {
        position: fixed;
        right: 0;
        bottom: -60px;
        padding-bottom: 60px;
        top: 0;
        background-color: #000000;
        z-index: 3;
        width: 62px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding-top: 13px;

        .workout-info-mobile__add,
        .workout-play-nav {
          position: static;
          background-color: transparent;
          z-index: 3;

          .container {
            padding: 0; } }

        .workout-info-mobile__add {
          margin-top: auto;
          padding-top: 0;
          padding-bottom: 8px;

          .container {
            flex-direction: column-reverse; }

          .workout-info-mobile__info + .workout-info-mobile__info {
            margin-top: 18px; }

          .workout-info-mobile__info {
            text-align: center;
            margin: 0;

            &:first-child {
              display: none; }

            &.right {
              text-align: center; } }

          .workout-info-mobile__title {
            color: rgba(#ffffff, 0.6); }

          .workout-info-mobile__value {
            color: #ffffff; } }

        .workout-play-nav {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          width: 100%;
          height: auto;
          order: -1;

          span {
            display: none; }

          svg {
            margin: 0;
            fill: #ffffff;

            &.icon-play {
              fill: #7DF3B3; } } }

        .workout-play-btn {
          width: 100%;
          height: 57px;
          display: flex;

          &.workout-play-btn-next {
            order: -2; }

          &.workout-play-btn-prev {
            order: -1; } } } } } }
