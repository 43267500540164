@import "../../../assets/sass/utils/utils";

.logo {
  display: flex;
  position: relative;
  justify-content: flex-start;

  @media (min-width: 720px) {
    display: flex; }

  img {
    @include size($logo_img_width, $logo_img_height); }

  .logo-inner {
    position: relative; }

  .logo-company {
    position: absolute;
    right: $logo_company_position_right;
    top: $logo_company_position_top;
    color: $logo_company_color;
    font-size: $logo_company_font_size; }

  &.mealplan60fit {
    padding-bottom: 10px;

    img {
      height: auto;
      width: 50px;

      @media (min-width: 576px) {
        width: 60px; }

      @media (min-width: 890px) {
        width: 70px; }

      @media (min-width: 1200px) {
        width: 80px; } }

    .logo-company {
      top: calc(100% + 3px);
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      white-space: nowrap; } }

  &.delifitplan {
    img {
      height: auto;
      width: 100px;

      @media (min-width: 576px) {
        width: 110px; }

      @media (min-width: 890px) {
        width: 125px; }

      @media (min-width: 1200px) {
        width: 152px; } } }

  &.funappetit {
    img {
      height: auto;
      width: 100px;

      @media (min-width: 576px) {
        width: 110px; }

      @media (min-width: 890px) {
        width: 120px; }

      @media (min-width: 1200px) {
        width: 145px; } } } }

.bank-site-logo {

  &__inner {
    display: flex;
    align-items: center; }

  img {
    @include size(48px);

    @media (min-width: 480px) {
      @include size(52px); }

    @media (min-width: 890px) {
      @include size(56px); }

    @media (min-width: 1024px) {
      @include size(52px); }

    @media (min-width: 1200px) {
      @include size(62px); } }

  &__body {
    white-space: nowrap;
    margin-left: 8px;
    padding-left: 11px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      background-color: #000; } }

  &__desc {
    font-size: 15px;
    font-weight: 700;
    font-family: $second-font;
    color: #000;
    line-height: 1.4em;
    letter-spacing: 0.4px;

    @media (min-width: 480px) {
      font-size: 16px; }

    @media (min-width: 890px) {
      font-size: 17px; }

    @media (min-width: 1024px) {
      font-size: 15px; }

    @media (min-width: 1200px) {
      font-size: 18px; } } }
