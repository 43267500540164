@import "../../../assets/sass/utils/utils";

.home-page {
  width: 100%; }

.home-head {
  display: flex;
  flex-direction: column;
  padding: 0 0 40px;

  @media (min-width: 640px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row; }

  @media (min-width: 720px) {
    padding: 0 0 60px; }

  @media (min-width: 890px) {
    padding: 0 0 80px; }

  @media (min-width: 1200px) {
    padding: 0 0 100px; }

  @media (min-width: 1350px) {
    padding: 0 0 120px; } }

.home-user {
  display: flex;
  align-items: flex-start;
  max-width: 472px;
  width: 100%;
  margin-bottom: 25px;

  @media (min-width: 640px) {
    width: 49%;
    margin-bottom: 0; }

  @media (min-width: 720px) {
    width: 48%;
    max-width: 600px; }

  @media (min-width: 1024px) {
    align-items: center; }

  &__pic {
    @include size(70px);
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;

    @media (min-width: 720px) {
      margin-right: 20px;
      @include size(82px); }

    @media (min-width: 890px) {
      @include size(94px);
      margin-right: 22px; }

    @media (min-width: 1024px) {
      margin-right: 25px;
      @include size(114px); }

    @media (min-width: 1200px) {
      margin-right: 30px;
      @include size(124px); } }

  &__avatar.user-avatar {
    @include size(70px);
    margin-right: 16px;

    @media (min-width: 640px) and (max-width: 719px) {
      @include size(50px);
      margin-right: 10px; }

    @media (min-width: 720px) {
      margin-right: 20px;
      @include size(82px); }

    @media (min-width: 890px) {
      @include size(94px);
      margin-right: 22px; }

    @media (min-width: 1024px) {
      margin-right: 25px;
      @include size(114px); }

    @media (min-width: 1200px) {
      margin-right: 30px;
      @include size(140px); }

    .icon-avatar-woman {
      @include size(30px, 40px);

      @media (min-width: 1024px) {
        @include size(40px, 50px); } } }

  .user-avatar {
    &__empty {
      font-size: 40px;

      @media (min-width: 720px) {
        font-size: 52px; }

      @media (min-width: 890px) {
        font-size: 58px; }

      @media (min-width: 1024px) {
        font-size: 66px; }

      @media (min-width: 1200px) {
        font-size: 72px; } } }

  &__body {
    width: 100%;
    max-width: (calc(100% - 86px));

    @media (min-width: 640px) and (max-width: 719px) {
      max-width: (calc(100% - 60px)); }

    @media (min-width: 720px) {
      max-width: (calc(100% - 102px)); }

    @media (min-width: 890px) {
      max-width: (calc(100% - 116px)); }

    @media (min-width: 1024px) {
      max-width: (calc(100% - 139px)); }

    @media (min-width: 1200px) {
      max-width: (calc(100% - 170px)); } }

  &__greeting-small {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 22px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 14px;

    @media (min-width: 890px) {
      margin-bottom: 22px; } }

  &__greeting {
    font-size: $home_user_greeting_font_size_base;
    line-height: 1;
    margin-bottom: 5px;
    font-weight: $home_user_greeting_font_weight;
    text-transform: $home_user_greeting_text_transform;

    @media (min-width: 576px) {
      font-size: $home_user_greeting_font_size_576; }

    @media (min-width: 720px) {
      font-size: $home_user_greeting_font_size_720; }

    @media (min-width: 890px) {
      font-size: $home_user_greeting_font_size_890; }

    @media (min-width: 1024px) {
      font-size: $home_user_greeting_font_size_1024; }

    @media (min-width: 1200px) {
      font-size: $home_user_greeting_font_size_1200; }

    @media (min-width: 1350px) {
      font-size: $home_user_greeting_font_size_1350; } }

  &__motivation {
    font-size: $home_user_motivation_font_size_base;
    line-height: 156.49%;
    font-family: $quote-font;

    @media (min-width: 720px) {
      font-size: $home_user_motivation_font_size_720; }

    @media (min-width: 1200px) {
      font-size: $home_user_motivation_font_size_1200; }

    @media (min-width: 1350px) {
      font-size: $home_user_motivation_font_size_1350; } } }

.workouts-finished {
  display: flex;
  align-items: center;
  background-color: $workouts_finished_bg;
  color: $workouts_finished_color;
  padding: 19px 22px;
  width: 100%;
  font-family: $second-font;
  border-radius: $workouts_finished_border_radius;
  border: $workouts_finished_border;

  @media (min-width: 640px) {
    width: 48%;
    padding: 15px; }

  @media (min-width: 720px) {
    max-width: 374px;
    width: 48%;
    padding: 22px 25px; }

  @media (min-width: 1200px) {
    padding: 25px 30px; }

  &__icon {
    width: 60px;
    margin-right: 18px;

    @media (min-width: 720px) {
      margin-right: 20px; }

    @media (min-width: 890px) {
      margin-right: 22px; } }

  &__body {
    width: 100%; }

  &__desc {
    color: $workouts_finished_desc_color;
    font-size: $workouts_finished_desc_font_size;
    line-height: $workouts_finished_desc_line_height;
    margin-bottom: $workouts_finished_desc_margin_bottom; }

  &__title {
    font-size: $workouts_finished_title_font_size_base;
    line-height: 156.49%;
    margin-bottom: $workouts_finished_title_margin_bottom;
    font-weight: $workouts_finished_title_font_weight;

    @media (min-width: 720px) {
      font-size: $workouts_finished_title_font_size_720; } }

  &__subtitle {
    font-size: $workouts_finished_subtitle_font_size_base;
    line-height: 1; } }

.recommended-workouts-section {
  padding: 30px 0 40px;
  position: relative;

  @media (min-width: 890px) {
    padding-top: 35px; }

  @media (min-width: 1024px) {
    padding: 40px 0 80px; }

  > .container {
    position: relative; }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $content_under_carouseles; }

  .title-medium {
    color: $home_workouts_recomended_title_color; } }

.workouts-page-section {
  margin-bottom: 30px;

  @media (min-width: 890px) {
    margin-bottom: 35px; }

  @media (min-width: 1200px) {
    margin-bottom: 40px; } }

.last-workouts-section {
  margin-top: 40px;

  @media (min-width: 576px) {
    margin-top: 50px; }

  @media (min-width: 890px) {
    margin-top: 60px; }

  @media (min-width: 1200px) {
    margin-top: 70px; } }

.category-workouts-section {
  margin-top: 80px; }

.meals-list-section + .recommended-workouts-section {
  @media (max-width: 1023px) {
    padding-top: 20px; }

  @media (max-width: 575px) {
    padding-top: 0; } }
