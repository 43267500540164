@import "../../../../../assets/sass/utils/utils";

.pause-video-btn {
  display: flex;
  align-items: center;
  background-color: $color_main_focus;
  padding: 24px;
  width: 217px;
  cursor: pointer;
  margin-top: 40px;
  z-index: 2;
  border-radius: $wt_paus_btn_border_radius;

  &__pic {
    @include size(45px);
    margin-right: 16px;
    border-radius: $wt_paus_btn_border_radius;
    overflow: hidden;

    &-default {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E5E5E5;
      @include size(100%);

      .workouts-icon {
        fill: rgba(#000000, 0.2);

        @include size(30px); } }

    img {
      @include size(100%);
      object-fit: cover; } }

  &__text {
    width: calc(100% - 45px - 16px);
    color: $wt_paus_btn_text_color;
    text-transform: $wt_paus_btn_text_text_transform;
    font-size: $wt_paus_btn_text_font_size;
    line-height: $wt_paus_btn_text_line_height;
    text-align: left;
    font-weight: $wt_paus_btn_text_font_weight; } }

.pause-video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10; }

.pause-video-bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(#2C2C2C, 0.9);
  z-index: 1; }

.pause-video-close {
  position: absolute;
  bottom: 100%;
  right: 0;
  min-width: 0;
  width: 98px;

  @media (min-width: 375px) {
    width: ls(375, 737, 98, 109); }

  @media (min-width: 576px) {
    top: 14px;
    right: 14px;
    bottom: auto; }

  @media (min-width: 737px) {
    width: 109px; } }

.pause-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 6px;
  background-color: #fff;
  width: 100%;
  max-width: 737px;
  height: 215px;
  max-height: 100vh;
  border-radius: $wt_pause_border_radius;

  @media (min-width: 375px) {
    height: ls(375, 737, 215, 422); }

  @media (min-width: 576px) {
    padding: 14px; }

  @media (min-width: 737px) {
    height: 422px; }

  &-inner {
    @include size(100%);
    border-radius: $wt_pause_border_radius;
    overflow: hidden;

    > div,
    iframe,
    object,
    embed {
      @include size(100%); } } }

body.video-pause-body {
  overflow: hidden;

  .content {
    z-index: 4; }

  .workout-play-carousel-inner {
    z-index: 3; } }
