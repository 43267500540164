.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%; }

.btn {
  width: calc((100% - 20px) / 2);

  @media (min-width: 1024px) {
    width: calc((100% - 40px) / 2); } }
