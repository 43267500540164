.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0;

  &.full {
    .body {
      align-items: normal; }

    .inner {
      flex: 1 0; } } }

.head {
  display: flex;
  justify-content: center; }

.body {
  flex: 1 0;
  display: flex;
  align-items: center; }

.inner {
  width: 100%; }
