@import "../../../../../assets/sass/utils/utils";

.workout-play-header {
  padding: 40px 0 6vh;
  width: 100%;
  display: none;

  @media (min-width: 768px) {
    position: relative;
    background-color: #fff;
    height: 80px;
    padding: 0;
    display: block; }

  @media (min-width: 890px) {
    height: 90px; }

  @media (min-width: 1024px) {
    height: 98px; }

  &.is-finish {
    @media (max-width: 767px) {
      padding: 0; }

    .workout-play-header__title,
    .workout-play-info {
      @media (max-width: 767px) {
        display: none; } } }

  .container-fluid {
    height: 100%; }

  &__inner {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 60px;
      height: 100%; }

    @media (min-width: 890px) {
      padding-left: 70px; }

    @media (min-width: 1024px) {
      padding-left: 78px; }

    .back-button {
      @media (max-width: 767px) {
        height: 80px;
        top: -80px; }

      @media (max-width: 479px) {
        height: 70px;
        top: -70px; } } }

  &__title {
    font-size: $wt_header_title_font_size_base;
    text-transform: $wt_header_title_text_transform;
    font-weight: $wt_header_title_font_weight;
    margin-right: auto;
    width: 100%;
    margin-bottom: 34px;
    transition: all .2s;

    @media (min-width: 768px) {
      max-width: calc(100% - 258px - 30px);
      padding-right: 15px;
      margin-bottom: 0; }

    @media (min-width: 1200px) {
      max-width: calc(100% - 524px - 30px - 258px);
      font-size: $wt_header_title_font_size_1200; }

    @media (min-width: 1276px) {
      font-size: $wt_header_title_font_size_1276; }

    .is-finish & {
      opacity: 0; } } }

.workout-play-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    position: absolute;
    top: 100%;
    height: 80px;
    left: 0;
    padding: 20px ls(480, 1349, 25, 50) 0; }

  @media (min-width: 1200px) {
    width: 534px;
    margin-right: 30px;
    position: static;
    padding: 0;
    height: auto; }

  .is-finish & {
    display: none; }

  &__item + &__item {
    @media (min-width: 768px) {
      margin-left: 30px; } }

  &__item {
    transition: all .2s;
    text-align: right;

    @media (min-width: 768px) {
      text-align: left; }

    &:nth-child(1) {
      width: 50px;
      margin-left: 20px;

      @media (min-width: 768px) {
        width: 60px;
        margin-left: 0; }

      @media (min-width: 1200px) {
        width: 44px; } }

    &:nth-child(2) {
      width: 50px;
      margin-left: 10px;

      @media (min-width: 768px) {
        width: 80px;
        margin-left: 0; }

      @media (min-width: 1200px) {
        width: 56px; } }

    &:nth-child(3) {
      width: calc(100% - 30px - 100px);
      order: -1;
      text-align: left;

      @media (min-width: 768px) {
        width: calc( (100% - 140px - 90px) / 2);
        order: 0; }

      @media (min-width: 1200px) {
        width: 172px; } }

    &:nth-child(4) {
      display: none;

      @media (min-width: 768px) {
        display: block;
        width: calc( (100% - 100px - 90px) / 2); }

      @media (min-width: 1200px) {
        width: 172px; } }

    &.fixed {
      margin-left: 0;
      text-align: left;
      width: 100%;
      padding: 30px 10px 100px;
      order: 2;

      @media (min-width: 320px) {
        padding: 30px ls(320, 374, 10, 25) 100px; }

      @media (min-width: 375px) {
        padding: 30px 25px 100px; }

      @media (min-width: 480px) {
        padding: 30px ls(480, 1349, 25, 50) 100px; }

      @media (min-width: 768px) {
        display: none; } }

    &.is-hide {
      opacity: 0; } }

  &__title {
    font-size: 12px;
    line-height: 156.49%;
    font-family: $second-font;
    color: rgba(#000, 0.6);
    margin-bottom: 5px; }

  &__value {
    line-height: 126.49%;
    font-size: $wt_workout_play_info_title_font_size;
    font-weight: $wt_workout_play_info_title_font_weight;
    text-transform: $wt_workout_play_info_title_text_transform; } }

.workout-play-nav {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  background-color: #000000;
  z-index: 3;

  @media (min-width: 768px) {
    background-color: #fff;
    position: static;
    width: 258px;
    background-color: transparent; } }

.workout-play-btn {
  display: flex;
  align-items: center;
  width: calc(100% / 3);
  justify-content: center;
  line-height: 156.49%;
  height: 100%;
  color: #ffffff;
  font-size: $wt_workout_play_nav_btn_font_size;
  text-transform: $wt_workout_play_nav_btn_text_transform;
  font-weight: $wt_workout_play_nav_btn_font_weight;

  @media (min-width: 768px) {
    color: #000000; }

  span {
    padding-top: $wt_workout_play_nav_btn_padding_top; }

  svg {
    margin-right: 8px;
    fill: #ffffff;

    @media (min-width: 768px) {
      fill: #000000; } }

  .icon-stop {
    @include size(13px); }

  .icon-angle-left,
  .icon-angle-right {
    @include size(8px, 13px); }

  .icon-angle-right {
    margin-right: 0;
    margin-left: 8px; }

  .icon-play {
    @include size(18px);
    margin-right: 9px;
    margin-left: -3px;
    fill: #7DF3B3; } }

.workout-play-finish-btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 50px;
  background-color: #000000;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;

  @media (min-width: 480px) {
    font-size: 15px; }

  @media (min-width: 576px) {
    font-size: 18px; }

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    height: 80px;
    font-size: 20px; }

  @media (min-width: 890px) {
    font-size: 23px;
    height: 90px; }

  @media (min-width: 1024px) {
    font-size: 26px;
    height: 98px; }

  @media (min-width: 1200px) {
    font-size: 30px; } }
