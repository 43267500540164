@import "../../../../../assets/sass/utils/utils";

.icon-lock-effect {
  @include size(70px, 65px);

  @media (min-width: 480px) {
    @include size(74px, 69px); }

  @media (min-width: 1200px) {
    @include size(77px, 72px); } }
