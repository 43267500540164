.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.body {
  width: 100%;
  flex: 1 0 auto; }

.footer {
  width: 100%;
  flex: 0 0 auto;

  @media (max-width: 575px) {
    padding-bottom: 30px; } }

.icon {
  fill: #86DBCC; }

.btn {
  margin-top: 25px; }
