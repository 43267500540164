@import "../../../assets/sass/utils/utils";

.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &--small {
    font-family: $second-font;

    .filter-item {
      font-size: 14px;
      text-transform: capitalize;
      display: flex;

      @media (min-width: 480px) {
        font-size: 15px; }

      @media (min-width: 890px) {
        font-size: 16px; }

      + .filter-item {
        margin-left: 15px; }

      &.active {
        span {
          border-color: #000000; } }

      span {
        border-bottom: 3px solid transparent;
        width: 100%;
        padding-bottom: 4px; } } }

  &--large {
    .filter-item {
      font-size: $filter_tabs_text_font_size_base;
      font-weight: $filter_tabs_font_weight;
      text-transform: $filter_tabs_text_transform;

      @media (min-width: 480px) {
        font-size: $filter_tabs_text_font_size_480; }

      @media (min-width: 576px) {
        font-size: $filter_tabs_text_font_size_576; }

      @media (min-width: 768px) {
        font-size: $filter_tabs_text_font_size_768; }

      @media (min-width: 890px) {
        font-size: $filter_tabs_text_font_size_890; }

      @media (min-width: 1200px) {
        font-size: $filter_tabs_text_font_size_1200; }

      + .filter-item {
        margin-left: 20px;

        @media (min-width: 768px) {
          margin-left: 25px; }

        @media (min-width: 1024px) {
          margin-left: 30px; } } } } }

.filter-item {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  color: #ABABAB;
  cursor: pointer;
  position: relative;
  transition: all .2s;
  line-height: 156.49%;
  white-space: nowrap;

  @media (min-width: 480px) {
    font-size: 25px; }

  @media (min-width: 768px) {
    font-size: 27px; }

  @media (min-width: 890px) {
    font-size: 29px; }

  @media (min-width: 1024px) {
    font-size: 32px; }

  + .filter-item {
    margin-left: 20px;

    @media (min-width: 480px) {
      margin-left: 25px; }

    @media (min-width: 1200px) {
      margin-left: 30px; } }

  &.active {
    color: #000000;
    cursor: default; } }
