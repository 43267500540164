@import "../../../../../assets/sass/utils/utils";

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__icon {
    transform: rotate(-90deg);
    width: 271px;
    height: 271px;

    @media (min-width: 480px) {
      width: 291px;
      height: 291px; }

    @media (min-width: 576px) {
      width: 311px;
      height: 311px; }

    @media (min-width: 768px) {
      width: 332px;
      height: 332px; }

    @media (min-width: 890px) {
      width: 352px;
      height: 352px; }

    @media (min-width: 1200px) {
      max-width: 422px;
      max-height: 422px;
      min-width: 360px;
      min-height: 360px;
      width: 50vh;
      height: 50vh; } }

  &__value {
    transition: all 1s linear; }

  &__meter,
  &__value {
    fill: none; }

  &__meter {
    stroke: $wt_time_value_meter; }

  &__value {
    stroke: $wt_time_value_stroke;
    stroke-linecap: round; }

  &__nums {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: $wt_timer_nums_font_size_base;
    line-height: 1;
    font-weight: $wt_timer_nums_font_weight;
    transition: all .2s;

    @media (min-width: 576px) {
      font-size: $wt_timer_nums_font_size_576; }

    @media (min-width: 890px) {
      font-size: $wt_timer_nums_font_size_890; }

    @media (min-width: 1200px) {
      font-size: $wt_timer_nums_font_size_1200; }

    &.is-finish {
      font-size: $wt_timer_nums_is_finish_font_size_base;

      @media (min-width: 576px) {
        font-size: $wt_timer_nums_is_finish_font_size_576; }

      @media (min-width: 890px) {
        font-size: $wt_timer_nums_is_finish_font_size_890; } }

    .is-active & {
      transform: translate(-50%, -50%) scale(1.2); } } }
