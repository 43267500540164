@import "../../../assets/sass/utils/utils";

.main-nav-link {
  display: flex;
  align-items: center;
  color: $main_nav_link_color;
  opacity: 0.35;
  text-transform: $main_nav_link_text_transform;
  font-size: $main_nav_link_font_size;
  font-weight: $main_nav_link_font_weight;
  transition: all .2s;
  padding: 10px 0;
  cursor: pointer;

  svg {
    fill: #000000;

    @include size(30px);
    margin-right: 14px;
    transition: all .2s;

    &.icon-home-icon {
      @include size(28px, 25px); }

    &.icon-workouts {
      @include size(37px, 26px); }

    &.icon-meal-plan,
    &.icon-recipes {
      @include size(24px, 26px); }

    &.icon-lifestyle {
      @include size(26px); }

    &.icon-profile {
      @include size(26px); }

    &.icon-logout {
      @include size(24px); } }

  span {
    padding-top: 1px; }

  &:hover {
    opacity: 0.5; }

  &.active {
    opacity: 1; } }
