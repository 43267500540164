@import "../../../assets/sass/utils/utils";

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  cursor: pointer;
  width: 70px;

  @media (min-width: 480px) {
    width: 80px; }

  @media (min-width: 890px) {
    width: 90px; }

  @media (min-width: 1024px) {
    width: 98px; }

  svg {
    fill: #ffffff;
    @include size(19px, 16px); } }
