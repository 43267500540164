@import "../../../assets/sass/utils/utils";

.log-weight {
  &__title {
    margin-bottom: 14px;

    @media (min-width: 1024px) {
      margin-bottom: 27px; } }

  &__title-add {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      margin-bottom: 45px; }

    @media (min-width: 1024px) {
      margin-bottom: 60px; } }

  .log-counter,
  .weight-logged {
    @media (max-width: 719px) {
      width: 100%;
      max-width: 100%; } }

  .weight-logged {

    &__title {
      margin-bottom: 0;

      @media (max-width: 719px) {
        justify-content: center; } }

    &__link {
      display: none; } } }

.log-weight-head {
  margin-bottom: 36px;

  @media (min-width: 720px) {
    margin-bottom: 0; } }

.log-weight-chart {
  .highcharts-text-outline {
    fill: transparent;
    stroke: transparent; }

  &__nav {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
    display: flex;
    align-items: center;

    &.scrolling {
      right: 74px; } }

  &__btn + &__btn {
    margin-left: 4px; }

  &__btn {
    @include size(24px);
    background-color: $log_weight_chart_btn_bg;
    position: relative;
    border-radius: $log_weight_chart_btn_border_radius;

    &:active {
      background-color: rgba($log_weight_chart_btn_bg, 0.8); }

    &:hover {
      @media (min-width: 1025px) {
        background-color: rgba($log_weight_chart_btn_bg, 0.9); } }

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 2px;
      background-color: $log_weight_chart_btn_color; }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg); }

    &.minus {
      &::after {
        display: none; } }

    &.scroll {
      display: flex;
      justify-content: center;
      align-items: center;

      &::after,
      &::before {
        display: none; }

      .angle-icon {
        fill: $log_weight_chart_btn_color;
        @include size(12px); } } }

  &__main {
    height: 310px;
    width: calc(100% + 20px);
    margin: 0 -10px;
    position: relative;
    border-radius: $log_weight_chart_border_radius;
    overflow: hidden;

    @media (min-width: 320px) {
      width: calc(100% + #{ls-c(320, 374, 10, 25)} + #{ls-c(320, 374, 10, 25)});
      margin: 0 ls(320, 374, -10, -25); }

    @media (min-width: 375px) {
      width: calc(100% + 50px);
      margin: 0 -25px; }

    @media (min-width: 480px) {
      width: calc(100% + #{ls-c(480, 1349, 25, 50)} + #{ls-c(480, 1349, 25, 50)});
      margin: 0 ls(480, 1349, -25, -50); }

    @media (min-width: 720px) {
      width: 100%;
      margin: 0; }

    .highcharts-credits {
      display: none; } }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px; }

  &__title {
    span {
      &:last-child {
        @media (min-width: 720px) {
          display: none; } }

      &:first-child {
        @media (max-width: 719px) {
          display: none; } } } }

  &__target {
    display: flex;
    align-items: center;
    font-size: 32px;
    text-transform: uppercase;

    span {
      padding-top: 2px; }

    .target-icon {
      width: 30px;
      height: 30px;
      margin-right: 8px;
      fill: #000000; } }

  &__body {
    width: 100%; }

  &__empty {
    width: 100%;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    max-height: 360px;

    @media (min-width: 375px) {
      height: ls(375, 719, 180, 360); }

    @media (min-width: 720px) {
      height: ls(720, 1350, 180, 360); }

    @media (min-width: 1350px) {
      height: 360px; }

    .log-weight-empty-icon {
      width: 60px;
      height: 60px;
      margin-bottom: 25px; }

    &-desc {
      color: #ABABAB;
      font-size: 14px;
      line-height: 156.49%;
      font-family: $second-font; } } }

.log-weight-list {
  margin-top: 20px; }

.log-weight-item {
  display: flex;
  align-items: center;
  background-color: $log_weight_item_bg;
  padding: 18.5px 19px 18.5px 25px;
  border: $log_weight_item_border;
  border-radius: $log_weight_item_border_radius;

  + .log-weight-item {
    margin-top: 10px; }

  &__date {
    margin-right: auto;
    font-family: $second-font;
    font-size: $log_weight_item_date_font_size;
    font-weight: $log_weight_item_date_font_weight; }

  &__value {
    text-transform: $log_weight_item_value_text_transform;
    font-size: $log_weight_item_value_font_size;
    font-weight: $log_weight_item_value_font_weight; }

  &__delete {
    @include size(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 17px;

    svg {
      fill: #000000;
      @include size(10px); } } }
