@import "../../../assets/sass/utils/utils";

.section-desc {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ACA6DD;
  line-height: 156.49%;
  font-family: $second-font;

  @media (min-width: 480px) {
    font-size: 13px; }

  @media (min-width: 708px) {
    font-size: 14px; }

  &__icon {
    @include size(52px);
    border-radius: 50%;
    border: 5px solid #F7F9FB;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;

    @media (min-width: 480px) {
      @include size(56px); }

    @media (min-width: 708px) {
      margin-right: 20px; } }

  svg {
    @include size(31px);
    fill: #fff;

    @media (min-width: 480px) {
      position: static;
      width: 35px;
      height: 35px;
      fill: #ACA6DD; } }

  span {
    width: calc(100% - 64px);

    @media (min-width: 480px) {
      width: calc(100% - 74px); }

    @media (min-width: 708px) {
      width: calc(100% - 76px); } } }
