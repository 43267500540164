@import "../../../../../assets/sass/utils/utils";

.circle-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: $second-font;

  &__icon {
    transform: rotate(-90deg); }

  &__meter,
  &__value {
    fill: none; }

  &__meter {
    stroke: #F7F9FB; }

  &__value {
    stroke: $color_main_focus;
    stroke-linecap: round; }

  &__nums {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: $circle_progress_nums_font_size;
    line-height: 1;
    font-weight: $circle_progress_nums_font_weight; } }
