@import "../../../../../assets/sass/utils/utils";

.start-screen {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  align-items: center;

  &__inner {
    width: 100%;
    text-align: center;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 8vh 0; }

  &__head {
    margin-bottom: 8vh;

    @media (min-width: 1200px) {
      margin-bottom: 11.3207547vh; } }

  &__title {
    font-size: 30px;
    line-height: 36px;
    text-transform: $wt_start_screen_title_text_transform;
    font-weight: $wt_start_screen_font_weight;

    @media (min-width: 375px) {
      font-size: ls(375, 1200, 30, 60);
      line-height: ls(375, 1200, 36, 72); }

    @media (min-width: 1200px) {
      font-size: 60px;
      line-height: 72px; } }

  &__desc {
    font-size: 14px;
    line-height: 156.49%;
    font-family: $second-font;

    @media (min-width: 375px) {
      font-size: ls(375, 576, 14, 16); }

    @media (min-width: 576px) {
      font-size: 16px; } }

  &__btn {
    margin-top: 50px;
    height: 62px;
    width: 150px;
    background-color: transparent;
    color: #000000;

    @media (min-width: 375px) {
      height: ls(375, 1200, 62, 78);
      width: ls(375, 1200, 150, 160); }

    @media (min-width: 1200px) {
      margin-top: 75px;
      height: 78px;
      width: 160px; }

    &:hover {
      background-color: transparent;

      span {
        border-color: #000; } }

    span {
      border-bottom: 1px solid transparent;
      transition: all .2s; } }

  .timer.final-backlight {
    .timer__nums {
      color: $color_main_focus; } } }
