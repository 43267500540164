.title {
  line-height: 1.3em;
  font-size: 20px;
  margin-bottom: 0.9em;
  max-width: 13em;
  text-align: center;
  width: 100%; }

@media screen and ( min-width: 360px ) {
  .title {
    font-size: 24px; } }

@media screen and ( min-width: 480px ) {
  .title {
    font-size: 32px; } }

@media screen and ( min-width: 768px ) {
  .title {
    font-size: 33px;
    max-width: 100%; } }

@media screen and ( min-width: 890px ) {
  .title {
    font-size: 37px; } }

@media screen and ( min-width: 1024px ) {
  .title {
    font-size: 41px; } }

@media screen and ( min-width: 1200px ) {
  .title {
    font-size: 48px; } }
