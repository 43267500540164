.plan-cart-footer {
  width: 100%; }

.plan-cart-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 19px;
  transition: all .2s;

  @media (min-width: 890px) {
    margin-bottom: 24px; }

  &.is-empty {
    opacity: 0.2; }

  &__title {
    font-size: 20px;
    line-height: 27px; }

  &__prices {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;

    span {
      margin-right: 15px;
      font-size: 14px;
      text-decoration: line-through;
      transition: all .2s;

      &.is-empty {
        opacity: 0; } } } }

.plan-cart-purchase {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #54C4B0;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  height: 70px;
  transition: all .2s;
  position: relative;

  @media (min-width: 576px) {
    font-size: 14px;
    line-height: 19px; }

  @media (min-width: 576px) {
    font-size: 16px;
    line-height: 22px; }

  &:hover {
    background-color: rgba(#54C4B0, 0.9); }

  &.is-empty,
  &.is-empty:hover {
    background-color: rgba(#000, 0.2); }

  &.is-empty {
    .is-disabled {
      opacity: 1; }

    .is-active {
      opacity: 0; } }

  span {
    transition: all .2s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

  .is-disabled {
    opacity: 0; }

  .is-active {
    opacity: 1; } }

.plan-cart-purchase-empty {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 42px;
  margin-top: 9px;
  transition: all .2s;
  border-radius: 10px;

  &:hover {
    background-color: rgba(#F1F1F1, 0.3); } }
