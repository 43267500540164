@import "../../../../../assets/sass/utils/utils";

.daily-list-helper {
  margin-bottom: 25px; }

.daily-list-head {
  margin-bottom: 25px;

  @media (min-width: 576px) {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  @media (min-width: 768px) {
    margin-bottom: 45px; }

  @media (min-width: 1024px) {
    margin-bottom: 50px; }

  @media (min-width: 1200px) {
    margin-bottom: 55px; } }

.daily-list-nav {
  align-items: center;
  display: none;

  @media (min-width: 576px) {
    display: flex; } }

.daily-list-arrow {
  @include size(28px);
  cursor: pointer;

  &:hover,
  &:active {
    svg {
      fill: #000000; } } }

.arrow-right,
.arrow-left {
  fill: #ABABAB;
  @include size(18px, 15px);
  transition: all .2s; }

.arrow-right {
  margin-left: 4px; }

.daily-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  .meal-card + .meal-card {
    margin-top: 25px; }

  .meal-card {
    max-width: 425px; } }

.daily-list-carousel {
  .swiper-container {
    @media (min-width: 576px) {
      overflow: visible; } }

  .meal-card {
    @media (max-width: 575px) {
      max-width: 350px;
      margin: 0 auto; } } }
