.swiper-pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  position: static;

  @media (min-width: 1024px) {
    display: none; }

  .swiper-pagination-bullet {
    @include size(8px);
    background-color: rgba(#ffffff, 0.15);
    border-radius: 0;
    margin: 0 6.5px;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: #fff; } }

  &--white {
    .swiper-pagination-bullet {
      background-color: rgba(#000000, 0.15);

      &.swiper-pagination-bullet-active {
        background-color: #000000; } } } }

.swiper-button-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @include size(45px);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 1023px) {
    display: none; }

  svg {
    @include size(18px, 15px); }

  &.swiper-button-next {
    right: 0;
    border-radius: $carousel_swiper_nav_next_btn_border_radius; }

  &.swiper-button-prev {
    left: 0;
    border-radius: $carousel_swiper_nav_prev_btn_border_radius; }

  &.swiper-button-disabled {
    display: none; } }
