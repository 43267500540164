@import "../../../assets/sass/utils/utils";

@keyframes handleMove {
  from {
    transform: translateX(0); }

  25% {
    transform: translateX(-10px); }

  40% {
    transform: translateX(0); } }

.helper {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 14px 0 14px 17px;
  font-family: $second-font;
  font-size: 14px;
  line-height: 156.49%;
  margin-top: 20px;

  .helper-icon {
    @include size(42px, 30px);
    margin-right: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .angle-small-left-icon {
      @include size(10.5px, 8.25px);
      position: absolute;
      top: 1px;
      left: 0; }

    .handle-icon {
      @include size(23.25px, 28.5px);
      animation-name: handleMove;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      position: relative;
      z-index: 1;
      animation-delay: .5s; } }

  &__message {
    width: calc(100% - 42px - 10px - 36px);
    font-weight: 700; }

  &__delete {
    @include size(36px);

    svg {
      @include size(12px); } } }
