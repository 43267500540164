@import "../../../../../assets/sass/utils/utils";

.category-preview {
  display: flex;
  align-items: center;
  height: 135px;
  background-color: $category_preview_bg;
  color: $category_preview_color;
  border-radius: $category_preview_border_radius;
  overflow: hidden;

  @media (min-width: 375px) {
    height: ls(375, 639, 135, 240); }

  @media (min-width: 640px) {
    height: ls(640, 1199, 135, 250); }

  @media (min-width: 1200px) {
    height: 250px; }

  &__pic {
    width: 46.12%;
    height: 100%;
    object-fit: cover; }

  &__body {
    width: 53.88%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center; }

  &__icon {
    @include size(44px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    @media (min-width: 375px) {
      @include size(ls(375, 639, 44, 78));
      margin-bottom: ls(375, 639, 10, 18); }

    @media (min-width: 640px) {
      @include size(ls(640, 1200, 44, 78));
      margin-bottom: ls(640, 1023, 10, 18); }

    @media (min-width: 1024px) {
      margin-bottom: 18px; }

    @media (min-width: 1200px) {
      @include size(80px); }

    svg {
      fill: $category_preview_svg_fill;
      @include size(40px, 34px);

      @media (min-width: 375px) {
        @include size(ls(375, 639, 40, 58), ls(375, 639, 34, 58)); }

      @media (min-width: 640px) {
        @include size(ls(640, 1023, 40, 58), ls(640, 1023, 34, 58)); }

      @media (min-width: 1024px) {
        @include size(58px, 58px); } } }

  &__name {
    font-size: $category_preview_name_font_size_base;
    font-weight: $category_preview_name_font_weight;
    line-height: 1;
    text-transform: $category_preview_text_transform;

    @media (min-width: 400px) {
      font-size: $category_preview_name_font_size_400; }

    @media (min-width: 480px) {
      font-size: $category_preview_name_font_size_480; }

    @media (min-width: 576px) {
      font-size: $category_preview_name_font_size_576; }

    @media (min-width: 640px) {
      font-size: $category_preview_name_font_size_640; }

    @media (min-width: 700px) {
      font-size: $category_preview_name_font_size_700; }

    @media (min-width: 768px) {
      font-size: $category_preview_name_font_size_768; }

    @media (min-width: 890px) {
      font-size: $category_preview_name_font_size_890; }

    @media (min-width: 1024px) {
      font-size: $category_preview_name_font_size_1024; } } }
