@import "../../../../../assets/sass/utils/utils";

.video,
.video-second {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }

  iframe,
  object,
  embed {
    width: 100%;
    height: 100%; }

  .player .vp-controls .play {
    background-color: red !important; } }
