.fixed-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(#2C2C2C, 0.8); }
