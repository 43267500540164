.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }

.body {
  width: 100%;
  flex: 1 0 auto; }

.footer {
  width: 100%;
  flex: 0 0 auto;

  @media (max-width: 575px) {
    padding-bottom: 30px; } }

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 50px; }

.old {
  font-size: 16px;
  font-weight: 600;
  color: rgba(#000, 0.4);
  line-height: 1.5em;
  position: relative;
  margin-bottom: 4px;

  &::before {
    content: "";
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    background-color: rgba(#000, 0.4);
    display: block; } }

.new {
  font-size: 22px;
  line-height: 1.2em;
  color: #F75623;
  font-weight: 700; }

.desc {
  text-align: left;
  margin-bottom: 50px;
  margin-top: 30px;

  p {
    margin-top: 12px; } }
