@import "../../../assets/sass/utils/utils";

.meal {
  $t: &;

  .title {
    margin-bottom: 10px;

    @media (min-width: 480px) {
      margin-bottom: 25px; } }

  &__head {
    margin-bottom: 25px;

    @media (max-width: 719px) {
      order: -2; } }

  .param-list {
    @media (max-width: 719px) {
      width: 100%; }

    .param {
      @media (max-width: 719px) {
        margin: 0 7.5px; }

      svg {
        fill: #000000; } } }

  &__pic {
    @media (max-width: 719px) {
      order: -1; }

    img {
      width: 100%;
      max-height: 220px;
      object-fit: cover;

      @media (min-width: 375px) {
        max-height: ls(375, 720, 220, 400); }

      @media (min-width: 720px) {
        max-height: ls(720, 1350, 300, 450); }

      @media (min-width: 1350px) {
        max-height: 450px; } } }

  .title-add {
    margin-top: 30px;

    @media (min-width: 720px) {
      margin-top: 0; } }

  &__ingredients {
    margin-top: 40px;

    @media (min-width: 720px) {
      max-width: 362px; }

    @media (min-width: 890px) {
      margin-top: 45px; }

    @media (min-width: 1024px) {
      margin-top: 50px; }

    @media (min-width: 1200px) {
      margin-top: 50px; }

    @media (min-width: 1350px) {
      margin-top: 60px; }

    .title-medium {
      margin-bottom: 10px;

      @media (min-width: 480px) {
        margin-bottom: 20px; } } }

  &__preparations + &__preparations {
    @media (max-width: 889px) {
      margin-top: 45px; } }

  &__preparations {
    margin-top: 20px;

    @media (min-width: 480px) {
      margin-top: 30px; }

    @media (min-width: 576px) {
      margin-top: 40px; }

    @media (min-width: 890px) {
      margin-top: 50px; }

    @media (min-width: 1024px) {
      margin-top: 60px; }

    @media (min-width: 1350px) {
      margin-top: 72px; }

    .title-medium {
      margin-bottom: 30px;

      @media (min-width: 480px) {
        margin-bottom: 42px; } } }

  &--dark {
    .title,
    .title-add,
    .ingredient,
    .preparation,
    .title-medium {
      color: #ffffff; }

    .ingredient + .ingredient {
      border-top: 1px solid rgba(#ffffff, 0.1); }

    .param-list {
      .param {
        color: #ffffff;

        svg {
          fill: #ffffff; } } } } }

.ingredient {
  display: flex;
  align-items: center;
  color: #000000;
  padding: 20px 0;
  font-family: $second-font;
  font-size: 15px;

  + .ingredient {
    border-top: 1px solid rgba(#000000, 0.1); }

  &__name {
    width: 50%;
    font-size: 15px;
    line-height: 130.49%; }

  &__value {
    width: 50%;
    text-align: right;
    font-size: 13px;
    line-height: 1; } }

.preparation {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 130.49%;

  @media (min-width: 480px) {
    font-size: 17px;
    line-height: 135.49%; }

  @media (min-width: 1024px) {
    font-size: 22px;
    line-height: 156.49%; }

  + .preparation {
    margin-top: 32px;

    @media (min-width: 1024px) {
      margin-top: 60px; } }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #000000;
    line-height: 130.49%;
    margin-right: 20px;
    @include size(50px);
    font-weight: normal;
    font-weight: $meal_one_preparation_font_weight;
    font-size: $meal_one_preparation_font_size;
    border-radius: $meal_one_preparation_border_radius;

    @media (min-width: 480px) {
      @include size(56px); }

    @media (min-width: 1024px) {
      @include size(60px); } }

  &__value {
    font-family: $second-font;
    font-size: 12px;
    width: calc(100% - 72px);

    @media (min-width: 480px) {
      width: calc(100% - 76px);
      font-size: 13px; }

    @media (min-width: 576px) {
      font-size: 14px; }

    @media (min-width: 768px) {
      font-size: 15px; }

    @media (min-width: 1024px) {
      width: calc(100% - 80px);
      font-size: 16px; } } }
