.auth-layout-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  padding: 40px 0; }

.auth-nav-link {
  display: flex;
  align-items: center;
  padding: 0 20px 0 14px;
  height: 48px;
  border-radius: 80px;
  color: #fff;
  font-size: 16px;

  @media (min-width: 840px) {
    font-size: 17px;
    border-radius: 100px;
    padding: 0 22px 0 18px;
    height: 58px; }

  @media (min-width: 1024px) {
    font-size: 18px;
    padding: 0 27px 0 21px; }

  &:hover {
    color: #ffffff;
    background-color: rgba(#fff, 0.05); }

  &.active {
    background-color: #fff;
    color: #655CAE;

    svg {
      fill: #655CAE;
      color: #655CAE; } }

  svg {
    height: 26px;
    width: 26px;
    fill: #fff;
    margin-right: 7px;

    @media (min-width: 1024px) {
      margin-right: 13px; } } }
