@import "../../../assets/sass/utils/utils";

.radio-wrap {
  position: relative;
  font-size: 0;
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 3px;

    &:checked {
      ~ .radio-icon:after {
        opacity: 1; } } } }

.radio-icon {
  @include size(16px);
  border-radius: 50%;
  position: relative;
  border: 1px solid #DEDEDE;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000;
    @include size(6px);
    border-radius: 50%;
    transition: all .2s;
    opacity: 0; } }

.radio-label {
  display: inline-block;
  vertical-align: middle;
  padding-top: $radio_label_padding_top;
  font-size: $radio_label_font_size;
  text-transform: $radio_label_text_transform;
  font-weight: $radio_label_font_weight; }
