@import "../../../assets/sass/utils/utils";

.log-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 292px;
  max-width: 100%;

  @media (min-width: 375px) {
    width: ls(375, 640, 239, 292); }

  @media (min-width: 640px) {
    width: 292px; }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#fff, 0.9);
    z-index: 3; }

  &__btn {
    margin-left: 6px;
    width: 35.95%;
    background-color: #000000;
    color: #ffffff;
    text-transform: uppercase;
    font-size: $log_counter_btn_font_size;
    font-weight: $log_counter_btn_font_weight;
    border-radius: $log_counter_btn_border_radius;
    width: 30.95%;
    height: 62px;

    @media (min-width: 375px) {
      height: ls(375, 640, 62, 78); }

    @media (min-width: 480px) {
      width: 35.95%; }

    @media (min-width: 640px) {
      height: ls(640, 926, 62, 78); }

    @media (min-width: 720px) {
      width: 30.95%; }

    @media (min-width: 768px) {
      width: 35.95%; }

    @media (min-width: 926px) {
      height: 78px; }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.6);
      color: #ffffff;
      cursor: auto; } }

  &__error {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background-color: #F64848;
    color: #ffffff;
    text-align: center;
    font-family: $second-font;
    font-size: $log_counter_error_font_size;
    border-radius: $log_counter_error_border_radius;
    padding: 12px 20px;
    right: 0; }

  &__field {
    width: 46%;
    text-transform: uppercase;
    border: 0;
    max-height: 100%;
    text-align: center;
    font-size: $log_counter_field_font_size;
    font-weight: $log_counter_font_weight;
    line-height: 1;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 480px) {
      font-size: 34px; }

    @media (min-width: 608px) {
      font-size: 37px; }

    @media (min-width: 640px) {
      font-size: $log_counter_field_font_size_640; }

    @media (min-width: 768px) {
      font-size: $log_counter_field_font_size_768; }

    @media (min-width: 926px) {
      font-size: $log_counter_field_font_size_926; }

    &-number {
      transition: all .2s;
      transform: scale(0.75);
      color: #ABABAB;
      cursor: pointer;

      &.active {
        transform: scale(1);
        color: #000000; }

      &:last-child {
        transform-origin: 10% 80%;
        text-align: left;
        width: 35%; }

      &:first-child {
        transform-origin: 100% 80%;
        text-align: right;
        width: 60%; } } }

  &__input {
    display: flex;
    align-items: center;
    background-color: $log_counter_input_bg;
    border: $log_counter_input_border;
    width: calc(69.05% - 6px);
    height: 62px;
    border-radius: $log_counter_input_border_radius;

    @media (min-width: 375px) {
      height: ls(375, 640, 62, 78); }

    @media (min-width: 480px) {
      width: calc(64.05% - 6px); }

    @media (min-width: 640px) {
      height: 78px; }

    @media (min-width: 640px) {
      height: ls(640, 926, 62, 78); }

    @media (min-width: 720px) {
      width: calc(69.05% - 6px); }

    @media (min-width: 768px) {
      width: calc(64.05% - 6px); }

    @media (min-width: 926px) {
      height: 78px; }

    &-btn {
      width: 27%;
      height: 100%;
      position: relative;
      transition: all .2s;

      &:disabled {
        &::after,
        &::before {
          opacity: 0.6; } }

      &::after {
        transition: all .2s;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000000;
        width: 16px;
        max-width: 90%;
        height: 3px; }

      &:hover {
        @media (min-width: 1025px) {
          background-color: rgba(#000000, 0.03); } }

      &:active {
        background-color: rgba(#000000, 0.03); }

      &.plus {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #000000;
          height: 16px;
          width: 3px; } } } } }

.weight-logged {
  height: 78px;
  padding: 0 18px;
  background-color: #fff;
  display: inline-flex;
  max-width: 181px;
  width: 100%;
  align-items: center;
  height: 62px;

  @media (min-width: 375px) {
    height: ls(375, 640, 62, 78); }

  @media (min-width: 640px) {
    height: 78px; }

  @media (min-width: 640px) {
    height: ls(640, 926, 62, 78); }

  @media (min-width: 926px) {
    height: 78px; }

  &__inner {
    width: 100%; }

  &__title {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 156.49%;
    margin-bottom: 6px;

    svg {
      fill: #7DF3B3;
      @include size(20px);
      margin-right: 8px; } }

  &__link {
    font-size: 12px;
    line-height: 14px;
    font-family: $second-font;
    color: #000000;

    &:hover {
      text-decoration: underline; } } }
