.control + .control {
  margin-top: 15px; }

.control {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 480px) {
    font-size: 16px; }

  @media (min-width: 890px) {
    font-size: 17px; }

  @media (min-width: 1200px) {
    font-size: 18px; } }

.input {
  width: calc(100% - 3.5em - 0.5em); }

.iconWrap {
  width: 3.5em;
  height: 3.5em;
  padding: 0.5em;
  margin-right: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center; }

.icon {
  max-width: 100%;
  max-height: 100%;
  fill: #54C4B0; }

.double {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.half {
  width: calc( (100% - 15px) / 2 ); }
