.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 450px;
  margin: 0 auto; }

.body {
  width: 100%;
  flex: 1 0 auto; }

.footer {
  width: 100%;
  flex: 0 0 auto;

  @media (max-width: 575px) {
    padding-bottom: 30px; } }

.radioList {
  max-width: 320px;
  margin: 40px auto 0;

  &:last-child {
    margin-bottom: 40px;

    @media (min-width: 890px) {
      margin-bottom: 60px; } } }

.form {
  width: 100%; }
