@import "../../../assets/sass/utils/utils";

.meal-page-body {

  @media (min-width: 720px) {
    margin-top: 30px; }

  @media (min-width: 890px) {
    margin-top: 35px; }

  @media (min-width: 1024px) {
    margin-top: 40px; }

  @media (min-width: 1200px) {
    margin-top: 45px; } }
