@import "../../../assets/sass/utils/utils";

.error-page .content {
  display: flex; }

.error-wrap {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $error_page_bg;
  padding: 40px 0; }

.error-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    @include size(120px);
    margin-bottom: 50px;

    @media (min-width: 400px) {
      @include size(ls(400, 1200, 120, 195));
      margin-bottom: ls(400, 1200, 50, 80); }

    @media (min-width: 1200px) {
      @include size(195px);
      margin-bottom: 80px; } }

  &__body {
    text-align: center; }

  &__title {
    line-height: 156.49%;
    text-transform: uppercase;
    margin-bottom: 2px;
    font-size: 30px;

    @media (min-width: 576px) {
      font-size: 32px;
      margin-bottom: 5px; }

    @media (min-width: 1200px) {
      font-size: 34px; } }

  &__description {
    font-family: $second-font;
    font-size: 14px;
    line-height: 156.49%;

    @media (min-width: 576px) {
      font-size: 15px; }

    @media (min-width: 1200px) {
      font-size: 16px; } } }
