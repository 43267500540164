@import "../../../assets/sass/utils/utils";

.recipes-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;

  @media (min-width: 480px) {
    margin-bottom: 40px; }

  @media (min-width: 768px) {
    margin-bottom: 50px; }

  @media (min-width: 1200px) {
    margin-bottom: 70px; }

  &__selects {
    width: 100%;
    overflow-x: auto;

    @media (min-width: 840px) {
      width: 430px; }

    @media (min-width: 632px) {
      overflow-x: visible; }

    .select-wrap {
      width: 106px;
      min-width: 106px;
      width: calc(100% / 3);

      @media (min-width: 480px) {
        min-width: 120px; }

      @media (min-width: 632px) {
        width: 100%; } } }

  &__tabs {
    margin-bottom: 20px;

    @media (min-width: 840px) {
      margin-bottom: 0; }

    .filter-tab-counter {
      position: absolute;
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      right: -12px;
      top: 10px;

      @media (min-width: 480px) {
        font-size: 15px;
        top: 12px; }

      @media (min-width: 768px) {
        top: 15px;
        right: -13px; }

      @media (min-width: 1200px) {
        top: 20px;
        right: -15px; } } } }

.recipes-footer {
  margin-top: 60px; }
