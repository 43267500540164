@import "../../../assets/sass/utils/utils";

.rate-workout-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 14; }

.rate-workout-modal-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#2C2C2C, 0.2); }

.rate-workout-modal {
  width: 682px;
  max-width: calc(100% - 30px);
  position: absolute;
  left: 50%;
  top: 25%;
  background-color: $small_modal_bg;
  transform: translateX(-50%);
  border-radius: $small_modal_border_radius;

  @media (max-width: 359px) {
    top: 7vh; } }

.rate-workout-modal-inner {
  width: 100%;
  padding: 30px 28px 30px;

  @media (min-width: 576px) {
    padding: 43px 39px 48px; }

  @media (min-width: 890px) {
    padding: 40px 55px 50px; }

  &__title {
    color: $small_modal_title_color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: $small_modal_title_font_size_base;
    line-height: $small_modal_title_font_weight;
    line-height: 150%;
    margin-bottom: 6px;
    text-transform: $small_modal_title_text_transform;

    @media (min-width: 480px) {
      font-size: $small_modal_title_font_size_480;
      margin-bottom: 9px; }

    @media (min-width: 890px) {
      font-size: $small_modal_title_font_size_890;
      margin-bottom: 12px; } }

  &__desc {
    color: #000000;
    line-height: 1;
    font-size: $small_modal_desc_font_size_base;
    font-weight: $small_modal_desc_font_weight;
    text-align: center;
    margin-bottom: 27px;
    font-family: $second-font;

    @media (min-width: 480px) {
      margin-bottom: 32px;
      font-size: $small_modal_desc_font_size_480; }

    @media (min-width: 890px) {
      margin-bottom: 39px;
      font-size: $small_modal_desc_font_size_890; } }

  &__footer {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 480px) {
      justify-content: space-between;
      flex-direction: row;
      width: calc(100% + 36px);
      margin: 0 -18px; }

    @media (min-width: 768px) {
      width: 100%;
      margin: 0; }

    button {
      width: 100%;
      min-width: 0;
      padding: 0;

      @media (min-width: 480px) {
        width: calc((100% - 10px) / 3); }

      @media (min-width: 576px) {
        width: calc((100% - 20px) / 3); }

      &.with-icon {
        @media (min-width: 576px) {
          padding: 0; } }

      + button {
        margin-top: 15px;

        @media (min-width: 480px) {
          margin-top: 0; } }

      span {
        display: flex;
        align-items: center; }

      svg {
        margin-left: 0;
        margin-right: 5px;
        order: -1;
        margin-top: -2px;

        @media (min-width: 480px) and (max-width: 575px) {
          display: none; } } } } }
