.content-page {
  padding: 35px 0;
  width: 100%;

  @media (min-width: 480px) {
    padding: ls(480, 1349, 35, 80) 0 ls(480, 1349, 35, 140); }

  @media (min-width: 1350px) {
    padding: 80px 0 140px; } }

.content-page-head {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 375px) {
    margin-bottom: ls(375, 1349, 32, 60); }

  @media (min-width: 1350px) {
    margin-bottom: 60px; } }

.content-page-inner {
  display: flex;
  flex-direction: column;

  @media (min-width: 720px) {
    display: block; }

  &::after {
    @media (min-width: 720px) {
      content: '';
      display: table;
      clear: both; } } }

.content-page-left {
  @media (min-width: 720px) {
    float: left;
    width: 46%;
    max-width: 362px; }

  @media (min-width: 768px) {
    width: 43%; }

  @media (min-width: 890px) {
    width: 39%; } }

.content-page-right {
  @media (min-width: 720px) {
    float: right;
    width: 51%;
    max-width: 700px; }

  @media (min-width: 768px) {
    width: 54%; }

  @media (min-width: 890px) {
    width: 59%; } }
