@import "../../../assets/sass/utils/utils";

.category-detail-filters {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;

  @media (min-width: 375px) {
    max-width: 190px;
    margin-top: 0; }

  @media (min-width: 480px) {
    max-width: 210px; }

  @media (min-width: 768px) {
    max-width: 230px; } }

.categories-not-found {
  padding: 33vh 0 0; }

.categories-page .title {
  text-transform: $category_detail_title_text_transform; }
