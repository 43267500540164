@import "../../../../../assets/sass/utils/utils";

.exercise-list-wrap + .exercise-list-wrap {
  margin-top: 40px; }

.exercise-list-wrap {

  .title-medium {}

  .title-medium + .title-add {
    margin-top: 10px; }

  .title-medium+ .exercise-list {
    margin-top: 30px;

    @media (min-width: 890px) {
      margin-top: 40px; } } }

.workout-plan .exercise-list-wrap {
  .title-add {
    margin-bottom: 30px;

    @media (min-width: 890px) {
      margin-bottom: 40px; } } }

.exercise-card {
  color: #000000;
  background-color: #ffffff;
  border-radius: $exercise_card_border_radius;
  border: $exercise_card_border;
  overflow: hidden;

  &:hover {
    color: #000000; }

  + .exercise-card {
    margin-top: 10px; }

  &__inner {
    display: flex;
    align-items: center;
    cursor: pointer; }

  &__pic {
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(118px, 110px);

    @media (min-width: 576px) {
      @include size(140px, 130px); }

    @media (min-width: 1200px) {
      @include size(159px, 145px); }

    svg {
      @include size(50px); }

    .image-creator-pic {
      @include size(auto);
      max-width: 100%;
      max-height: 100%; } }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: calc(100% - 118px);

    @media (min-width: 576px) {
      width: calc(100% - 140px); }

    @media (min-width: 1200px) {
      width: calc(100% - 159px);
      padding: 0 30px; } }

  &__name {
    font-size: $exercise_card_name_font_size_base;
    line-height: 1;
    font-weight: $exercise_card_name_font_weight;
    text-transform: $exercise_card_name_text_transform;

    @media (min-width: 480px) {
      font-size: $exercise_card_name_font_size_480; }

    @media (min-width: 576px) {
      font-size: $exercise_card_name_font_size_576; }

    @media (min-width: 768px) {
      font-size: $exercise_card_name_font_size_768; }

    @media (min-width: 890px) {
      font-size: $exercise_card_name_font_size_890; }

    @media (min-width: 1024px) {
      font-size: $exercise_card_name_font_size_1024; } }

  &__desc {
    font-weight: $exercise_card_desc_font_weight;
    font-size: $exercise_card_desc_font_size_base;
    font-family: $second-font;
    line-height: 1;
    margin-top: 6px;

    @media (min-width: 480px) {
      font-size: $exercise_card_desc_font_size_480; }

    @media (min-width: 768px) {
      font-size: $exercise_card_desc_font_size_768; } }

  &__dropdown {
    padding: 20px 15px 15px;

    @media (min-width: 375px) {
      padding: ls(375, 719, 20, 30) ls(375, 719, 15, 30) ls(375, 719, 15, 30); }

    @media (min-width: 720px) {
      padding: ls(720, 1023, 20, 30) ls(720, 1023, 15, 30) ls(720, 1023, 15, 30); }

    @media (min-width: 1024px) {
      padding: 30px; } } }

.exercise-video {
  $t: &;

  &__head {
    display: flex;
    align-items: center; }

  &__btn {
    text-transform: $exercise_video_btn_text_transform;
    color: #ABABAB;
    font-size: $exercise_video_btn_font_size_base;
    line-height: $exercise_video_btn_line_height_base;
    font-weight: $exercise_video_btn_font_weight;

    @media (min-width: 480px) {
      font-size: $exercise_video_btn_font_size_480;
      line-height: $exercise_video_btn_line_height_480; }

    @media (min-width: 576px) {
      font-size: $exercise_video_btn_font_size_576;
      line-height: $exercise_video_btn_line_height_576; }

    @media (min-width: 640px) {
      font-size: $exercise_video_btn_font_size_640;
      line-height: $exercise_video_btn_line_height_640; }

    @media (min-width: 720px) {
      font-size: $exercise_video_btn_font_size_720;
      line-height: $exercise_video_btn_line_height_720; }

    @media (min-width: 768px) {
      font-size: $exercise_video_btn_font_size_768;
      line-height: $exercise_video_btn_line_height_768; }

    @media (min-width: 890px) {
      font-size: $exercise_video_btn_font_size_890;
      line-height: $exercise_video_btn_line_height_890; }

    @media (min-width: 1024px) {
      font-size: $exercise_video_btn_font_size_1024;
      line-height: $exercise_video_btn_line_height_1024; }

    &.active {
      color: #000000; } }

  &__btn + &__btn {
    margin-left: 15px;

    @media (min-width: 480px) {
      margin-left: 20px; }

    @media (min-width: 576px) {
      margin-left: 26px; }

    @media (min-width: 720px) {
      margin-left: 15px; }

    @media (min-width: 768px) {
      margin-left: 20px; }

    @media (min-width: 890px) {
      margin-left: 26px; } }

  &__btn + &__btn--close {
    margin-left: auto;
    color: #000000; }

  &__body {
    background: $exercise_video_body_bg;
    position: relative;
    height: 162px;
    margin-top: 20px;
    overflow: hidden;
    border-radius: $exercise_video_body_border_radius;

    @media (min-width: 375px) {
      height: ls(375, 719, 162, 337); }

    @media (min-width: 480px) {
      margin-top: 25px; }

    @media (min-width: 576px) {
      margin-top: 30px; }

    @media (min-width: 720px) {
      height: ls(720, 1359, 162, 337);
      margin-top: 20px; }

    @media (min-width: 768px) {
      margin-top: 25px; }

    @media (min-width: 890px) {
      margin-top: 30px; }

    @media (min-width: 1360px) {
      height: 337px; } } }
