@font-face {
  font-family: 'Kalam';
  src: url('../../fonts/Kalam-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: block; }

// Themes:
// hadew
// fitculator

@import "../themes/hadew/fonts";
